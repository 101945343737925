import styled from 'styled-components';
import AppCSS from '../../../utils/styled';

export const Components = {
  Container: styled.div<{ isLaptop: boolean }>`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 16px ${(p) => (p.isLaptop ? 24 : 16)}px;
    background-color: ${AppCSS.colors.utility.screenBackground};
    gap: 32px;
  `,
  HeaderContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
  `,
  HeaderTitleWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  `,
  HeaderTitle: styled.span`
    ${AppCSS.fonts.h1};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  HeaderDetailsWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2px;
  `,
  HeaderDetailsLabel: styled.span`
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green70};
  `,
  BodyContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  `,
  ColumnContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
  `,
};
