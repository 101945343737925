import React from 'react';
import { Components } from './styled';
import { ReactComponent as InstructionsImage } from '../../../../assets/illustrations/illustration-portal-instructions.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/icon-search-alt.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/icon-plus-thin.svg';
import { ReactComponent as BellIcon } from '../../../../assets/icons/icon-bell-alt.svg';

const PortalInstructions = () => {
  return (
    <Components.Container>
      <InstructionsImage />
      <Components.StepsContainer>
        <Components.StepItem>
          <Components.StepIconWrapper>
            <SearchIcon />
          </Components.StepIconWrapper>
          <Components.StepContentWrapper>
            <Components.StepTitle>Caută dosarul de pe Portal</Components.StepTitle>
            <Components.StepDescription>
              Scrie numărul dosarului în bara de căutare și îți vor apărea rezultatele. Dacă nu l-ai găsit, poți încerca
              să cauți după numele părții.
            </Components.StepDescription>
          </Components.StepContentWrapper>
        </Components.StepItem>
        <Components.StepItem>
          <Components.StepIconWrapper>
            <PlusIcon />
          </Components.StepIconWrapper>
          <Components.StepContentWrapper>
            <Components.StepTitle>Adaugă dosarul în lista ta de dosare</Components.StepTitle>
            <Components.StepDescription>
              Dacă ai găsit dosarul pe care îl cauți, adaugă-l în lista ta în care ții evidența lor.
            </Components.StepDescription>
          </Components.StepContentWrapper>
        </Components.StepItem>
        <Components.StepItem>
          <Components.StepIconWrapper>
            <BellIcon />
          </Components.StepIconWrapper>
          <Components.StepContentWrapper>
            <Components.StepTitle>Gata! Vei fi notificat în legătură cu dosarul tău</Components.StepTitle>
            <Components.StepDescription>
              De aici ne ocupăm noi, aplicația este sincronizată cu portalul, așa că vei fi notificat imediat cum apare
              o schimbare, sau înainte de un termen.
            </Components.StepDescription>
          </Components.StepContentWrapper>
        </Components.StepItem>
      </Components.StepsContainer>
    </Components.Container>
  );
};

export default PortalInstructions;
