import styled from 'styled-components';
import AppCSS from '../../../../../utils/styled';
import { DEFAULT_PLACEHOLDER_HEIGHT } from '../../../../../components/Placeholder/constants';

export const Components = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    padding: 24px 16px;
    background-color: ${AppCSS.colors.white};
    ${AppCSS.boxShadows.block};
    overflow: hidden;
    height: min-content;
    width: 100%;
  `,
  HeaderContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid ${AppCSS.colors.utility.borders};
    width: 100%;
    height: 40px;
  `,
  HeaderTitle: styled.span`
    ${AppCSS.fonts.label16Medium};
    color: ${AppCSS.colors.greens.green90};
  `,
  ButtonsContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  `,
  AddNoteButton: styled(AppCSS.buttonStyles.TertiaryButtonStyle)``,
  CancelButton: styled(AppCSS.buttonStyles.TertiaryButtonStyle)``,
  SaveButton: styled(AppCSS.buttonStyles.SecondaryButtonStyle)``,
  BodyContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
  `,
  LoaderContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${DEFAULT_PLACEHOLDER_HEIGHT}px;
    width: 100%;
  `,
  NotesContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
  `,
  NoteContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  `,
  NoteText: styled.span`
    ${AppCSS.fonts.paragraph14Regular};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  NoteTimestamp: styled.span`
    text-transform: capitalize;
    margin-left: auto;
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green70};
  `,
};
