import { Dispatch, SetStateAction, useState } from 'react';
import _ from 'lodash';

const useFormState = <T extends string | number | Date | undefined, V extends { target?: { value?: T } }>(
  initialValue?: T,
  process?: (value: T) => T | undefined,
  debounceTime?: number,
): {
  value: T | undefined;
  setValue: Dispatch<SetStateAction<T | undefined>>;
  onChange: (e: V) => void;
} => {
  const [value, setValue] = useState<T | undefined>(initialValue);

  const updateValue = _.debounce((value: T | undefined) => setValue(value), debounceTime);

  const onChange = (e: V) => {
    const newValue = process ? (e.target && e.target.value ? process(e.target.value) : undefined) : e?.target?.value;
    if (debounceTime && debounceTime > 0) {
      updateValue(newValue);
    } else {
      setValue(newValue);
    }
  };
  return { value, setValue, onChange };
};

export default useFormState;
