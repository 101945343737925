import styled from 'styled-components';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import AppCSS from '../../../utils/styled';
import { UIView } from '../../../utils/styled/styled';

export const CommonComponents = {
  TabPanel: styled(TabPanelUnstyled)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 16px;
  `,
  TabContainer: styled(UIView)`
    height: 100%;
    overflow-y: auto;
  `,
  Header: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    margin-bottom: 16px;
  `,
  Title: styled.span`
    ${AppCSS.fonts.h3};
    color: ${AppCSS.colors.greens.green90};
  `,
  ButtonsContainer: styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  `,
};
