import React from 'react';
import { Components } from './styled';

const DayEvents = ({
  date,
  hasHearings,
  isCurrentDate,
}: {
  date: Date;
  hasHearings?: boolean;
  isCurrentDate?: boolean;
}) => {
  return (
    <Components.DayTileWrapper>
      {date.getDate()}
      <Components.DotsWrapper>
        {hasHearings && <Components.Dot variant={'hearing'} isCurrentDate={isCurrentDate} />}
      </Components.DotsWrapper>
    </Components.DayTileWrapper>
  );
};

export default DayEvents;
