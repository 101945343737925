import styled from 'styled-components';
import AppCSS from '../../utils/styled';

import TabsUnstyled from '@mui/base/TabsUnstyled';

export const Components = {
  Container: styled.div<{ isOnMobile: boolean }>`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 16px ${(p) => (p.isOnMobile ? 16 : 24)}px;
    background-color: ${AppCSS.colors.utility.screenBackground};
    gap: 32px;
  `,
  Label: styled.span`
    ${AppCSS.fonts.label16Regular};
    color: ${AppCSS.colors.greens.green90};
  `,
  Link: styled(AppCSS.buttonStyles.TertiaryButtonStyle)``,
  BlockContainer: styled.div`
    width: 100%;
    max-width: 1000px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 16px;
    background-color: ${AppCSS.colors.white};
    gap: 12px;
  `,
  Tabs: styled(TabsUnstyled)`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  `,
};
