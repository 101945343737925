import { GridRowsProp } from '@mui/x-data-grid';
import { ProjectTableDataType, ProjectTableType } from './types';
import { Case, OverallSyncLog } from '../../api/types/case';
import { concatArrayOfStrings } from '../../utils/strings';
import { Profile, SavedCase } from '../../api/types/profile';
import { parseCaseNumberAndInstitution, parsePortalCaseNumber } from '../../api/portal/utils';

const getSearchValue = (values: (string | undefined)[]): string | undefined => {
  return values && values?.length > 0 ? values?.reduce((result, item) => result?.concat(' ', item || '')) : undefined;
};

export const parseDataToRows = (type: ProjectTableType, data: ProjectTableDataType): GridRowsProp => {
  switch (type) {
    case 'cases':
      return (data as Case[])?.map((item) => {
        const partiesNames = item?.parties?.map((item) => item?.name);
        return {
          id: item?.id,
          caseNumber: item?.displayNumber,
          matter: item?.matter,
          parties: item?.parties,
          institution: item?.displayInstitution,
          proceduralStage: item?.displayProceduralStage,
          searchValue: getSearchValue([
            item?.displayNumber,
            item?.matter,
            concatArrayOfStrings(partiesNames),
            item?.displayInstitution,
          ]),
        };
      });
    case 'users':
      return (data as Profile[])?.map((item) => {
        return {
          id: item?.id,
          name: item?.fullName,
          email: item?.email,
          phoneNumber: item?.phoneNumberCode + item.phoneNumber,
          casesCount: item?.cases?.length,
          notesCount: item?.noteIds?.length || 0,
          createdAt: item?.createdAt,
          searchValue: getSearchValue([item?.id, item?.fullName, item?.email]),
        };
      });
    case 'casesPreview':
      return (data as SavedCase[])?.map((item) => {
        const { caseNumber, displayInstitution } = parseCaseNumberAndInstitution(item?.number);
        return {
          id: item?.id,
          caseNumber,
          institution: displayInstitution,
          searchValue: getSearchValue([item?.id, caseNumber, displayInstitution]),
        };
      });
    case 'overallSyncLogs':
      return (data as OverallSyncLog[])?.map((item) => {
        return {
          id: item?.id,
          createdAt: item?.createdAt,
          finishedAt: item?.finishedAt,
          type: item?.type,
          casesFetchedFromFirestore: item?.casesFetchedFromFirestore?.length,
          syncedCases: item?.syncedCases?.length,
        };
      });
  }
};
