import React from 'react';
import { Dispatch, SetStateAction } from 'react';

export interface UIProviderContextType {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const UIProviderContext = React.createContext<UIProviderContextType>({
  isModalOpen: false,
  setIsModalOpen: () => {},
});
