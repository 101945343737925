import React, { useContext, useEffect } from 'react';
import { Components } from './styled';
import ProjectTable from '../../../components/ProjectTable';
import { tableColumns } from '../../../components/ProjectTable/constants';
import ProjectContainer from '../../../components/containers/ProjectContainer';
import useFormTextState from '../../../hooks/useFormTextState';
import { useQuery } from '@tanstack/react-query';
import { getSyncLogs } from '../../../api/case';
import { ActivityIndicatorContext } from '../../../providers/ActivityIndicatorProvider/context';
import { useNavigate } from 'react-router-dom';

const CasesSync = () => {
  const { setIsLoading } = useContext(ActivityIndicatorContext);

  const navigate = useNavigate();
  const { isLoading, data } = useQuery({ queryKey: ['casesSyncLogs'], queryFn: getSyncLogs });

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  const onViewSyncLog = (id: string) => {
    navigate(`/cases-sync/${id}`);
  };

  return (
    <ProjectContainer title={'Sincronizare'}>
      <Components.Container>
        <ProjectTable data={data || []} columns={tableColumns.overallSyncLogs({ onViewDetails: onViewSyncLog })} />
      </Components.Container>
    </ProjectContainer>
  );
};

export default CasesSync;
