import styled from 'styled-components';
import AppCSS from '../../utils/styled';

export const Components = {
  Container: styled.div<{ canBeClicked?: boolean }>`
    display: flex;
    margin-right: 0;
    ${({ canBeClicked }) => canBeClicked && 'cursor: pointer;'};
  `,

  UserImage: styled.img<{ dimension: number }>`
    height: ${(p) => p.dimension}px;
    width: ${(p) => p.dimension}px;
    ${(p) => AppCSS.radiuses.forHeight(p.dimension)};
    object-fit: cover;
  `,

  UserBgIcon: styled.div<{ dimension: number }>`
    height: ${(p) => p.dimension}px;
    width: ${(p) => p.dimension}px;
    ${(p) => AppCSS.radiuses.forHeight(p.dimension)};
    background-color: ${AppCSS.colors.primaryGreenGoth};
    justify-content: center;
    align-items: center;
    display: flex;
  `,

  UserInitials: styled.span<{ size: 24 | 32 | 42 | 56 | 64 | 96 }>`
    color: ${AppCSS.colors.white};
    ${({ size }) => {
      switch (size) {
        case 24:
          return AppCSS.fonts.label12Bold;
        case 32:
        case 42:
          return AppCSS.fonts.label14Bold;
        case 56:
        case 64:
        case 96:
          return AppCSS.fonts.h2;
      }
    }};
  `,
};
