import styled from 'styled-components';
import AppCSS from '../../../utils/styled';

export const Components = {
  Container: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    ${AppCSS.radiuses.verySmall};
    background-color: ${AppCSS.colors.greens.green50};
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green80};
    min-width: max-content;
  `,
};
