import FONTS, { FontType } from '../../assets/fonts';
import styled from 'styled-components';
import { RADIUS_SIZES } from './constants';

const projectBlack = '#000000';
const projectWhite = '#ffffff';
const primaryGreenGoth = '#26532B';
const orange = '#ff7300';
const red = '#F55C47';
const green50 = '#E1E8E2';
const utilityBackground = '#E5E5E5';

const colors = {
  utility: {
    screenBackground: '#F4F4F4',
    activityIndicator: { color: primaryGreenGoth, background: primaryGreenGoth + '40' },
    headerBar: {
      background: '#F9F9F9',
    },
    table: {
      headerBackground: '#767977' + '10',
    },
    borders: green50,
    backgroundTransparent80: utilityBackground + '80',
    backgroundTransparent40: utilityBackground + '40',
  },

  primaryMatteBlack: '#191919',
  primaryGreenGoth: primaryGreenGoth,

  secondaryGravelGreen: '#767977',
  secondaryGold: '#D4AF37',

  greens: {
    green90: '#272B28',
    green80: '#4C504D',
    green70: '#848985',
    green60: '#B4B9B5',
    green50,
    green40: '#F3F6F4',
  },

  golds: {
    gold80: '#C19D25',
    gold60: '#DABA54',
    gold40: '#F5D87A',
    gold20: '#FFEBA7',
  },

  orange,
  oranges: {
    light: '#ffb27a',
  },
  red,
  reds: {
    light: '#ffada9',
  },

  black: projectBlack,
  white: projectWhite,
};

const createFontCSS = (size = 16, fontFamily: FontType = FONTS.openSansRegular, lineHeight?: number | undefined) => {
  return `
      font-size: ${size}px;
      ${fontFamily?.length > 0 ? `font-family: ${fontFamily}` : ''};
      ${lineHeight !== null && lineHeight !== undefined ? `line-height: ${lineHeight}px;` : ''};
    `;
};

const fonts = {
  h1: createFontCSS(32, FONTS.poppinsBold),
  h2: createFontCSS(24, FONTS.poppinsBold),
  h3: createFontCSS(20, FONTS.poppinsBold),
  h4: createFontCSS(16, FONTS.poppinsBold),
  h5: createFontCSS(14, FONTS.poppinsBold),
  h6: createFontCSS(12, FONTS.poppinsBold),

  label20Regular: createFontCSS(20, FONTS.openSansRegular),
  label16Bold: createFontCSS(16, FONTS.openSansBold),
  label16Medium: createFontCSS(16, FONTS.openSansSemiBold),
  label16Regular: createFontCSS(16, FONTS.openSansRegular),
  label14Bold: createFontCSS(14, FONTS.openSansBold),
  label14Medium: createFontCSS(14, FONTS.openSansSemiBold),
  label14Regular: createFontCSS(14, FONTS.openSansRegular),
  label12Bold: createFontCSS(12, FONTS.openSansBold),
  label12Medium: createFontCSS(12, FONTS.openSansSemiBold),
  label12Regular: createFontCSS(12, FONTS.openSansRegular),
  label10Regular: createFontCSS(10, FONTS.openSansRegular),

  paragraph14Regular: createFontCSS(14, FONTS.openSansRegular, 21),
  paragraph12Regular: createFontCSS(12, FONTS.openSansRegular, 18),
};

const radiuses = {
  verySmall: `border-radius: ${RADIUS_SIZES.verySmall}px;`,
  small: `border-radius: ${RADIUS_SIZES.small}px;`,
  default: `border-radius: ${RADIUS_SIZES.default}px;`,
  regular: `border-radius: ${RADIUS_SIZES.regular}px;`,
  big: `border-radius: ${RADIUS_SIZES.big}px;`,
  rounded: `border-radius: ${RADIUS_SIZES.veryBig}px;`,
  extra: `border-radius: ${RADIUS_SIZES.extra}px`,
  topRounded: `border-top-right-radius: ${RADIUS_SIZES.veryBig}px; border-top-left-radius: ${RADIUS_SIZES.veryBig}px;`,
  forHeight: (value: number) => `border-radius: ${RADIUS_SIZES.forHeight(value)}px;`,
};

const PrimaryButtonStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 40px;
  min-width: max-content;
  padding: 10px 16px;
  ${fonts.h5};
  color: ${colors.white};
  background-color: ${colors.primaryGreenGoth};
  cursor: pointer;
  &:disabled {
    cursor: auto;
  }
`;
const PrimaryButtonWithIconStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 40px;
  min-width: max-content;
  padding: 10px 12px 10px 8px;
  gap: 6px;
  ${fonts.h5};
  color: ${colors.white};
  background-color: ${colors.primaryGreenGoth};
  cursor: pointer;
  &:disabled {
    cursor: auto;
  }
`;

const SecondaryButtonStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 40px;
  min-width: max-content;
  padding: 10px 16px;
  ${fonts.h5};
  color: ${colors.primaryGreenGoth};
  border: 1px solid ${colors.primaryGreenGoth};
  cursor: pointer;
  &:disabled {
    cursor: auto;
  }
`;
const SecondaryButtonWithIconStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 40px;
  min-width: max-content;
  padding: 10px 12px 10px 8px;
  gap: 6px;
  ${fonts.h5};
  color: ${colors.primaryGreenGoth};
  border: 1px solid ${colors.primaryGreenGoth};
  cursor: pointer;
  &:disabled {
    cursor: auto;
  }
`;

const TertiaryButtonStyle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  min-width: max-content;
  ${fonts.h5};
  color: ${colors.primaryGreenGoth};
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  &:disabled {
    cursor: auto;
  }
`;

const buttonStyles = {
  PrimaryButtonStyle,
  PrimaryButtonWithIconStyle,
  SecondaryButtonStyle,
  SecondaryButtonWithIconStyle,
  TertiaryButtonStyle,
};

const boxShadows = {
  menu: 'box-shadow: 0px 6px 12px 0px #C3C3C340;',
  block: 'box-shadow: 0px 2px 4px 0px #BFBFBF40;',
  modal: 'box-shadow: 0px 2px 4px 0px #BFBFBF40;',
};

const AppCSS = {
  colors,
  radiuses,
  fonts,
  buttonStyles,
  boxShadows,
};

export default AppCSS;
