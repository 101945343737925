import React from 'react';
import styled from 'styled-components';

export const UIView = styled.div<{
  children?: React.ReactNode;
  backgroundColor?: string;
  displayFlex?: boolean;
  flexDirection?: 'row' | 'column';
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
  alignItems?: 'flex-start' | 'center' | 'flex-end';
  flex?: number;
  margin?: number;
  marginX?: string;
  gap?: number;
  width?: number | string;
  maxWidth?: number | string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  autoHeight?: boolean;
  fullScreenHeight?: boolean;
  height?: number | string;
  isRow?: boolean;
}>`
  ${(p) => p?.displayFlex && 'display: flex;'};
  ${(p) => (p?.flexDirection ? `flex-direction: ${p?.flexDirection};` : '')};
  ${(p) => (p?.backgroundColor ? `backgroundColor: ${p?.backgroundColor};` : '')};
  ${(p) => (p?.justifyContent && p?.justifyContent?.length > 0 ? `justify-content: ${p.justifyContent};` : '')};
  ${(p) => (p?.alignItems && p?.alignItems?.length > 0 ? `align-items: ${p.alignItems};` : '')};
  ${(p) => (p.flex ? `flex: ${p.flex};` : '')};
  ${(p) =>
    p.margin !== undefined && p.margin !== null
      ? `
      position: absolute;
      top: ${p.margin || 0}px;
      bottom: ${p.margin || 0}px;
      left: ${p.margin || 0}px;
      right: ${p.margin || 0}px;
      `
      : ''};
  ${(p) => (p?.height ? `height: ${p?.height}px;` : '')};
  ${(p) => (p?.width ? `width: ${p?.width}px;` : '')};
  ${(p) => (p?.gap ? `gap: ${p?.gap}px;` : '')};
  ${(p) => (p?.fullWidth ? 'width: 100%;' : '')};
  ${(p) => (p?.fullHeight ? 'height: 100%;' : '')};
  ${(p) => (p?.autoHeight ? 'height: auto;' : '')};
  ${(p) => (p?.fullScreenHeight ? 'height: 100vh;' : '')};
  ${(p) => (p?.isRow ? 'flex-direction: row;' : '')};
`;
