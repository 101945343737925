import React, { useContext, useMemo } from 'react';
import { SubscriptionContext } from './context';
import { createCheckoutSession, createCustomerPortalSession } from '../../api/subscription';
import { SessionContext } from '../SessionProvider/context';
import { getProjectErrorForCode } from '../../utils/errors';
import { ProjectErrors } from '../../utils/errors/types';
import { CreateCheckoutSessionDto } from '../../api/typeDtos/subscription';
import { SUBSCRIPTION_TYPE } from '../../defines';
import { getDifferenceInDays } from '../../utils/dateFormat';

const SubscriptionProvider = ({ children }: { children: React.ReactNode }) => {
  const { currentProfile } = useContext(SessionContext);

  const createCheckoutSessionAction = async (productPriceId: string) => {
    if (currentProfile) {
      const dto: CreateCheckoutSessionDto = {
        priceId: productPriceId,
        customerId: currentProfile?.stripeCustomerId,
      };
      return await createCheckoutSession(dto);
    }
    return getProjectErrorForCode(ProjectErrors.genericTryAgain);
  };

  const createCustomerPortalSessionAction = async () => {
    if (currentProfile) {
      return await createCustomerPortalSession(currentProfile?.stripeCustomerId);
    }
    return getProjectErrorForCode(ProjectErrors.genericTryAgain);
  };

  const isPayingUser = () => {
    return currentProfile?.subscriptionType === SUBSCRIPTION_TYPE.pro;
  };

  const isActiveTrial = () => {
    const nowTs = new Date().getTime();
    return (
      currentProfile?.subscriptionType === SUBSCRIPTION_TYPE.trial &&
      currentProfile?.subscriptionEndDate?.getTime() > nowTs
    );
  };

  const isSubscriptionActive = () => {
    return isPayingUser() || isActiveTrial();
  };

  const isThreeDaysBeforeTrialExpiration = () => {
    if (currentProfile?.subscriptionEndDate) {
      const nowTs = new Date().getTime();
      const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;
      const isThreeDaysBeforeExpiration = currentProfile?.subscriptionEndDate?.getTime() - nowTs < threeDaysInMs;
      return isActiveTrial() && isThreeDaysBeforeExpiration;
    }
    return false;
  };

  const isExpiredTrial = () => {
    if (currentProfile?.subscriptionEndDate) {
      const nowTs = new Date().getTime();
      const isExpired = currentProfile?.subscriptionEndDate?.getTime() < nowTs;
      return currentProfile?.subscriptionType === SUBSCRIPTION_TYPE.trial && isExpired;
    }
    return false;
  };

  const value = useMemo(
    () => ({
      createCheckoutSession: createCheckoutSessionAction,
      createCustomerPortalSession: createCustomerPortalSessionAction,
      isPayingUser,
      isActiveTrial,
      isSubscriptionActive,
      isThreeDaysBeforeTrialExpiration,
      isExpiredTrial,
    }),
    [currentProfile],
  );

  return <SubscriptionContext.Provider value={value}>{children}</SubscriptionContext.Provider>;
};

export default SubscriptionProvider;
