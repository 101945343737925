import React, { useContext } from 'react';
import { Components } from './styled';
import { CommonComponents } from '../styled';
import { MY_ACCOUNT_TABS } from '../../constants';
import { AccountTabPanel } from '../index';
import ProjectTextField from '../../../../components/inputs/ProjectTextField';
import { SessionContext } from '../../../../providers/SessionProvider/context';
import ProjectPhoneNumberField from '../../../../components/inputs/ProjectPhoneNumberField';

const ProfileTab = ({ tab }: { tab: number }) => {
  const { currentProfile } = useContext(SessionContext);

  return (
    <AccountTabPanel tab={tab} index={MY_ACCOUNT_TABS.profile.index}>
      <CommonComponents.Header>
        <CommonComponents.Title>Profil</CommonComponents.Title>
        <CommonComponents.ButtonsContainer />
      </CommonComponents.Header>
      <Components.Container>
        <Components.FieldLabel>Nume de familie</Components.FieldLabel>
        <ProjectTextField value={currentProfile?.lastName} type={'text'} isDisabled />
        <Components.FieldLabel>Prenume</Components.FieldLabel>
        <ProjectTextField value={currentProfile?.firstName} type={'text'} isDisabled />
        <Components.FieldLabel>Email</Components.FieldLabel>
        <ProjectTextField value={currentProfile?.email} type={'text'} isDisabled />
        <Components.FieldLabel>Număr de telefon</Components.FieldLabel>
        <ProjectPhoneNumberField value={currentProfile?.phoneNumber} isDisabled />
      </Components.Container>
    </AccountTabPanel>
  );
};

export default ProfileTab;
