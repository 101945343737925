import styled from 'styled-components';

export const DefaultModalComponents = {
  ModalBodyContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 16px;
    width: 100%;
  `,
};
