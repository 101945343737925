import styled from 'styled-components/';
import Dialog from '@mui/material/Dialog';
import AppCSS from '../../utils/styled';
import DialogContent from '@mui/material/DialogContent';
import { DEFAULT_PROJECT_MODAL_WIDTH } from '../../constants';

export const Components = {
  Modal: styled(Dialog)<{ modalWidth?: number | 'auto' }>`
    & .MuiDialog-paper {
      ${({ modalWidth }) =>
        modalWidth === 'auto'
          ? 'width: auto;'
          : `
        width: ${modalWidth ? modalWidth : DEFAULT_PROJECT_MODAL_WIDTH}px;
        max-width: ${modalWidth ? modalWidth : DEFAULT_PROJECT_MODAL_WIDTH}px;
      `}
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: ${AppCSS.colors.white};
      ${AppCSS.boxShadows.modal};
      ${AppCSS.radiuses.default};
    }
  `,

  ModalContentContainer: styled(DialogContent)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0 !important;
  `,
};
