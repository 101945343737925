import React from 'react';
import { Components } from './styled';

const ActivityIndicator = ({ type = 'default' }: { type?: 'verySmall' | 'small' | 'default' }) => {
  const size = type === 'verySmall' ? 24 : 'small' ? 32 : 48;

  return <Components.CircularProgress size={size} />;
};

export default ActivityIndicator;
