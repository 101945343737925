import styled from 'styled-components';
import AppCSS from '../../../utils/styled';

export const Components = {
  Container: styled.div<{ isFullHeight?: boolean }>`
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    ${({ isFullHeight }) => isFullHeight && 'height: 100%; & > textarea { height: 100%; }'};
  `,
  TextArea: styled.textarea`
    resize: none;
    flex: 1;
    background-color: ${AppCSS.colors.white};
    padding: 12px 16px;
    ${AppCSS.radiuses.small};
    border: 1px solid ${AppCSS.colors.greens.green60};
    ${AppCSS.fonts.label14Regular};
    color: ${AppCSS.colors.greens.green90};
    -webkit-appearance: none;
    &::placeholder {
      color: ${AppCSS.colors.greens.green60};
    }
    &:focus-visible {
      -webkit-appearance: none;
      border: 1px solid ${AppCSS.colors.greens.green60};
      outline: none;
    }
    &:focus {
      -webkit-appearance: none;
      border: 1px solid ${AppCSS.colors.greens.green60};
      outline: none;
    }
    &:disabled {
      background-color: ${AppCSS.colors.greens.green40};
    }
  `,
};
