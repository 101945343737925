import styled from 'styled-components';
import AppCSS from '../../../../utils/styled';

export const Components = {
  Container: styled.div`
    cursor: pointer;
  `,
  Text: styled.span`
    ${AppCSS.fonts.label14Medium};
    color: ${AppCSS.colors.primaryGreenGoth};
  `,
};
