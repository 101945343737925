import React, { useContext, useEffect, useState } from 'react';
import { Components } from './styled';
import { PortalCaseHearing, SavedCaseParty } from '../../../../api/types/portal';
import { isSameDay, parseDateToStringWithFormat } from '../../../../utils/dateFormat';
import { format } from '../../../../utils/dateFormat/types';
import Placeholder from '../../../../components/Placeholder';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/icon-arrow-right.svg';
import { ReactComponent as PinIcon } from '../../../../assets/icons/icon-pin.svg';
import { SessionContext } from '../../../../providers/SessionProvider/context';
import Parties from '../../../../components/caseComponents/Parties';

const CaseItem = ({
  hearingData,
  onSeeInCasePress,
}: {
  hearingData: PortalCaseHearing;
  onSeeInCasePress: (caseId: string) => void;
}) => {
  const { currentProfile } = useContext(SessionContext);

  const [savedParty, setSavedParty] = useState<SavedCaseParty | undefined>();

  const getSavedParty = () => {
    const foundParty = currentProfile
      ? currentProfile?.savedParties?.find((item) => item?.caseId === hearingData?.caseId)
      : undefined;
    if (foundParty) {
      setSavedParty(foundParty);
    } else {
      setSavedParty(undefined);
    }
  };

  useEffect(() => {
    getSavedParty();
  }, [currentProfile, hearingData]);

  return (
    <Components.HearingContainer>
      <Components.HearingHeaderContainer>
        <Components.HearingDate>
          {hearingData?.hour} -{' '}
          <Components.HearingCaseNumber onClick={() => hearingData?.caseId && onSeeInCasePress(hearingData?.caseId)}>
            {hearingData?.case?.displayNumber}
          </Components.HearingCaseNumber>
        </Components.HearingDate>

        {hearingData?.caseId && (
          <Components.SeeInCaseButton onClick={() => hearingData?.caseId && onSeeInCasePress?.(hearingData?.caseId)}>
            Vezi în dosar
            <ArrowRightIcon />
          </Components.SeeInCaseButton>
        )}
      </Components.HearingHeaderContainer>
      {(hearingData?.panel || savedParty) && (
        <Components.PanelAndPartyWrapper>
          {hearingData?.panel && (
            <Components.PanelLabel>
              Complet - <Components.LocationLabel>{hearingData?.panel}</Components.LocationLabel>
            </Components.PanelLabel>
          )}

          {savedParty && (
            <>
              <Components.Separator />
              <Parties parties={[savedParty]} showAllParties savedParty={savedParty} />
            </>
          )}
        </Components.PanelAndPartyWrapper>
      )}

      {hearingData?.case?.displayInstitution && (
        <Components.LocationWrapper>
          <PinIcon width={20} height={20} />
          <Components.LocationLabel>{hearingData?.case?.displayInstitution}</Components.LocationLabel>
        </Components.LocationWrapper>
      )}
    </Components.HearingContainer>
  );
};

const CalendarDayView = ({
  date,
  hearings,
  onSeeInCasePress,
}: {
  date: Date;
  hearings: PortalCaseHearing[];
  onSeeInCasePress: (caseId: string) => void;
}) => {
  const hearingsForCurrentDate = hearings?.filter((item) => isSameDay(date, item?.portalDate));
  const title = parseDateToStringWithFormat(date, format.weekDayMonthDay);

  return (
    <Components.Container>
      <Components.Header>
        <Components.HeaderTitle>{title}</Components.HeaderTitle>
      </Components.Header>
      <Components.HearingsContainer>
        {hearingsForCurrentDate && hearingsForCurrentDate?.length > 0 ? (
          hearingsForCurrentDate.map((item, key) => (
            <CaseItem key={key} hearingData={item} onSeeInCasePress={onSeeInCasePress} />
          ))
        ) : (
          <Placeholder text={'Nu sunt termene ce pot fi afișate pentru ziua selectată'} />
        )}
      </Components.HearingsContainer>
    </Components.Container>
  );
};

export default CalendarDayView;
