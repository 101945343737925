import styled from 'styled-components';
import { SIDEBAR_WIDTH } from '../../../utils/styled/dimensions';
import { UIView } from '../../../utils/styled/styled';

export const Components = {
  RightSideContainer: styled(UIView)<{ addedWidht: number }>`
    width: calc(100% - ${(p) => p.addedWidht}px);
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  `,
  ChildrenWrapper: styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
  `,
};
