import * as yup from 'yup';
import { isValidYupSchema, yupUtils } from '../utils';
import { Profile } from '../types/profile';
import { ProjectErrors, ProjectErrorType } from '../../utils/errors/types';
import { getProjectErrorForCode, getProjectErrorFromRawError, isProjectError } from '../../utils/errors';
import { getFullName, getInitials } from './utils';
import { SUBSCRIPTION_TYPE } from '../../defines';

const SavedPartySchema = yup.object().shape({
  caseId: yupUtils.stringRequired,
  name: yupUtils.stringRequired,
  partyRole: yupUtils.stringRequired,
});

const ProfileSchema = yup.object().shape({
  id: yupUtils.stringRequired,
  createdAt: yupUtils.numberRequired,
  updatedAt: yupUtils.numberRequired,
  firstName: yupUtils.stringRequired,
  email: yupUtils.stringRequired,
  lastName: yupUtils.stringRequired,
  cases: yupUtils.arrayRequiredOf(yup.object().shape({ id: yupUtils.stringRequired, number: yupUtils.stringRequired })),
  archivedCases: yupUtils.arrayOptionalNullableOf(
    yup.object().shape({ id: yupUtils.stringRequired, number: yupUtils.stringRequired }),
  ),
  notificationCases: yupUtils.arrayRequiredOf(
    yup.object().shape({ id: yupUtils.stringRequired, number: yupUtils.stringRequired }),
  ),
  savedParties: yupUtils.arrayOptionalNullableOf(SavedPartySchema),
  noteIds: yupUtils.arrayOptionalNullableOf(yupUtils.stringRequired),
  taskIds: yupUtils.arrayOptionalNullableOf(yupUtils.stringRequired),
  phoneNumber: yupUtils.stringRequired,
  phoneNumberCode: yupUtils.stringRequired,
  isPhoneNumberVerified: yupUtils.booleanRequired,
  isAdmin: yupUtils.booleanOptional,
  subscriptionType: yupUtils.stringRequiredOneOf(Object.values(SUBSCRIPTION_TYPE)),
  subscriptionEndDate: yupUtils.numberRequired,
  stripeCustomerId: yupUtils.stringRequired,
  isOnboardingDone: yupUtils.booleanRequired,
});

const processProfile = (object: any): Profile => {
  return {
    ...object,
    fullName: getFullName({ firstName: object?.firstName, lastName: object?.lastName }),
    initials: getInitials({ firstName: object?.firstName, lastName: object?.lastName }),
    createdAt: new Date(object?.createdAt),
    updatedAt: new Date(object?.updatedAt),
    caseIds: object?.cases?.map((item: any) => item?.id),
    subscriptionEndDate: new Date(object?.subscriptionEndDate),
  };
};

export const parseProfile = async (object: any): Promise<Profile | ProjectErrorType> => {
  try {
    if (await isValidYupSchema(ProfileSchema, object)) {
      return processProfile(object);
    }
    return getProjectErrorForCode(ProjectErrors.profileInvalid);
  } catch (error) {
    console.log('Error at validating profile (id:', object?.id, '):', error);
    return getProjectErrorFromRawError(error as Error);
  }
};

export const parseProfiles = async (items: any): Promise<Profile[]> => {
  if (!(items && Array.isArray(items))) {
    console.log('[PROFILES] Error: No array found.');
    return [];
  }

  const list: Profile[] = [];
  for (const item of items) {
    const o = await parseProfile(item);
    o && !isProjectError(o) && list.push(o as Profile);
  }

  return list;
};
