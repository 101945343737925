import React, { useContext, useEffect, useState } from 'react';
import { Components } from './styled';
import Placeholder from '../../../../../components/Placeholder';
import { SessionContext } from '../../../../../providers/SessionProvider/context';
import { addNote, getAllNotes } from '../../../../../api/note';
import { Note } from '../../../../../api/types/note';
import ActivityIndicator from '../../../../../components/indicators';
import { parseDateToStringWithFormat } from '../../../../../utils/dateFormat';
import { format } from '../../../../../utils/dateFormat/types';
import ProjectTextArea from '../../../../../components/inputs/ProjectTextArea';
import useFormTextState from '../../../../../hooks/useFormTextState';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/icon-plus-alt.svg';
import { ToastContext } from '../../../../../providers/ToastProvider/context';
import { createRandomKey } from '../../../../../utils/strings';
import { NotesContext } from '../../../../../providers/NotesProvider/context';
import { SubscriptionContext } from '../../../../../providers/SubsctiptionProvider/context';
import { ProjectErrors } from '../../../../../utils/errors/types';
import { EVENTS, logEvent } from '../../../../../_analytics';
import { Case } from '../../../../../api/types/case';

const CaseNotes = ({ caseData }: { caseData?: Case }) => {
  const { currentProfile } = useContext(SessionContext);
  const { isSubscriptionActive } = useContext(SubscriptionContext);
  const { showWarningToast } = useContext(ToastContext);
  const { saveNoteOnServer } = useContext(NotesContext);

  const [notes, setNotes] = useState<Note[]>([]);
  const [isFetchingNotes, setIsFetchingNotes] = useState(false);
  const [isAddingNote, setIsAddingNote] = useState(false);

  const addNoteProps = useFormTextState();

  const refreshNotes = async () => {
    if (currentProfile?.id && caseData?.id) {
      setIsFetchingNotes(true);
      const result = await getAllNotes(currentProfile?.id, caseData?.id);
      setIsFetchingNotes(false);

      if (result && Array.isArray(result)) {
        setNotes(result as Note[]);
      }
    } else {
      setNotes([]);
    }
  };

  useEffect(() => {
    refreshNotes().then();
  }, []);

  const toggleAddNote = () => {
    setIsAddingNote(!isAddingNote);
  };

  const onAddNote = () => {
    if (!isSubscriptionActive()) {
      showWarningToast(ProjectErrors.expiredSubscription);
      return;
    }
    toggleAddNote();
  };

  const onCancelAddingNote = () => {
    toggleAddNote();
    addNoteProps.setValue(undefined);
  };

  const onSaveNote = () => {
    if (currentProfile && caseData?.id) {
      const noteText = addNoteProps.value;
      if (noteText && noteText?.length > 0) {
        saveNoteOnServer(caseData?.id, noteText).then();
        logEvent(
          EVENTS.add_case_note({
            caseNumber: caseData?.displayNumber,
            caseInstitution: caseData?.displayInstitution,
            caseCategory: caseData?.displayCategory,
            noteLength: noteText?.length,
          }),
        ).then();

        const now = new Date();
        const noteObject: Note = {
          id: createRandomKey(),
          createdAt: now,
          updatedAt: now,
          profileId: currentProfile?.id,
          caseId: caseData?.id,
          text: noteText,
        };
        const newNotes = [...notes, noteObject];
        setNotes(newNotes);
      }
      onCancelAddingNote();
    } else {
      showWarningToast('A apărut o eroare la adăugarea notei. Vă rugăm încercați din nou.');
    }
  };

  return (
    <Components.Container>
      <Components.HeaderContainer>
        <Components.HeaderTitle>Notițe</Components.HeaderTitle>
        {isAddingNote ? (
          <Components.ButtonsContainer>
            <Components.CancelButton onClick={onCancelAddingNote}>Anulează</Components.CancelButton>
            <Components.SaveButton onClick={onSaveNote}>Salvează</Components.SaveButton>
          </Components.ButtonsContainer>
        ) : (
          <Components.AddNoteButton onClick={onAddNote}>
            <PlusIcon />
            Adaugă
          </Components.AddNoteButton>
        )}
      </Components.HeaderContainer>
      <Components.BodyContainer>
        <Components.NotesContainer>
          {isAddingNote && <ProjectTextArea {...addNoteProps} placeholder={'Introduceți textul'} />}
          {notes && notes?.length > 0 ? (
            notes?.map((item) => {
              const _createdAt = parseDateToStringWithFormat(item?.createdAt, format.weekDayMonthDayYearTime);
              return (
                <Components.NoteContainer key={item?.id}>
                  <Components.NoteText>{item?.text}</Components.NoteText>
                  <Components.NoteTimestamp>{_createdAt}</Components.NoteTimestamp>
                </Components.NoteContainer>
              );
            })
          ) : isFetchingNotes ? (
            <Components.LoaderContainer>
              <ActivityIndicator />
            </Components.LoaderContainer>
          ) : (
            <Placeholder text={'Nu există note ce pot fi afișate pentru acest dosar'} />
          )}
        </Components.NotesContainer>
      </Components.BodyContainer>
    </Components.Container>
  );
};

export default CaseNotes;
