import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { OnboardingContext } from './context';
import { OnboardingStepStatus, OnboardingStepType } from './types';
import { SessionContext } from '../SessionProvider/context';
import { ONBOARDING_STEPS, ONBOARDING_STEPS_COUNT } from './constants';
import { setDidFinishOnboarding } from '../../api/profile';
import useLocalStorage from '../../hooks/useLocalStorage';

const OnboardingProvider = ({ children }: { children: React.ReactNode }) => {
  const { currentProfile } = useContext(SessionContext);

  const [localDidFinish, _] = useLocalStorage<boolean>('didFinishOnboarding', true);
  const [completedStepsCount, setCompletedStepsCount] = useState(1);
  const [shouldShowOnboarding, setShouldShowOnboarding] = useState(!localDidFinish);
  const initialProcessShowOnbRef = useRef(false);

  const processShouldShowOnboarding = () => {
    const didFinish = didCompleteOnboarding();
    setShouldShowOnboarding(!didFinish);
  };

  useEffect(() => {
    if (currentProfile) {
      if (!initialProcessShowOnbRef.current) {
        processShouldShowOnboarding();
      }
      if (currentProfile && didCompleteOnboarding()) {
        setDidFinishOnboarding(currentProfile?.id).then();
      }
      if (completedStepsCount !== 1) {
        initialProcessShowOnbRef.current = true;
      }
    }
  }, [completedStepsCount, currentProfile]);

  const processCompletedSteps = () => {
    const completedSteps = ONBOARDING_STEPS.reduce((res, item) => {
      const status = getStepStatus(item);
      if (status === 'done') {
        return res + 1;
      }
      return res;
    }, 0 as number);
    setCompletedStepsCount(completedSteps);
  };

  useEffect(() => {
    if (currentProfile) {
      processCompletedSteps();
    } else {
      setCompletedStepsCount(1);
    }
  }, [currentProfile]);

  const getStepStatus = (step: OnboardingStepType): OnboardingStepStatus => {
    const didAddCases =
      (currentProfile?.cases && currentProfile?.cases?.length > 0) ||
      (currentProfile?.archievedCases && currentProfile?.archievedCases?.length > 0);
    const didAddNotes = currentProfile?.noteIds ? currentProfile?.noteIds?.length > 0 : false;
    const didAddFirstTask = currentProfile?.taskIds ? currentProfile?.taskIds?.length > 0 : false;
    switch (step) {
      case 'register':
        return 'done';
      case 'addFirstCase':
        return didAddCases ? 'done' : 'current';
      case 'addFirstNote':
        return !didAddCases ? 'future' : didAddNotes ? 'done' : 'current';
      default:
        return 'current';
    }
  };

  const didCompleteOnboarding = (): boolean => {
    return completedStepsCount === ONBOARDING_STEPS_COUNT;
  };

  const onCompletingOnboarding = () => {
    setShouldShowOnboarding(false);
  };

  const value = useMemo(
    () => ({
      completedStepsCount,
      shouldShowOnboarding,
      getStepStatus,
      didCompleteOnboarding,
      onCompletingOnboarding,
    }),
    [completedStepsCount, shouldShowOnboarding],
  );

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
};

export default OnboardingProvider;
