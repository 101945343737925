import React, { ChangeEvent } from 'react';
import { Components } from './styled';

const ProjectTextArea = ({
  value,
  onChangeText,
  onBlur,
  rows = 4,
  placeholder,
  disabled = false,
  isFullHeight = false,
}: {
  value?: string;
  onChangeText?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: () => void;
  rows?: number;
  placeholder?: string;
  disabled?: boolean;
  isFullHeight?: boolean;
}) => {
  const _onChangeText = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    onChangeText?.(e);
  };

  const _onBlur = () => {
    onBlur?.();
  };

  return (
    <Components.Container isFullHeight={isFullHeight}>
      <Components.TextArea
        value={value}
        rows={rows}
        placeholder={placeholder}
        onChange={_onChangeText}
        onBlur={_onBlur}
        disabled={disabled}
      />
    </Components.Container>
  );
};

export default ProjectTextArea;
