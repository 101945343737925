import React from 'react';
import { Components } from './styled';
import { ReactComponent as SuccessfulSubIllustration } from '../../../assets/illustrations/illustration-successful-sub.svg';
import ProjectSimpleModal from '../../ProjectSimpleModal';
import { UIView } from '../../../utils/styled/styled';
import { ReactComponent as CloseIcon } from '../../../assets/icons/icon-close.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/icon-arrow-right.svg';

const NewSubscriptionModal = ({ isModalOpen, onModalClose }: { isModalOpen: boolean; onModalClose: () => void }) => {
  return <ProjectSimpleModal bodyChildren={<ModalBody onModalClose={onModalClose} />} isModalOpen={isModalOpen} />;
};

export default NewSubscriptionModal;

const ModalBody = ({ onModalClose }: { onModalClose: () => void }) => {
  return (
    <Components.Container>
      <Components.HeaderWrapper
        flexDirection={'column'}
        fullWidth
        displayFlex
        alignItems={'flex-end'}
        justifyContent='center'>
        <Components.CloseIconWrapper onClick={onModalClose} alignItems={'center'} justifyContent='center'>
          <CloseIcon />
        </Components.CloseIconWrapper>
        <Components.Header>
          <Components.HeaderTitle>Felicitări! Ai acces la abonamentul Pro</Components.HeaderTitle>
          <Components.HeaderSubtitle>
            Acum ai la îndemână toate benefiicile platformei Juridiq
          </Components.HeaderSubtitle>
        </Components.Header>
      </Components.HeaderWrapper>

      <SuccessfulSubIllustration />
      <Components.ButtonsContainer onClick={onModalClose}>
        <Components.ContinueButton>
          Continuă
          <ArrowRightIcon />
        </Components.ContinueButton>
      </Components.ButtonsContainer>
    </Components.Container>
  );
};
