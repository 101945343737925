import styled from 'styled-components';
import AppCSS from '../../utils/styled';

export const Components = {
  Container: styled.div<{ isMobile: boolean }>`
    display: flex;
    ${(p) => p.isMobile && 'flex-direction: column;'};
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 16px;
    gap: 16px;
    background-color: ${AppCSS.colors.white};
    ${AppCSS.boxShadows};
    width: 100%;
  `,
  FieldWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    flex: 1;
  `,
  FieldLabel: styled.span`
    ${AppCSS.fonts.label12Bold};
    color: ${AppCSS.colors.greens.green70};
  `,
  TextFieldContainer: styled.div`
    width: 100%;
    border: 1px solid ${AppCSS.colors.greens.green60};
    ${AppCSS.radiuses.small};
    background-color: ${AppCSS.colors.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  TextField: styled.input`
    flex: 1;
    width: 100%;
    padding: 12px;
    height: 38px;
    ${AppCSS.fonts.label12Medium};
    color: ${AppCSS.colors.greens.green90};
    background-color: transparent;
    border: none;
    -webkit-appearance: none;
    &:focus-visible {
      -webkit-appearance: none;
      border: none;
      outline: none;
    }
    &:focus {
      -webkit-appearance: none;
      border: none;
      outline: none;
    }
    &::placeholder {
      color: ${AppCSS.colors.greens.green60};
    }
  `,
};
