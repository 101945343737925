import React, { useContext, useEffect } from 'react';
import { Components } from './styled';
import { ReactComponent as CloseIcon } from '../../assets/icons/icon-close.svg';
import { UIProviderContext } from '../../providers/UIProvider/context';

const ProjectModal = ({
  bodyChildren,
  buttonChildren,
  isModalOpen,
  onModalClose,
  modalTitle,
  scrollType,
  modalWidth,
  disableCloseOnClickAway,
  cannotClose,
}: {
  bodyChildren: React.ReactNode;
  buttonChildren: React.ReactNode;
  isModalOpen: boolean;
  onModalClose?: () => void;
  modalTitle: string;
  scrollType?: 'body' | 'paper';
  modalWidth?: number | 'auto';
  disableCloseOnClickAway?: boolean;
  cannotClose?: boolean;
}) => {
  const { setIsModalOpen } = useContext(UIProviderContext);

  useEffect(() => {
    if (isModalOpen) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [isModalOpen]);

  const _onModalClose = () => {
    onModalClose?.();
  };

  return (
    <Components.Modal
      open={isModalOpen}
      onClose={(event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (!(disableCloseOnClickAway && reason === 'backdropClick')) {
          _onModalClose?.();
        }
      }}
      scroll={scrollType}
      modalWidth={modalWidth}
      disableEscapeKeyDown>
      <Components.ModalHeader noBottomLine={cannotClose}>
        <Components.TitleWrapper>
          <Components.ModalHeaderTitle>{modalTitle}</Components.ModalHeaderTitle>
        </Components.TitleWrapper>
        <Components.ModalHeaderIconWrapper onClick={_onModalClose}>
          {cannotClose ? null : <CloseIcon />}
        </Components.ModalHeaderIconWrapper>
      </Components.ModalHeader>
      <Components.ModalContentContainer noBottomLine={cannotClose}>{bodyChildren}</Components.ModalContentContainer>
      <Components.ModalButtonsContainer>{buttonChildren}</Components.ModalButtonsContainer>
    </Components.Modal>
  );
};

export default ProjectModal;
