import React, { useEffect } from 'react';
import { Components } from './styled';
import { PortalCaseParty, SavedCaseParty } from '../../../api/types/portal';
import { TABLE_MAX_VISIBLE_PARTIES } from '../../ProjectTable/cells/TableParties/constants';
import { ReactComponent as UserIcon } from '../../../assets/icons/icon-user.svg';
import { ReactComponent as UserFilledIcon } from '../../../assets/icons/icon-user-filled.svg';
import { concatArrayOfStrings, truncateString } from '../../../utils/strings';
import GenericTooltip from '../../tooltips/GenericTooltip';

const PartyItem = ({
  name,
  isChoosingParty,
  isSavedParty,
  onPartyClick,
}: {
  name: string;
  isChoosingParty?: boolean;
  isSavedParty?: boolean;
  onPartyClick?: () => void;
}) => {
  return (
    <Components.PartyWrapper onClick={onPartyClick} isChoosingParty={isChoosingParty} isSavedParty={isSavedParty}>
      <Components.PartyIconWrapper>{isSavedParty ? <UserFilledIcon /> : <UserIcon />}</Components.PartyIconWrapper>
      <Components.PartyName isChoosingParty={isChoosingParty} isSavedParty={isSavedParty}>
        {name}
      </Components.PartyName>
    </Components.PartyWrapper>
  );
};

const Parties = ({
  parties,
  isChoosingParty,
  savedParty,
  onSubmitParty,
  visiblePartiesCount = TABLE_MAX_VISIBLE_PARTIES,
  wrapContent = true,
  showAllParties = false,
}: {
  parties: PortalCaseParty[];
  isChoosingParty?: boolean;
  savedParty?: SavedCaseParty;
  onSubmitParty?: (party: PortalCaseParty) => void;
  visiblePartiesCount?: number;
  wrapContent?: boolean;
  showAllParties?: boolean;
}) => {
  const maxVisibleParties = typeof visiblePartiesCount === 'number' ? visiblePartiesCount : TABLE_MAX_VISIBLE_PARTIES;

  const indexOfSavedParty = savedParty ? parties?.findIndex((item) => item?.name === savedParty?.name) : undefined;
  const visibleParties =
    parties && parties?.length > 0
      ? typeof indexOfSavedParty === 'number'
        ? parties
            .reduce((arr, v, index) => {
              if (index === indexOfSavedParty) {
                return [v].concat(arr);
              }
              arr.push(v);
              return arr;
            }, [] as PortalCaseParty[])
            .slice(0, maxVisibleParties)
        : parties?.slice(0, maxVisibleParties)
      : [];
  const remainingPartiesCount = parties?.length - visibleParties?.length;
  const remainingParties = parties?.slice(maxVisibleParties);
  const remainingPartiesText = concatArrayOfStrings(remainingParties?.map((item) => item?.name)) as string;

  const _onSubmitParty = (party: PortalCaseParty) => {
    onSubmitParty?.(party);
  };

  return (
    <Components.PartiesWrapper wrapContent={wrapContent}>
      <>
        {showAllParties && parties?.length > 0
          ? parties?.map((partyItem, key) => {
              const truncatedName = truncateString(partyItem?.name, 20);
              const shouldShowTooltip = !isChoosingParty && partyItem?.name?.length > truncatedName?.length;
              const _isSavedParty = savedParty ? savedParty?.name === partyItem?.name : false;

              return shouldShowTooltip ? (
                <GenericTooltip key={key} text={partyItem?.name}>
                  <PartyItem name={truncatedName} isSavedParty={_isSavedParty} />
                </GenericTooltip>
              ) : (
                <PartyItem
                  key={key}
                  name={truncatedName}
                  isChoosingParty={isChoosingParty}
                  isSavedParty={_isSavedParty}
                  onPartyClick={() => _onSubmitParty(partyItem)}
                />
              );
            })
          : visibleParties &&
            visibleParties?.length > 0 &&
            visibleParties?.map((partyItem, key) => {
              const truncatedName = truncateString(partyItem?.name, 20);
              const shouldShowTooltip = partyItem?.name?.length > truncatedName?.length;
              const _isSavedParty = savedParty ? savedParty?.name === partyItem?.name : false;

              return shouldShowTooltip ? (
                <GenericTooltip key={key} text={partyItem?.name}>
                  <PartyItem name={truncatedName} isSavedParty={_isSavedParty} />
                </GenericTooltip>
              ) : (
                <PartyItem key={key} name={truncatedName} isSavedParty={_isSavedParty} />
              );
            })}
        {!showAllParties && remainingPartiesCount !== 0 && (
          <GenericTooltip text={remainingPartiesText}>
            <Components.PartyWrapper>
              <Components.PartyName>+{remainingPartiesCount} părți</Components.PartyName>
            </Components.PartyWrapper>
          </GenericTooltip>
        )}
      </>
    </Components.PartiesWrapper>
  );
};

export default Parties;
