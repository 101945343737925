import React from 'react';
import UIProvider from './UIProvider';
import ActivityIndicatorProvider from './ActivityIndicatorProvider';
import SessionProvider from './SessionProvider';
import ToastProvider from './ToastProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SubscriptionProvider from './SubsctiptionProvider';
import OnboardingProvider from './OnboardingProvider';
import NotesProvider from './NotesProvider';
import CaseProvider from './CaseProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 15 * 60 * 1000,
      staleTime: 15 * 60 * 1000,
    },
  },
});

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <UIProvider>
        <ActivityIndicatorProvider>
          <SessionProvider>
            <SubscriptionProvider>
              <OnboardingProvider>
                <CaseProvider>
                  <NotesProvider>
                    <ToastProvider>{children}</ToastProvider>
                  </NotesProvider>
                </CaseProvider>
              </OnboardingProvider>
            </SubscriptionProvider>
          </SessionProvider>
        </ActivityIndicatorProvider>
      </UIProvider>
    </QueryClientProvider>
  );
};

export default Providers;
