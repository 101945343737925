import { Dispatch, SetStateAction } from 'react';
import useFormState from './useFormState';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

const useFormSelectState = (
  initialValue?: string | undefined,
  process?: (value: string) => string | undefined,
): {
  value: string | undefined;
  setValue: Dispatch<SetStateAction<string | undefined>>;
  onChangeSelectedItem: (e: SelectChangeEvent<string | undefined>) => void;
} => {
  const props = useFormState<string, SelectChangeEvent<string | undefined>>(initialValue, process);

  return { onChangeSelectedItem: props.onChange, ...props };
};

export default useFormSelectState;
