import React from 'react';
import { DropdownStyleProps } from '../components/ProjectSearchDropdown/types';

const useDropdown = (props?: DropdownStyleProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<unknown>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget as HTMLElement);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return { anchorEl, open, show: handleClick, handleClose, ...props };
};

export default useDropdown;
