export const getFullName = (data: { firstName?: string | null; lastName?: string | null }): string | undefined => {
  let result = '';
  if (data?.firstName && data.firstName.length > 0) {
    result += data.firstName;
  }
  if (data?.lastName && data.lastName.length > 0) {
    if (result.length > 0) {
      result += ' ';
    }
    result += data.lastName;
  }
  return result.length > 0 ? result : undefined;
};

export const getInitials = (data: { firstName?: string | null; lastName?: string | null }): string | undefined => {
  let result = '';
  if (data?.firstName && data.firstName.length > 0) {
    result += data.firstName[0];
  }
  if (data?.lastName && data.lastName.length > 0) {
    result += data.lastName[0];
  }
  return result.length > 0 ? result : undefined;
};
