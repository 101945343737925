import React, { useContext } from 'react';
import { Components } from './styled';
import {
  ONBOARDING_DATA,
  ONBOARDING_STEPS,
  ONBOARDING_STEPS_COUNT,
} from '../../../../providers/OnboardingProvider/constants';
import OnboardingStep from './components/OnboardingStep';
import { useNavigate } from 'react-router-dom';
import { OnboardingContext } from '../../../../providers/OnboardingProvider/context';
import { OnboardingStepType } from '../../../../providers/OnboardingProvider/types';
import { SessionContext } from '../../../../providers/SessionProvider/context';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/icon-check-alt.svg';

const OnboardingBlock = () => {
  const navigate = useNavigate();

  const { getStepStatus, didCompleteOnboarding, completedStepsCount, onCompletingOnboarding } =
    useContext(OnboardingContext);
  const { currentProfile } = useContext(SessionContext);

  const onDonePress = () => {
    onCompletingOnboarding();
  };

  const progress = (completedStepsCount / ONBOARDING_STEPS_COUNT) * 100;
  const _didCompleteOnboarding = didCompleteOnboarding();

  const titleSuffix = _didCompleteOnboarding ? 'Felicitări' : 'Bun venit';
  const progressText = _didCompleteOnboarding ? 'felicitări' : 'ține-o tot așa!';
  return (
    <Components.Container>
      <Components.Header>
        <Components.HeaderTitle>
          {titleSuffix}
          {currentProfile && `, ${currentProfile?.firstName}`}!
        </Components.HeaderTitle>
        {!_didCompleteOnboarding && (
          <Components.HeaderSubtitle>
            Completează următorii pași pentru a începe să salvezi timpul tău prețios
          </Components.HeaderSubtitle>
        )}
      </Components.Header>
      <Components.ProgressWrapper>
        <Components.ProgressLabel>
          <Components.ProgressLabelAccent>
            {completedStepsCount} / {ONBOARDING_STEPS_COUNT} completat
          </Components.ProgressLabelAccent>
          {` - ${progressText}`}
        </Components.ProgressLabel>
        <Components.ProgressBar variant={'determinate'} value={progress} />
      </Components.ProgressWrapper>
      <Components.OnboardingStepsWrapper>
        {_didCompleteOnboarding ? (
          <Components.SuccessBannerWrapper>
            <Components.SuccessHeader>
              <Components.SuccessTitle>Ai parcurs pașii!</Components.SuccessTitle>
              <Components.SuccessSubtitle>Ai parcurs primii tăi pași în aplicația Juridiq</Components.SuccessSubtitle>
            </Components.SuccessHeader>
            <Components.DoneButton onClick={onDonePress}>
              Terminat
              <CheckIcon />
            </Components.DoneButton>
          </Components.SuccessBannerWrapper>
        ) : (
          Object.entries(ONBOARDING_DATA).map(([key, value]) => {
            const status = getStepStatus(key as OnboardingStepType);
            const onButtonPress =
              'navigateTo' in value && value?.navigateTo ? () => navigate(value?.navigateTo) : undefined;

            return (
              <OnboardingStep
                key={key}
                status={status}
                title={value.title}
                subtitle={value.subtitle}
                onButtonPress={onButtonPress}
                buttonLabel={value.buttonLabel}
              />
            );
          })
        )}
      </Components.OnboardingStepsWrapper>
    </Components.Container>
  );
};

export default OnboardingBlock;
