import styled from 'styled-components';
import AppCSS from '../../../../utils/styled';

export const Components = {
  Container: styled.div`
    width: 440px;
    height: 640px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 48px;

    @media only screen and (max-width: 1200px) {
      display: none;
    }
  `,
  StepsContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  `,
  StepItem: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    gap: 16px;
    padding: 8px 0;
  `,
  StepIconWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    min-width: 32px;
    height: 32px;
    ${AppCSS.radiuses.forHeight(32)};
    background-color: ${AppCSS.colors.greens.green50};
  `,
  StepContentWrapper: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    padding-top: 6px;
  `,
  StepTitle: styled.span`
    ${AppCSS.fonts.h5};
    color: ${AppCSS.colors.greens.green70};
  `,
  StepDescription: styled.span`
    ${AppCSS.fonts.paragraph12Regular};
    color: ${AppCSS.colors.greens.green70};
  `,
};
