import React, { useContext, useEffect } from 'react';
import ProjectSimpleContainer from '../../../components/containers/ProjectSimpleContainer';
import { Components } from '../styled';
import { UIView } from '../../../utils/styled/styled';
import { ReactComponent as AuthContainerBackground } from '../../../assets/illustrations/illustration-auth-bg.svg';
import { useNavigate } from 'react-router-dom';
import useFormTextState from '../../../hooks/useFormTextState';
import { ActivityIndicatorContext } from '../../../providers/ActivityIndicatorProvider/context';
import { ToastContext } from '../../../providers/ToastProvider/context';
import { SessionContext } from '../../../providers/SessionProvider/context';
import { isProjectError } from '../../../utils/errors';
import ProjectTextField from '../../../components/inputs/ProjectTextField';
import { ProjectErrors } from '../../../utils/errors/types';

const Login = () => {
  const navigate = useNavigate();

  const { setIsLoading } = useContext(ActivityIndicatorContext);
  const { showErrorToast, showWarningToast } = useContext(ToastContext);
  const { login, doesVerifyPhoneNumber } = useContext(SessionContext);

  const emailProps = useFormTextState();
  const passwordProps = useFormTextState();

  useEffect(() => {
    if (doesVerifyPhoneNumber) {
      navigate('/verify-phone-number');
    }
  }, []);

  const onGoToRegisterPress = () => {
    navigate('/register');
  };

  const onGoToForgotPasswordPress = () => {
    navigate('/forgot-password');
  };

  const onLoginPress = async () => {
    if (emailProps.value && passwordProps.value) {
      setIsLoading(true);
      const result = await login(emailProps.value, passwordProps.value);
      setIsLoading(false);
      const resultError = isProjectError(result);
      if (resultError) {
        if (resultError.code === ProjectErrors.phoneNumberNotVerified) {
          navigate('/verify-phone-number');
        } else {
          showErrorToast(resultError);
        }
      }
    } else {
      showWarningToast('Vă rugăm să introduceți ambele credențiale și să încercați din nou.');
    }
  };

  return (
    <ProjectSimpleContainer>
      <Components.Container>
        <Components.LeftSideWrapper>
          <Components.LeftSideBackgroundImageWrapper>
            <AuthContainerBackground height='100%' width='100%' />
          </Components.LeftSideBackgroundImageWrapper>
          <Components.LogoSquare />
          <Components.LeftSideFooterWrapper>
            <Components.FooterTitle>Secretarul tău digital.</Components.FooterTitle>
            <Components.FooterSubtitle>Ai ajuns pe platforma juriștilor inteligenți</Components.FooterSubtitle>
          </Components.LeftSideFooterWrapper>
        </Components.LeftSideWrapper>
        <Components.RightSideWrapper>
          <Components.RightSideHeaderWrapper>
            <Components.HeaderTitle>Salutări din nou!</Components.HeaderTitle>
            <Components.HeaderSubtitle>
              Revino la munca ta prețioasă, pe platforma în care timpul tău este resursa cea mai importantă
            </Components.HeaderSubtitle>
          </Components.RightSideHeaderWrapper>
          <Components.RightSideContentWrapper>
            <Components.FieldWrapper>
              <Components.FieldLabel>Adresa de email</Components.FieldLabel>
              <ProjectTextField
                value={emailProps.value}
                onChangeText={emailProps.onChangeText}
                type={'email'}
                placeholder={'Introduceți adresa de email'}
              />
            </Components.FieldWrapper>
            <Components.PasswordFieldWrapper>
              <Components.FieldWrapper>
                <Components.FieldLabel>Parola</Components.FieldLabel>
                <ProjectTextField
                  value={passwordProps.value}
                  onChangeText={passwordProps.onChangeText}
                  placeholder={'Introduceți parola'}
                  secureTextEntry
                />
              </Components.FieldWrapper>
              <Components.ForgotPasswordButton onClick={onGoToForgotPasswordPress}>
                Ai uitat parola?
              </Components.ForgotPasswordButton>
            </Components.PasswordFieldWrapper>
            <Components.CallToActionWrapper>
              <Components.CallToActionButton onClick={onLoginPress}>Conectează-te</Components.CallToActionButton>
              <UIView>
                <Components.CallToActionSubtitle>Nu ai încă un cont?</Components.CallToActionSubtitle>
                <Components.CallToActionSubtitleAccent onClick={onGoToRegisterPress}>
                  Înregistrează-te
                </Components.CallToActionSubtitleAccent>
              </UIView>
            </Components.CallToActionWrapper>
          </Components.RightSideContentWrapper>
        </Components.RightSideWrapper>
      </Components.Container>
    </ProjectSimpleContainer>
  );
};

export default Login;
