import React, { useContext, useState } from 'react';
import ProjectSimpleContainer from '../../../components/containers/ProjectSimpleContainer';
import { Components } from '../styled';
import { ReactComponent as AuthContainerBackground } from '../../../assets/illustrations/illustration-auth-bg.svg';
import { UIView } from '../../../utils/styled/styled';
import { useNavigate } from 'react-router-dom';
import { notImplementedAlert } from '../../../utils/testing';
import { SessionContext } from '../../../providers/SessionProvider/context';
import useFormTextState from '../../../hooks/useFormTextState';
import { ToastContext } from '../../../providers/ToastProvider/context';
import ProjectTextField from '../../../components/inputs/ProjectTextField';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { recoverPassword } = useContext(SessionContext);
  const { showWarningToast } = useContext(ToastContext);

  const emailProps = useFormTextState();
  const [didPressSend, setDidPressSend] = useState(false);

  const onBackToLoginPress = () => {
    navigate('/login');
  };

  const onRecoverPasswordPress = () => {
    if (emailProps.value && emailProps.value?.length > 0) {
      recoverPassword(emailProps.value).then();
      setDidPressSend(true);
    } else {
      showWarningToast('Vă rugăm să introduceți o adresă validă de email și să încercați din nou.');
    }
  };

  return (
    <ProjectSimpleContainer>
      <Components.Container>
        <Components.LeftSideWrapper>
          <Components.LeftSideBackgroundImageWrapper>
            <AuthContainerBackground height='100%' width='100%' />
          </Components.LeftSideBackgroundImageWrapper>
          <Components.LogoSquare />
          <Components.LeftSideFooterWrapper>
            <Components.FooterTitle>Secretarul tău digital.</Components.FooterTitle>
            <Components.FooterSubtitle>Ai ajuns pe platforma juriștilor inteligenți</Components.FooterSubtitle>
          </Components.LeftSideFooterWrapper>
        </Components.LeftSideWrapper>
        <Components.RightSideWrapper>
          <Components.RightSideHeaderWrapper>
            <Components.HeaderTitle>Suntem aici să te ajutăm</Components.HeaderTitle>
            <Components.HeaderSubtitle>
              Nu ezita în niciun moment de răscruce să ne ceri ajutorul
            </Components.HeaderSubtitle>
          </Components.RightSideHeaderWrapper>
          <Components.RightSideContentWrapper>
            {didPressSend ? (
              <>
                <Components.FieldLabel>
                  Dacă ai introdus o adresă de email asociată unui cont, vei primi curând un mail cu instrucțiuni pentru
                  recuperarea parolei
                </Components.FieldLabel>
                <Components.CallToActionWrapper>
                  <Components.CallToActionButton onClick={onBackToLoginPress}>
                    <Components.ArrowLeftIcon />
                    Înapoi la Login
                  </Components.CallToActionButton>
                </Components.CallToActionWrapper>
              </>
            ) : (
              <>
                <Components.FieldWrapper>
                  <Components.FieldLabel>
                    În cazul în care ți-au uitat parola, introdu adresa de email asociată contului mai jos:
                  </Components.FieldLabel>
                  <ProjectTextField
                    value={emailProps.value}
                    onChangeText={emailProps.onChangeText}
                    placeholder={'Introduceți adresa de email'}
                  />
                </Components.FieldWrapper>
                <Components.CallToActionWrapper>
                  <Components.CallToActionButton onClick={onRecoverPasswordPress}>
                    Recuperează parola
                  </Components.CallToActionButton>
                  <UIView>
                    <Components.CallToActionSubtitle>Ai ajuns din greșeală aici?</Components.CallToActionSubtitle>
                    <Components.CallToActionSubtitleAccent onClick={onBackToLoginPress}>
                      Înapoi la Login
                    </Components.CallToActionSubtitleAccent>
                  </UIView>
                </Components.CallToActionWrapper>
              </>
            )}
          </Components.RightSideContentWrapper>
        </Components.RightSideWrapper>
      </Components.Container>
    </ProjectSimpleContainer>
  );
};

export default ForgotPassword;
