export const MENU_ITEM_HEIGHT = 40 as const;
export const MENU_MAX_HEIGHT = MENU_ITEM_HEIGHT * 6;

export const DEFAULT_MENU_WIDTH = 300;
export const DEFAULT_MENU_TOP_OFFSET = 10;
export const DEFAULT_MENU_LEFT_OFFSET = 8;

export const SMALL_PROJECT_MODAL_WIDTH = 400;
export const DEFAULT_PROJECT_MODAL_WIDTH = 560;
export const MEDIUM_PROJECT_MODAL_WIDTH = 620;
export const BIG_PROJECT_MODAL_WIDTH = 700;
export const VERY_BIG_PROJECT_MODAL_WIDTH = 800;
