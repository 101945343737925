import styled from 'styled-components';
import AppCSS from '../../../../utils/styled';

export const Components = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 24px 16px;
    background-color: ${AppCSS.colors.white};
    gap: 12px;
  `,
  Title: styled.span`
    ${AppCSS.fonts.label16Bold};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  HearingsWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
  `,
  HearingContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 16px;
    gap: 16px;
    ${AppCSS.radiuses.default};
    border: 1px solid ${AppCSS.colors.utility.borders};
    overflow: hidden;
  `,
  HearingContent: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
  `,
  HearingTitleWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  `,
  HearingTitle: styled.span`
    ${AppCSS.fonts.label16Bold};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  HearingTag: styled.span`
    ${AppCSS.fonts.label12Bold};
    color: ${AppCSS.colors.greens.green60};
    text-transform: uppercase;
  `,
  HearingCaseNumberWrapper: styled.div<{ isMobile: boolean }>`
    display: flex;
    ${(p) => p.isMobile && 'flex-direction: column; flex-wrap: wrap;'};
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
  `,
  HearingCaseNumber: styled.span`
    ${AppCSS.fonts.label14Medium};
    color: ${AppCSS.colors.primaryGreenGoth};
    cursor: pointer;
  `,
  Separator: styled.div`
    width: 1px;
    height: 100%;
    background-color: ${AppCSS.colors.utility.borders};
  `,
  LocationAndPanelWWrapper: styled.div<{ isMobile: boolean }>`
    display: flex;
    ${(p) => p.isMobile && 'flex-direction: column; flex-wrap: wrap;'};
    align-items: ${(p) => (p.isMobile ? 'flex-start' : 'center')};
    justify-content: center;
    gap: 12px;
  `,
  LocationWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  `,
  LocationLabel: styled.span`
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green70};
  `,
  ButtonsWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;

    @media only screen and (max-width: 375px) {
      align-items: flex-start;
      flex-direction: column;
      gap: 12px;
    }
  `,
  SeeInCalendarButton: styled(AppCSS.buttonStyles.TertiaryButtonStyle)``,
  SeeInCaseButton: styled(AppCSS.buttonStyles.TertiaryButtonStyle)``,
  AddCaseButton: styled(AppCSS.buttonStyles.SecondaryButtonWithIconStyle)``,

  EmptyStateWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    padding: 32px 0;
  `,
  EmptyStateLabel: styled.span`
    ${AppCSS.fonts.label16Regular};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
};
