import styled from 'styled-components';
import TabUnstyled from '@mui/base/TabUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import AppCSS from '../../../../utils/styled';

export const Components = {
  TabsList: styled(TabsListUnstyled)`
    min-width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    padding-right: 24px;
    border-right: 1px solid ${AppCSS.colors.greens.green40};
    margin-right: 24px;
    & .Mui-selected {
      background-color: ${AppCSS.colors.greens.green40};
      ${AppCSS.fonts.label14Bold};
      border: none;
      outline: none;
    }
  `,
  Tab: styled(TabUnstyled)`
    ${AppCSS.fonts.label14Regular};
    color: ${AppCSS.colors.greens.green80};
    padding: 12px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
  `,
};
