import React from 'react';
import { Components } from './styled';

const ProjectDropdown = ({
  open,
  anchorEl,
  handleClose,
  children,
  dropdownInfo,
  onEdit,
  onDelete,
  onArchive,
  onDisable,
  onEnable,
  onViewProfile,
  onViewDetails,
  onLogout,
}: {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  children?: React.ReactNode;
  dropdownInfo?: string;
  onEdit?: () => void;
  onDelete?: () => void;
  onArchive?: () => void;
  onDisable?: () => void;
  onEnable?: () => void;
  onViewProfile?: () => void;
  onViewDetails?: () => void;
  onLogout?: () => void;
}) => {
  const _onDisable = () => {
    onDisable?.();
    handleClose();
  };

  const _onEnable = () => {
    onEnable?.();
    handleClose();
  };

  const _onEdit = () => {
    onEdit?.();
    handleClose();
  };

  const _onDelete = async () => {
    onDelete?.();
    handleClose();
  };

  const _onArchive = async () => {
    onArchive?.();
    handleClose();
  };

  const _onViewDetails = () => {
    onViewDetails?.();
    handleClose();
  };

  const _onViewProfile = () => {
    onViewProfile?.();
    handleClose();
  };

  const _onLogout = () => {
    onLogout?.();
    handleClose();
  };

  return (
    <Components.Menu
      elevation={0}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}>
      {dropdownInfo && dropdownInfo?.length > 0 && (
        <Components.InfoMenuItem disabled>{dropdownInfo}</Components.InfoMenuItem>
      )}
      {onViewProfile && <Components.MenuItem onClick={_onViewProfile}>Contul meu</Components.MenuItem>}
      {onViewDetails && <Components.MenuItem onClick={_onViewDetails}>Vezi detalii</Components.MenuItem>}
      {onEdit && <Components.MenuItem onClick={_onEdit}>Edit</Components.MenuItem>}
      {onDisable && <Components.MenuItem onClick={_onDisable}>Disable</Components.MenuItem>}
      {onEnable && <Components.MenuItem onClick={_onEnable}>Enable</Components.MenuItem>}
      {onDelete && <Components.DestructiveMenuItem onClick={_onDelete}>Șterge</Components.DestructiveMenuItem>}
      {onArchive && <Components.AlternativeMenuItem onClick={_onArchive}>Arhivează</Components.AlternativeMenuItem>}
      {onLogout && <Components.DestructiveMenuItem onClick={_onLogout}>Logout</Components.DestructiveMenuItem>}
      {children}
    </Components.Menu>
  );
};

export default ProjectDropdown;
