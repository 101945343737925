import React from 'react';
import { Components } from './styled';
import { Case } from '../../../../../api/types/case';
import Parties from '../../../../../components/caseComponents/Parties';
import CaseParties from '../CaseParties';

const CaseGeneralInfo = ({ data }: { data: Case }) => {
  return (
    <Components.Container>
      <Components.HeaderContainer>
        <Components.HeaderTitle>Informații generale</Components.HeaderTitle>
      </Components.HeaderContainer>
      <Components.BodyContainer>
        <Components.FieldWrapper>
          <Components.FieldLabel>Număr dosar</Components.FieldLabel>
          <Components.FieldValue>{data?.displayNumber}</Components.FieldValue>
        </Components.FieldWrapper>
        <Components.FieldWrapper>
          <Components.FieldLabel>Obiect</Components.FieldLabel>
          {data?.matter ? (
            <Components.FieldValue>{data?.matter}</Components.FieldValue>
          ) : (
            <Components.NotSetLabel>Necunoscut</Components.NotSetLabel>
          )}
        </Components.FieldWrapper>
        <CaseParties parties={data?.parties} caseId={data?.id} />
        <Components.FieldWrapper>
          <Components.FieldLabel>Instanță</Components.FieldLabel>
          {data?.displayInstitution ? (
            <Components.FieldValue>{data?.displayInstitution}</Components.FieldValue>
          ) : (
            <Components.NotSetLabel>Necunoscut</Components.NotSetLabel>
          )}
        </Components.FieldWrapper>
      </Components.BodyContainer>
    </Components.Container>
  );
};

export default CaseGeneralInfo;
