import React from 'react';
import { Components } from './styled';
import { parseDateToStringWithFormat } from '../../../../utils/dateFormat';
import { format } from '../../../../utils/dateFormat/types';

const Date = ({ params, showTime }: { params: any; showTime?: boolean }) => {
  const { field, row }: { field: string; row: any } = params;
  const text =
    row?.[field] !== null && row?.[field] !== undefined
      ? parseDateToStringWithFormat(row[field], format.monthDayYear).concat(
          showTime ? ' ' + parseDateToStringWithFormat(row[field], format.hourMinutes) : '',
        )
      : '-';

  return (
    <Components.Container>
      <Components.Text>{text}</Components.Text>
    </Components.Container>
  );
};

export default Date;
