import { useState } from 'react';

export const LocalStorage = {
  getItem: (key: string) => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : undefined;
    } catch (error) {
      console.log('Error at useLocalStorage', error);
      return undefined;
    }
  },
  setItem: (key: string, value: any) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log('Error at useLocalStorage', error);
    }
  },
};

const useLocalStorage = <T>(key: string, initialValue: T) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      if (
        !(
          (error as Error)?.message.includes('Unexpected token u in JSON at position 0') ||
          (error as Error)?.message.includes('Unexpected identifier "undefined"')
        )
      ) {
        console.log('Error at useLocalStorage', error);
      }
      return initialValue;
    }
  });
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log('Error at useLocalStorage', error);
    }
  };
  return [storedValue, setValue] as const;
};

export default useLocalStorage;
