import apiFetch from '../index';
import { ENDPOINTS } from '../endpoints';
import { CalendarEvents } from '../types/calendar';
import { parseCaseHearings } from './parser';

export const getCalendarEvents = async (date: string): Promise<CalendarEvents> => {
  try {
    const rawResult = await apiFetch({ path: ENDPOINTS.calendar.getAll, method: 'GET', query: { date } });
    const processedResult = await parseCaseHearings(rawResult?.data);

    return { events: processedResult?.events, eventsMetadata: processedResult?.eventsMetadata };
  } catch (e) {
    console.log('[getCalendarEvents] Error:', e);
    return { events: [], eventsMetadata: {} };
  }
};

export const getUpcomingEvents = async (): Promise<CalendarEvents> => {
  try {
    const rawResult = await apiFetch({ path: ENDPOINTS.calendar.getUpcoming, method: 'GET' });
    const processedResult = await parseCaseHearings(rawResult?.data);

    return { events: processedResult?.events, eventsMetadata: processedResult?.eventsMetadata };
  } catch (e) {
    console.log('[getUpcomingEvents] Error:', e);
    return { events: [], eventsMetadata: {} };
  }
};
