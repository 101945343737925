import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseDateToStringWithFormat } from '../../utils/dateFormat';
import { Components } from './styled';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/icon-arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/icon-angle-right.svg';
import { SessionContext } from '../../providers/SessionProvider/context';
import ProfileAvatar from '../ProfileAvatar';
import useDropdown from '../../hooks/useDropdown';
import ProjectDropdown from '../ProjectDropdown';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { MEDIA_QUERY_BREAKPOINTS } from '../../utils/responsive/constants';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ProjectSideBar from '../ProjectSideBar';
import { ReactComponent as MenuIcon } from '../../assets/icons/icon-menu.svg';
import { createMediaQuery } from '../../utils/responsive';

const ProjectHeaderBar = ({
  hasBack,
  title,
  secondaryTitle,
}: {
  hasBack?: boolean | string;
  title?: string;
  secondaryTitle?: string;
}) => {
  const navigate = useNavigate();
  const { currentProfile, logout } = useContext(SessionContext);

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const isMobile = useMediaQuery({
    query: createMediaQuery(MEDIA_QUERY_BREAKPOINTS.largeMobile, true),
  });

  const profileMenuProps = useDropdown();
  const currentDayText = parseDateToStringWithFormat(new Date(), 'E, MMMM do');
  const canClickTitle = secondaryTitle !== undefined && typeof hasBack === 'string' && hasBack?.length > 0;

  const onOpenMenu = () => {
    setIsDrawerOpen(true);
  };
  const onMenuClose = () => {
    setIsDrawerOpen(false);
  };

  const onBack = () => {
    navigate(-1);
  };

  const onTitlePress = () => {
    if (canClickTitle) {
      navigate(hasBack);
    }
  };

  const onViewProfile = () => {
    navigate('/contul-meu');
  };

  return (
    <Components.Container isOnMobile={isMobile}>
      <Components.LeftSideWrapper>
        <MediaQuery maxWidth={MEDIA_QUERY_BREAKPOINTS.desktop}>
          <Components.MenuIconWrapper onClick={onOpenMenu}>
            <MenuIcon />
          </Components.MenuIconWrapper>
        </MediaQuery>
        <SwipeableDrawer anchor={'left'} open={isDrawerOpen} onClose={onMenuClose} onOpen={onOpenMenu}>
          <ProjectSideBar />
        </SwipeableDrawer>

        {typeof hasBack === 'boolean' && hasBack && (
          <Components.BackButton onClick={onBack}>
            <Components.LeftSideIconWrapper>
              <ArrowLeftIcon />
            </Components.LeftSideIconWrapper>
            Înapoi
          </Components.BackButton>
        )}
        {title && (
          <Components.LeftSideTitle onClick={onTitlePress} isClickable={canClickTitle}>
            {title}
          </Components.LeftSideTitle>
        )}
        {secondaryTitle && (
          <>
            <Components.LeftSideIconWrapper>{<ArrowRightIcon />}</Components.LeftSideIconWrapper>
            <Components.LeftSideTitle>{secondaryTitle}</Components.LeftSideTitle>
          </>
        )}
      </Components.LeftSideWrapper>
      <Components.RightSectionWrapper displayFlex flexDirection='row' alignItems='center' justifyContent={'center'}>
        {/*<Components.ClockWrapper>*/}
        {/*  <Components.ClockIcon />*/}
        {/*  <Components.Clock format={'HH:mm A'} ticking />*/}
        {/*</Components.ClockWrapper>*/}
        {/*<Components.Separator />*/}
        {/*<Components.DateLabel>{currentDayText}</Components.DateLabel>*/}
        <Components.ProfileWrapper onClick={(e) => profileMenuProps.show(e)}>
          <MediaQuery minWidth={MEDIA_QUERY_BREAKPOINTS.mediumMobile}>
            <Components.ProfileLabel>{currentProfile?.fullName || 'Juridiq User'}</Components.ProfileLabel>
          </MediaQuery>
          <ProfileAvatar size={32} initials={currentProfile?.initials || 'JU'} />
        </Components.ProfileWrapper>
        <ProjectDropdown {...profileMenuProps} onViewProfile={onViewProfile} onLogout={logout} />
      </Components.RightSectionWrapper>
    </Components.Container>
  );
};

export default ProjectHeaderBar;
