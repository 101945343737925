import React, { useContext, useEffect } from 'react';
import { Components } from './styled';
import { ReactComponent as CloseIcon } from '../../assets/icons/icon-close.svg';
import { LOCAL_STORAGE_KEYS, SUBSCRIPTION_TYPE, UPGRADE_FORM_LINK } from '../../defines';
import useLocalStorage from '../../hooks/useLocalStorage';
import { SessionContext } from '../../providers/SessionProvider/context';
import { useNavigate } from 'react-router-dom';
import { SubscriptionContext } from '../../providers/SubsctiptionProvider/context';

const TrialBar = () => {
  const navigate = useNavigate();

  const { currentProfile } = useContext(SessionContext);
  const { isThreeDaysBeforeTrialExpiration, isExpiredTrial } = useContext(SubscriptionContext);

  const [didCloseTrialBanner, setDidCloseTrialBanner] = useLocalStorage(LOCAL_STORAGE_KEYS.didCloseTrialBanner, false);

  useEffect(() => {
    if (
      currentProfile &&
      (currentProfile?.subscriptionType === SUBSCRIPTION_TYPE.pro || !isThreeDaysBeforeTrialExpiration())
    ) {
      setDidCloseTrialBanner(true);
    } else if (!didCloseTrialBanner && currentProfile && isThreeDaysBeforeTrialExpiration()) {
      setDidCloseTrialBanner(false);
    }
  }, [currentProfile]);

  const onOpenProfile = () => {
    navigate('/contul-meu?tab=billing');
  };

  const onCloseTrialBanner = () => {
    setDidCloseTrialBanner(true);
  };

  return isThreeDaysBeforeTrialExpiration() ? (
    <Components.Container>
      <Components.Text>
        Perioada de test pentru Juridiq se va termina în mai puțin de 3 zile. Poți accesa{' '}
        <Components.TextAccent>abonamentul Pro</Components.TextAccent> din pagina{' '}
        <Components.Link onClick={onOpenProfile}>Contul meu</Components.Link>.
      </Components.Text>
      {/*<Components.CloseIconWrapper onClick={onCloseTrialBanner}>*/}
      {/*  <CloseIcon />*/}
      {/*</Components.CloseIconWrapper>*/}
    </Components.Container>
  ) : isExpiredTrial() ? (
    <Components.Container>
      <Components.Text>
        Perioada de test pentru Juridiq a ajuns la final. Poți accesa{' '}
        <Components.TextAccent>abonamentul Pro</Components.TextAccent> din pagina{' '}
        <Components.Link onClick={onOpenProfile}>Contul meu</Components.Link>.
      </Components.Text>
      {/*<Components.CloseIconWrapper onClick={onCloseTrialBanner}>*/}
      {/*  <CloseIcon />*/}
      {/*</Components.CloseIconWrapper>*/}
    </Components.Container>
  ) : null;
};

export default TrialBar;
