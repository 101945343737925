import { ProjectErrors, ProjectErrorType } from '../utils/errors/types';
import { createQueryStringFromHash } from './utils';
import { getProjectErrorForCode, getProjectErrorFromServerResponseCodeIfPossible } from '../utils/errors';
import { BACKEND_BASE_URL, BROWSER_TIMEZONE } from '../defines';
import { createAuthHeaders } from './utils';
import { triggerEvent } from '../utils/event-listeners';

const defaultHeaders = {
  'Content-Type': 'application/json',
  'accept': 'application/json',
  'Timezone': BROWSER_TIMEZONE,
};

const apiFetch = async ({
  method = 'GET',
  path,
  query,
  body,
  headers,
}: {
  method: 'GET' | 'POST' | 'DELETE' | 'PUT';
  path: string;
  query?: { [_: string]: string };
  body?: unknown;
  headers?: { [_: string]: string };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<any | ProjectErrorType> => {
  if (body && method === 'GET') {
    console.log('[API] Failed with error: GET calls should not use body param');
    return getProjectErrorForCode(ProjectErrors.generic);
  }

  const authHeaders = await createAuthHeaders();

  let data;
  try {
    const response = await fetch(BACKEND_BASE_URL + path + createQueryStringFromHash(query), {
      method: method,
      body: (body as any) && JSON.stringify(body),
      headers: { ...defaultHeaders, ...authHeaders, ...(headers || {}) },
    });
    data = await response.json();
  } catch (err) {
    console.log('[API] Failed with raw error:', err);
    return getProjectErrorForCode(ProjectErrors.serverResponseInvalid);
  }
  const error = getProjectErrorFromServerResponseCodeIfPossible(data);
  if (error) {
    if (error.code === '401') {
      triggerEvent('forceLogout');
    } else if (error.code === '403') {
      return getProjectErrorForCode(ProjectErrors.forbidden, error.details);
    }
    console.log('[API] Failed with error:', error.getUserMessage());
    return error;
  }
  return data;
};

export default apiFetch;
