import { OnboardingStepType } from './types';

export const ONBOARDING_DATA = {
  register: {
    title: 'Înregistrează-te',
    subtitle: 'Felicitări, acum ești utilizator Juridiq!',
    buttonLabel: undefined,
  },
  addFirstCase: {
    title: 'Adaugă primul tău dosar',
    subtitle: 'Ca să te putem ajuta, trebuie să-ți adaugi măcar un dosar.',
    buttonLabel: 'Adaugă dosar',
    navigateTo: '/portal-just',
  },
  addFirstNote: {
    title: 'Adaugă prima notiță la dosar',
    subtitle: 'Trece-ți orice poți uita despre dosar!',
    buttonLabel: 'Vezi dosare',
    navigateTo: '/dosare',
  },
};

export const ONBOARDING_STEPS = Object.keys(ONBOARDING_DATA) as OnboardingStepType[];
export const ONBOARDING_STEPS_COUNT = Object.keys(ONBOARDING_DATA)?.length;
export const ONBOARDING_DEFAULT_COMPLETED_STEP: OnboardingStepType = 'register';
