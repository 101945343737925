export const RADIUS_SIZES = {
  verySmall: 2,
  small: 4,
  default: 10,
  regular: 12,
  big: 16,
  veryBig: 24,
  extra: 56,
  forHeight: (value: number) => value / 2,
};
