import React from 'react';
import { BIG_PLACEHOLDER_HEIGHT, DEFAULT_PLACEHOLDER_HEIGHT, DEFAULT_PLACEHOLDER_TEXT } from './constants';
import { Components } from './styled';

const Placeholder = ({
  text = DEFAULT_PLACEHOLDER_TEXT,
  type = 'default',
}: {
  text?: string;
  type?: 'auto' | 'default' | 'big' | 'fullHeight';
}) => {
  const height =
    type === 'auto'
      ? 'auto'
      : type === 'fullHeight'
      ? '100%'
      : type === 'default'
      ? DEFAULT_PLACEHOLDER_HEIGHT
      : BIG_PLACEHOLDER_HEIGHT;

  return (
    <Components.Container height={height}>
      <Components.Label>{text}</Components.Label>
    </Components.Container>
  );
};

export default Placeholder;
