import React, { useContext, useEffect } from 'react';
import { Components } from './styled';
import { SessionContext } from '../../providers/SessionProvider/context';
import useLocalStorage from '../../hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS, SIGN_UP_CAMPAIGNS, SUBSCRIPTION_TYPE } from '../../defines';
import { ReactComponent as CloseIcon } from '../../assets/icons/icon-close.svg';

const CampaignBar = () => {
  const { currentProfile } = useContext(SessionContext);

  const [didCloseCampaignBanner, setDidCloseCampaignBanner] = useLocalStorage(
    LOCAL_STORAGE_KEYS.didCloseCampaignBanner,
    false,
  );

  useEffect(() => {
    if (currentProfile && currentProfile?.signUpCampaignId !== SIGN_UP_CAMPAIGNS.BaroulConstanta) {
      setDidCloseCampaignBanner(true);
    } else if (!didCloseCampaignBanner && currentProfile) {
      setDidCloseCampaignBanner(false);
    }
  }, [currentProfile]);

  const onCloseCampaignBanner = () => {
    setDidCloseCampaignBanner(true);
  };

  return !didCloseCampaignBanner ? (
    <Components.Container>
      <Components.Text>
        Te-ai înregistrat pentru parteneriatul <Components.TextAccent>Juridiq X Baroul Constanța</Components.TextAccent>
        . Contul tău este verificat înainte de a primi cele{' '}
        <Components.TextAccent>6 luni gratuite de abonament</Components.TextAccent>
      </Components.Text>
      <Components.CloseIconWrapper onClick={onCloseCampaignBanner}>
        <CloseIcon />
      </Components.CloseIconWrapper>
    </Components.Container>
  ) : null;
};

export default CampaignBar;
