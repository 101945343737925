import styled from 'styled-components';
import AppCSS from '../../../utils/styled';
import { UIView } from '../../../utils/styled/styled';

export const Components = {
  Container: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  `,

  HeaderWrapper: styled(UIView)``,
  CloseIconWrapper: styled(UIView)`
    cursor: pointer;
  `,

  Header: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    text-align: center;
  `,

  HeaderTitle: styled.span`
    ${AppCSS.fonts.label16Bold};
    color: ${AppCSS.colors.greens.green90};
  `,
  HeaderSubtitle: styled.span`
    ${AppCSS.fonts.paragraph12Regular};
    color: ${AppCSS.colors.greens.green80};
  `,

  ButtonsContainer: styled.div`
    width: 100%;
    align-items: center;
    justify-content: flex-end;
  `,
  ContinueButton: styled(AppCSS.buttonStyles.TertiaryButtonStyle)`
    margin-left: auto;
  `,
};
