import React, { useContext, useEffect, useState } from 'react';
import { Components } from './styled';
import { PortalCaseParty, SavedCaseParty } from '../../../../../api/types/portal';
import Parties from '../../../../../components/caseComponents/Parties';
import { SessionContext } from '../../../../../providers/SessionProvider/context';
import { ToastContext } from '../../../../../providers/ToastProvider/context';
import { CaseContext } from '../../../../../providers/CaseProvider/context';

const CaseParties = ({ parties, caseId }: { parties: PortalCaseParty[]; caseId: string }) => {
  const { currentProfile } = useContext(SessionContext);
  const { showWarningToast } = useContext(ToastContext);
  const { saveCaseParty } = useContext(CaseContext);

  const [isChoosingParty, setIsChoosingParty] = useState(false);
  const [savedParty, setSavedParty] = useState<SavedCaseParty | undefined>();

  const getSavedParty = () => {
    const foundParty = currentProfile
      ? currentProfile?.savedParties?.find((item) => item?.caseId === caseId)
      : undefined;
    if (foundParty) {
      setSavedParty(foundParty);
    } else {
      setSavedParty(undefined);
    }
  };

  useEffect(() => {
    if (!savedParty) {
      getSavedParty();
    }
  }, [currentProfile]);

  const toggleIsChoosingParty = () => {
    setIsChoosingParty(!isChoosingParty);
  };

  const onChooseParty = () => {
    toggleIsChoosingParty();
  };

  const onCancelChoosingParty = () => {
    toggleIsChoosingParty();
  };

  const onSubmitParty = (party: PortalCaseParty) => {
    toggleIsChoosingParty();
    if (currentProfile) {
      const savedParty: SavedCaseParty = { ...party, caseId };
      setSavedParty(savedParty);
      saveCaseParty(savedParty).then();
    } else {
      showWarningToast('A apărut o problemă la alegerea părții din dosar. Vă rugăm încercați din nou.');
    }
  };

  return (
    <Components.Container>
      <Components.HeaderWrapper>
        <Components.Title>Părți</Components.Title>
        {isChoosingParty ? (
          <Components.CancelButton onClick={onCancelChoosingParty}>Anulează</Components.CancelButton>
        ) : (
          <Components.PickPartyButton onClick={onChooseParty}>Alege partea</Components.PickPartyButton>
        )}
      </Components.HeaderWrapper>
      {parties && parties?.length > 0 ? (
        <Parties
          parties={parties}
          showAllParties={isChoosingParty}
          isChoosingParty={isChoosingParty}
          savedParty={savedParty}
          onSubmitParty={onSubmitParty}
        />
      ) : (
        <Components.NotSetLabel>Necunoscut</Components.NotSetLabel>
      )}
    </Components.Container>
  );
};

export default CaseParties;
