import React, { useContext, useEffect, useState } from 'react';
import { Components } from './styled';
import { MY_ACCOUNT_TABS } from '../../constants';
import { CommonComponents } from '../styled';
import { AccountTabPanel } from '../index';
import { SessionContext } from '../../../../providers/SessionProvider/context';
import { ToastContext } from '../../../../providers/ToastProvider/context';
import { ActivityIndicatorContext } from '../../../../providers/ActivityIndicatorProvider/context';
import { isProjectError } from '../../../../utils/errors';
import { updateGenericNotifications } from '../../../../api/profile';
import { ReactComponent as SaveIcon } from '../../../../assets/icons/icon-save.svg';

const SecurityTab = ({ tab }: { tab: number }) => {
  const { refreshProfile, currentProfile } = useContext(SessionContext);
  const { showWarningToast, showErrorToast, showSuccessToast } = useContext(ToastContext);
  const { setIsLoading } = useContext(ActivityIndicatorContext);

  const [enabledGenericNotifications, setEnabledGenericNotifications] = useState(false);

  useEffect(() => {
    setEnabledGenericNotifications(currentProfile?.enabledGenericNotifications || false);
  }, [currentProfile?.enabledGenericNotifications]);

  const resetEnabledNotifications = () => {
    if (currentProfile) {
      setEnabledGenericNotifications(currentProfile?.enabledGenericNotifications || false);
    }
  };

  const toggleEnabledNotifications = () => {
    setEnabledGenericNotifications(!enabledGenericNotifications);
  };

  const onSave = async () => {
    if (currentProfile?.enabledGenericNotifications === enabledGenericNotifications) {
      return;
    }

    setIsLoading(true);
    const result = await updateGenericNotifications(enabledGenericNotifications);
    setIsLoading(false);
    const resultError = isProjectError(result);
    if (resultError) {
      showErrorToast(resultError);
    } else {
      if (enabledGenericNotifications) {
        showSuccessToast('Ați pornit cu succes notificările generice.');
      } else {
        showSuccessToast('Ați oprit cu succes notificările generice.');
      }

      refreshProfile().then();
    }
  };

  return (
    <AccountTabPanel tab={tab} index={MY_ACCOUNT_TABS.notifications.index}>
      <CommonComponents.Header>
        <CommonComponents.Title>{MY_ACCOUNT_TABS.notifications.label}</CommonComponents.Title>
        <Components.ButtonsWrapper>
          <Components.TertiaryButton onClick={resetEnabledNotifications}>Anulează</Components.TertiaryButton>
          <Components.SecondaryButton onClick={onSave}>
            <SaveIcon />
            Salvează
          </Components.SecondaryButton>
        </Components.ButtonsWrapper>
      </CommonComponents.Header>
      <Components.Container>
        <Components.FieldWrapper>
          <Components.Checkbox checked={true} disabled />
          <Components.FieldLabelAndDescription>
            <Components.FieldLabel>Schimbări de termene - termene noi + soluții</Components.FieldLabel>
            <Components.FieldDescription>Pornite întotdeauna</Components.FieldDescription>
          </Components.FieldLabelAndDescription>
        </Components.FieldWrapper>
        <Components.FieldWrapper>
          <Components.Checkbox checked={enabledGenericNotifications} onClick={toggleEnabledNotifications} />
          <Components.FieldLabelAndDescription>
            <Components.FieldLabel>
              Schimbări generice - documente din dosar, termene invalide, alte modificări
            </Components.FieldLabel>
            <Components.FieldDescription>
              Recomandăm să lăsați pornite aceste notificări. Fără ele, e posibil să omiteți modificări din dosar
            </Components.FieldDescription>
          </Components.FieldLabelAndDescription>
        </Components.FieldWrapper>
      </Components.Container>
    </AccountTabPanel>
  );
};

export default SecurityTab;
