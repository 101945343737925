import { getProjectErrorForCode, getProjectErrorFromRawError, isProjectError } from '../../utils/errors';
import { ProjectErrors, ProjectErrorType } from '../../utils/errors/types';
import { Profile } from '../types/profile';
import apiFetch from '../index';
import { ENDPOINTS } from '../endpoints';
import { CreateProfileDto } from '../typeDtos/profile';
import { parseProfile } from './parser';
import { SavedCaseParty } from '../types/portal';

export const getProfileByUserId = async (
  id: string,
  authHeaders?: { [_: string]: string },
): Promise<ProjectErrorType | Profile> => {
  try {
    const result = (await apiFetch({ path: ENDPOINTS.profile.getById(id), method: 'GET', headers: authHeaders }))?.data;
    return await parseProfile(result);
  } catch (error) {
    console.log('[getProfileByUserId] Error:', error);
    return getProjectErrorFromRawError(error as Error);
  }
};

export const createProfile = async (
  dto: CreateProfileDto,
  authHeaders: { [_: string]: string },
): Promise<Profile | ProjectErrorType> => {
  try {
    const result = (await apiFetch({ path: ENDPOINTS.profile.create, method: 'POST', body: dto, headers: authHeaders }))
      ?.data;
    return await parseProfile(result);
  } catch (error) {
    console.log('[createProfile] Error:', error);
    return getProjectErrorFromRawError(error as Error);
  }
};

export const getOrCreateProfile = async (
  authHeaders: { [_: string]: string },
  userId: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  phoneNumber?: string,
  phoneNumberCode?: string,
  signUpCampaign?: string,
  signUpCampaignId?: string,
): Promise<Profile | ProjectErrorType> => {
  try {
    const result = await createProfile(
      { id: userId, firstName, lastName, email, phoneNumber, phoneNumberCode, signUpCampaign, signUpCampaignId },
      authHeaders,
    );
    const resultError = isProjectError(result);
    if (resultError) {
      return resultError;
    } else if (result && result && 'id' in result) {
      return result;
    }

    return getProjectErrorForCode(ProjectErrors.genericTryAgain);
  } catch (error) {
    return getProjectErrorFromRawError(error as Error);
  }
};

export const archiveCase = async (caseId: string, userId: string): Promise<void> => {
  try {
    await apiFetch({
      path: ENDPOINTS.profile.archiveCase,
      method: 'POST',
      body: { caseId, userUid: userId },
    });
  } catch (error) {
    console.log('Error at archiveCase', error);
  }
};

export const saveCaseParty = async (
  profileId: string,
  savedParty: SavedCaseParty,
): Promise<ProjectErrorType | Profile> => {
  try {
    const result = await apiFetch({
      path: ENDPOINTS.profile.saveCaseParty,
      method: 'POST',
      body: { profileId, savedParty },
    });
    return await parseProfile(result?.data);
  } catch (e) {
    console.log('[getAllCases] Error:', e);
    return getProjectErrorFromRawError(e);
  }
};

export const setDidFinishOnboarding = async (profileId: string): Promise<void> => {
  try {
    await apiFetch({
      path: ENDPOINTS.profile.finishOnboarding(profileId),
      method: 'GET',
    });
  } catch (e) {
    console.log('[setDidFinishOnboarding] Error:', e);
  }
};
export const updateGenericNotifications = async (enabled: boolean): Promise<void> => {
  try {
    await apiFetch({
      path: ENDPOINTS.profile.updateGenericNotifications,
      method: 'POST',
      body: { enabled },
    });
  } catch (e) {
    console.log('[updateGenericNotifications] Error:', e);
  }
};

export const deleteCasesForUser = async (userId: string): Promise<void> => {
  try {
    await apiFetch({
      path: ENDPOINTS.profile.deleteCases,
      method: 'DELETE',
      body: { userId },
    });
  } catch (e) {
    console.log('[deleteCasesForUser] Error:', e);
  }
};
