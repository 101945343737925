import React, { useState } from 'react';
import { Components } from './styled';
import { CaseSyncLog } from '../../../../../api/types/case';
import { parseDateToStringWithFormat } from '../../../../../utils/dateFormat';
import { format } from '../../../../../utils/dateFormat/types';
import { ReactComponent as ArrowDown } from '../../../../../assets/icons/icon-angle-down-alt.svg';
import { ReactComponent as ArrowUp } from '../../../../../assets/icons/icon-angle-up-alt.svg';
import { JustCaseHearings } from '../../../../../components/caseComponents/CaseHearings';

const ActivityLogItem = ({ data }: { data: CaseSyncLog }) => {
  const [showOldHearing, setShowOldHearing] = useState(false);
  const [showNewHearings, setShowNewHearings] = useState(false);

  const toggleOldHearing = () => {
    setShowOldHearing(!showOldHearing);
  };
  const toggleNewHearings = () => {
    setShowNewHearings(!showNewHearings);
  };

  const date = data?.createdAt
    ? parseDateToStringWithFormat(data?.createdAt, format.weekDayMonthDayYearTime)
    : 'Unknown';
  const status = data?.notificationData ? 'syncedAndNotificationSent' : 'triedSyncing';
  const text =
    status === 'syncedAndNotificationSent' ? 'S-a sincronizat dosarul' : 'S-a incercat sincronizarea dosarului';

  return (
    <Components.Container>
      <Components.Date>{date}</Components.Date>
      <Components.ContentContainer>
        <Components.LogText>{text}</Components.LogText>
        <Components.HearingsButton onClick={toggleOldHearing}>
          Termen vechi
          {showOldHearing ? <ArrowUp /> : <ArrowDown />}
        </Components.HearingsButton>
        {showOldHearing ? <JustCaseHearings hearings={[data?.oldHearing]} /> : null}
        <Components.HearingsButton onClick={toggleNewHearings}>
          Termene noi
          {showNewHearings ? <ArrowUp /> : <ArrowDown />}
        </Components.HearingsButton>
        {showNewHearings ? <JustCaseHearings hearings={data?.newHearings || []} /> : null}
      </Components.ContentContainer>
    </Components.Container>
  );
};

export default ActivityLogItem;
