import React, { useState, useMemo, useContext } from 'react';
import { ProjectErrors, ProjectErrorType } from '../../utils/errors/types';
import { ToastContext } from './context';
import Snackbar from '@mui/material/Snackbar';
import { Components } from './styled';
import { ToastType } from './types';

const ToastNotification = ({ error }: { error?: ToastType }) => {
  const { dismissToast } = useContext(ToastContext);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dismissToast();
  };

  let autoHideDuration = 2500;
  switch (error?.notificationSeverity) {
    case 'error':
      autoHideDuration = 3500;
      break;
    case 'info':
      autoHideDuration = 3500;
      break;
    case 'warning':
    case 'success':
      autoHideDuration = 3500;
      break;
  }

  const toastMessage = error
    ? error?.info && typeof error.info === 'string'
      ? error.info
      : (error?.info as ProjectErrorType)?.getUserMessage()
    : ProjectErrors.genericTryAgain;

  return (
    <Snackbar transitionDuration={0} open={!!error?.info} autoHideDuration={autoHideDuration} onClose={handleClose}>
      <Components.Alert
        elevation={6}
        onClose={handleClose}
        severity={error?.notificationSeverity || 'info'}
        sx={{ width: '100%' }}
        variant='filled'>
        <Components.Text>{toastMessage}</Components.Text>
      </Components.Alert>
    </Snackbar>
  );
};

export default function ToastProvider({ children }: { children: React.ReactNode }) {
  const [error, setError] = useState<ToastType | undefined>();

  const dismissToast = () => {
    setError(undefined);
  };

  const showToast = (message: string) => {
    setError({ info: message, notificationSeverity: 'info' });
  };
  const showSuccessToast = (message: string) => {
    setError({ info: message, notificationSeverity: 'success' });
  };
  const showErrorToast = (error: ProjectErrorType) => {
    setError({ info: error, notificationSeverity: 'error' });
  };
  const showWarningToast = (error: ProjectErrorType | string) => {
    setError({ info: error, notificationSeverity: 'warning' });
  };

  const value = useMemo(() => {
    return {
      showToast,
      showSuccessToast,
      showErrorToast,
      showWarningToast,
      dismissToast,
    };
  }, []);

  return (
    <ToastContext.Provider value={value}>
      <ToastNotification error={error} />
      {children}
    </ToastContext.Provider>
  );
}
