import React, { useContext, useMemo, useState } from 'react';
import ActivityIndicatorScreenView from '../../components/indicators/ActivityIndicatorScreenView';
import { ActivityIndicatorContext } from './context';
import { UIProviderContext } from '../UIProvider/context';
import { SIDEBAR_WIDTH } from '../../utils/styled/dimensions';
import { SessionContext } from '../SessionProvider/context';

const ActivityIndicatorProvider = ({ children }: { children: React.ReactNode }) => {
  const { isModalOpen } = useContext(UIProviderContext);
  const { isLoggedIn } = useContext(SessionContext);

  const [isLoading, setIsLoading] = useState(false);

  const value = useMemo(
    () => ({
      isLoading,
      setIsLoading,
    }),
    [isLoading],
  );

  // todo - repair this cause isLoggedIn cannot be accessed from this provider
  const leftPadding = isLoggedIn && !isModalOpen ? SIDEBAR_WIDTH : 0;
  return (
    <ActivityIndicatorContext.Provider value={value}>
      {children}
      {isLoading && <ActivityIndicatorScreenView leftPadding={leftPadding} />}
    </ActivityIndicatorContext.Provider>
  );
};

export default ActivityIndicatorProvider;
