import { isProductionEnvironment } from '../../defines';
import { ProjectErrors, ProjectErrorType } from './types';

const createProjectErrorObject = (
  code: ProjectErrors | 'custom' | string,
  message: string,
  details?: string,
): ProjectErrorType => ({
  code,
  message,
  details,
  getUserMessage: () => {
    if (!isProductionEnvironment) {
      return (
        `${message}${details && details?.length > 0 ? ` [Details: ${details}]` : ''}` +
        (message !== code ? ` [Code: ${code}]` : '')
      );
    }
    return message;
  },
});

export const getProjectErrorForCode = (code: ProjectErrors, details?: string): ProjectErrorType => {
  const message = code.valueOf();
  return createProjectErrorObject(code, message, details);
};

export const getProjectErrorFromServerResponseCodeIfPossible = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response?: any,
  rawDetails?: string,
): ProjectErrorType | undefined => {
  const errorCode = response?.error?.code;
  const details =
    rawDetails && rawDetails?.length > 0
      ? `Server: ${response?.error?.details || ''}, Local: ${rawDetails}`
      : response?.error?.details;
  if (errorCode?.length > 0) {
    if (errorCode === 'forbidden') {
      const message = response?.error?.details || ProjectErrors?.[errorCode as keyof typeof ProjectErrors];
      return createProjectErrorObject(errorCode, message || ProjectErrors.generic);
    }
    const message = ProjectErrors?.[errorCode as keyof typeof ProjectErrors];
    return createProjectErrorObject(errorCode, message || ProjectErrors.generic, details);
  }
  return undefined;
};

export const getProjectErrorFromRawError = (error?: any): ProjectErrorType => {
  const code = 'custom';
  const message = ProjectErrors.generic.valueOf();
  const details = error && 'message' in error ? error.message : 'unhandled';
  return createProjectErrorObject(code, message, details);
};

export const getProjectErrorFromCustomCode = (code: string, details?: string): ProjectErrorType => {
  const message = ProjectErrors.generic.valueOf() + ` [${code}]`;
  return createProjectErrorObject(code, message, details);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isProjectError = (object: any): ProjectErrorType | undefined => {
  return object !== undefined && object !== null && typeof object === 'object' && 'getUserMessage' in object
    ? (object as ProjectErrorType)
    : undefined;
};
