import React, { useContext, useEffect, useState } from 'react';
import ProjectSimpleContainer from '../../../components/containers/ProjectSimpleContainer';
import { Components } from '../styled';
import { ReactComponent as AuthContainerBackground } from '../../../assets/illustrations/illustration-auth-bg.svg';
import { UIView } from '../../../utils/styled/styled';
import { useNavigate } from 'react-router-dom';
import useFormTextState from '../../../hooks/useFormTextState';
import { ToastContext } from '../../../providers/ToastProvider/context';
import { isValidEmail, isValidPassword, isValidPhoneNumber } from '../../../utils/validation';
import { SessionContext } from '../../../providers/SessionProvider/context';
import { ActivityIndicatorContext } from '../../../providers/ActivityIndicatorProvider/context';
import { isProjectError } from '../../../utils/errors';
import ProjectTextField from '../../../components/inputs/ProjectTextField';
import ProjectPhoneNumberField from '../../../components/inputs/ProjectPhoneNumberField';
import { ProjectErrors } from '../../../utils/errors/types';
import { DEFAULT_PHONE_NUMBER_CODE, EXTERNAL_LINKS } from '../../../defines';
import { useQuery } from '../../../router';

const Register = () => {
  const navigate = useNavigate();

  const { showWarningToast, showErrorToast, showSuccessToast } = useContext(ToastContext);
  const { setIsLoading } = useContext(ActivityIndicatorContext);
  const { signUp } = useContext(SessionContext);

  const firstNameProps = useFormTextState();
  const lastNameProps = useFormTextState();
  const emailProps = useFormTextState();
  const passwordProps = useFormTextState();
  const phoneNumberProps = useFormTextState();
  const [didAcceptTC, setDidAcceptTC] = useState(false);

  const onGoToLoginPress = () => {
    navigate('/login');
  };

  const toggleDidAcceptTC = () => {
    setDidAcceptTC(!didAcceptTC);
  };

  const clearStates = () => {
    firstNameProps.setValue(undefined);
    lastNameProps.setValue(undefined);
    emailProps.setValue(undefined);
    passwordProps.setValue(undefined);
    setDidAcceptTC(false);
  };

  const onRegisterPress = async () => {
    if (!(lastNameProps.value && lastNameProps.value?.length > 0)) {
      showWarningToast('Numele de familie introdus este prea scurt.');
      return;
    }
    if (!(firstNameProps.value && firstNameProps.value?.length > 0)) {
      showWarningToast('Prenumele introdus este prea scurt.');
      return;
    }
    if (!isValidEmail(emailProps.value)) {
      showWarningToast('Adresa de email introdusă este invalidă.');
      return;
    }
    if (!isValidPassword(passwordProps.value)) {
      showWarningToast('Parola trebuie să aibă minimum 8 caractere, cel puțin o literă mare și cel puțin un număr.');
      return;
    }
    if (!isValidPhoneNumber(phoneNumberProps.value, DEFAULT_PHONE_NUMBER_CODE)) {
      showWarningToast('Numărul de telefon introdus nu este corect.');
      return;
    }
    if (!didAcceptTC) {
      showWarningToast('Acceptarea Termenilor și condițiilor și a Politicii de confidențialitate este obligatorie.');
      return;
    }

    setIsLoading(true);
    const result = await signUp(
      emailProps.value as string,
      passwordProps.value as string,
      firstNameProps.value,
      lastNameProps.value,
      phoneNumberProps.value as string,
      DEFAULT_PHONE_NUMBER_CODE,
    );
    setIsLoading(false);
    const resultError = isProjectError(result);
    if (resultError) {
      if (resultError.code === ProjectErrors.phoneNumberNotVerified) {
        navigate('/verify-phone-number');
      } else {
        showErrorToast(resultError);
        clearStates();
      }
    } else {
      showSuccessToast('V-ați înregistrat cu succes.');
    }
  };

  const onTermsAndConditionsPress = () => {
    window.open(EXTERNAL_LINKS.termsAndConditions, '_blank');
  };
  const onPrivacyPolicyPress = () => {
    window.open(EXTERNAL_LINKS.privacyPolicy, '_blank');
  };

  return (
    <ProjectSimpleContainer>
      <Components.Container>
        <Components.LeftSideWrapper>
          <Components.LeftSideBackgroundImageWrapper>
            <AuthContainerBackground height='100%' width='100%' />
          </Components.LeftSideBackgroundImageWrapper>
          <Components.LogoSquare />
          <Components.LeftSideFooterWrapper>
            <Components.FooterTitle>Secretarul tău digital.</Components.FooterTitle>
            <Components.FooterSubtitle>Ai ajuns pe platforma juriștilor inteligenți</Components.FooterSubtitle>
          </Components.LeftSideFooterWrapper>
        </Components.LeftSideWrapper>
        <Components.RightSideWrapper>
          <Components.RightSideHeaderWrapper>
            <Components.HeaderTitle>Bine ai venit!</Components.HeaderTitle>
            <Components.HeaderSubtitle>
              Urmează să te înregistrezi pe platforma care își propune să îți maximizeze perfomanța profesională ca și
              jurist
            </Components.HeaderSubtitle>
          </Components.RightSideHeaderWrapper>
          <Components.RightSideContentWrapper>
            <Components.MultipleFieldsWrapper>
              <Components.FieldWrapper>
                <Components.FieldLabel>Nume de familie</Components.FieldLabel>
                <ProjectTextField
                  value={lastNameProps.value}
                  onChangeText={lastNameProps.onChangeText}
                  placeholder={'Introduceți numele de familie'}
                />
              </Components.FieldWrapper>
              <Components.FieldWrapper>
                <Components.FieldLabel>Prenume</Components.FieldLabel>
                <ProjectTextField
                  value={firstNameProps.value}
                  onChangeText={firstNameProps.onChangeText}
                  placeholder={'Introduceți prenumele'}
                />
              </Components.FieldWrapper>
            </Components.MultipleFieldsWrapper>
            <Components.FieldWrapper>
              <Components.FieldLabel>Adresă de email</Components.FieldLabel>
              <ProjectTextField
                value={emailProps.value}
                onChangeText={emailProps.onChangeText}
                type={'email'}
                placeholder={'Introduceți adresa de email'}
              />
            </Components.FieldWrapper>
            <Components.FieldWrapper>
              <Components.FieldLabel>Parolă</Components.FieldLabel>
              <ProjectTextField
                value={passwordProps.value}
                onChangeText={passwordProps.onChangeText}
                placeholder={'Introduceți parola'}
                secureTextEntry
              />
            </Components.FieldWrapper>
            <Components.FieldWrapper>
              <Components.FieldLabel>Număr de telefon</Components.FieldLabel>
              <ProjectPhoneNumberField value={phoneNumberProps.value} onChange={phoneNumberProps.onChangeText} />
            </Components.FieldWrapper>
            <Components.CheckboxWrapper>
              <Components.Checkbox checked={didAcceptTC} onClick={toggleDidAcceptTC} />
              <UIView>
                <Components.CallToActionSubtitle>Sunt de acord cu </Components.CallToActionSubtitle>
                <Components.CallToActionSubtitleAccent onClick={onTermsAndConditionsPress}>
                  Termenii și condițiile
                </Components.CallToActionSubtitleAccent>
                <Components.CallToActionSubtitle> & </Components.CallToActionSubtitle>
                <Components.CallToActionSubtitleAccent onClick={onPrivacyPolicyPress}>
                  Politica de Confidențialitate
                </Components.CallToActionSubtitleAccent>
              </UIView>
            </Components.CheckboxWrapper>
            <Components.CallToActionWrapper>
              <Components.CallToActionButton onClick={onRegisterPress}>Înregistrează-te</Components.CallToActionButton>
              <UIView>
                <Components.CallToActionSubtitle>Ai deja un cont?</Components.CallToActionSubtitle>
                <Components.CallToActionSubtitleAccent onClick={onGoToLoginPress}>
                  Conectează-te
                </Components.CallToActionSubtitleAccent>
              </UIView>
            </Components.CallToActionWrapper>
          </Components.RightSideContentWrapper>
        </Components.RightSideWrapper>
      </Components.Container>
    </ProjectSimpleContainer>
  );
};

export default Register;
