import React, { useContext } from 'react';
import { Components } from './styled';
import ProjectContainer from '../../components/containers/ProjectContainer';
import { ReactComponent as CheckIcon } from '../../assets/icons/icon-check-alt.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/icon-arrow-right-white.svg';
import { PRODUCT_PRICE_ID } from '../../defines';
import { getProjectErrorForCode, isProjectError } from '../../utils/errors';
import { ProjectErrors } from '../../utils/errors/types';
import { SubscriptionContext } from '../../providers/SubsctiptionProvider/context';
import { ToastContext } from '../../providers/ToastProvider/context';
import { ActivityIndicatorContext } from '../../providers/ActivityIndicatorProvider/context';
import { SessionContext } from '../../providers/SessionProvider/context';

const Subscriptions = () => {
  const { createCheckoutSession } = useContext(SubscriptionContext);
  const { showErrorToast, showWarningToast } = useContext(ToastContext);
  const { setIsLoading } = useContext(ActivityIndicatorContext);
  const { currentProfile } = useContext(SessionContext);

  const onBuySubPress = async () => {
    if (currentProfile && currentProfile?.subscriptionType !== 'pro') {
      setIsLoading(true);
      const redirectUrl = await createCheckoutSession(PRODUCT_PRICE_ID.subMonthly);
      setIsLoading(false);
      const redirectUrlError = isProjectError(redirectUrl);
      if (redirectUrlError) {
        showErrorToast(redirectUrlError);
      } else if (redirectUrl && (redirectUrl as string)?.length > 0) {
        window.location.href = redirectUrl as string;
      } else {
        showErrorToast(getProjectErrorForCode(ProjectErrors.genericTryAgain));
      }
    } else {
      showWarningToast('A aparut o eroare: Ai deja acces la abonamentul Pro');
    }
  };

  return (
    <ProjectContainer hasBack>
      <Components.Container>
        <Components.CardContainer>
          <Components.CardHeader>
            <Components.CardTitle>Pro</Components.CardTitle>
            <Components.CardSubtitle>Orice beneficiu în mâna ta</Components.CardSubtitle>
          </Components.CardHeader>
          <Components.PrimaryButton onClick={onBuySubPress}>
            Cumpără
            <ArrowRightIcon />
          </Components.PrimaryButton>
          <Components.BenefitsWrapper>
            <Components.BenefitsTitle>Beneficii incluse:</Components.BenefitsTitle>
            <Components.BenefitItem>
              <CheckIcon />
              <Components.BenefitLabel>
                Evidența dosarelor - <Components.BenefitLabelAccent>nelimitat</Components.BenefitLabelAccent>
              </Components.BenefitLabel>
            </Components.BenefitItem>
            <Components.BenefitItem>
              <CheckIcon />
              <Components.BenefitLabel>Sincronizare cu portal.just.ro</Components.BenefitLabel>
            </Components.BenefitItem>
            <Components.BenefitItem>
              <CheckIcon />
              <Components.BenefitLabel>Notificări la modificările din dosare</Components.BenefitLabel>
            </Components.BenefitItem>
            <Components.BenefitItem>
              <CheckIcon />
              <Components.BenefitLabel>Acces de oriunde</Components.BenefitLabel>
            </Components.BenefitItem>
          </Components.BenefitsWrapper>
        </Components.CardContainer>
      </Components.Container>
    </ProjectContainer>
  );
};

export default Subscriptions;
