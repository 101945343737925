import * as yup from 'yup';
import { isValidYupSchema, yupUtils } from '../utils';
import { ProceduralStageEnum } from '../enums/caseEnums';
import { ProjectErrors, ProjectErrorType } from '../../utils/errors/types';
import { getProjectErrorForCode, getProjectErrorFromRawError, isProjectError } from '../../utils/errors';
import { CaseCategoryType, InstitutionType, PortalCase } from '../types/portal';
import { parsePortalCaseNumber } from './utils';
import { CaseCategoryEnum, DisplayProceduralStageEnum, InstitutionEnum } from './enums';

const PartySchema = yup.object().shape({
  name: yupUtils.stringRequired,
  partyRole: yupUtils.stringRequired,
});

export const HearingSchema = yup.object().shape({
  caseId: yupUtils.stringOptionalNullable,
  panel: yupUtils.stringRequired,
  portalDate: yupUtils.numberRequired,
  hour: yupUtils.stringRequired,
  startingHour: yupUtils.numberRequired,
  startingMinute: yupUtils.numberRequired,
  solution: yupUtils.stringOptionalNullable,
  solutionSummary: yupUtils.stringOptionalNullable,
  referenceDate: yupUtils.stringOptionalNullable,
});

export const PortalCaseSchema = yup.object().shape({
  number: yupUtils.stringRequired,
  institution: yupUtils.stringOptionalNullable,
  category: yupUtils.stringOptionalNullableOneOf(Object.keys(CaseCategoryEnum)),
  proceduralStage: yupUtils.stringOptionalNullableOneOf(Object.values(ProceduralStageEnum)),
  matter: yupUtils.stringOptionalNullable,
  portalCreatedAt: yupUtils.stringOptionalNullable,
  portalUpdatedAt: yupUtils.stringOptionalNullable,
  parties: yupUtils.arrayCompactRequiredOf(PartySchema),
  hearings: yupUtils.arrayCompactRequiredOf(HearingSchema),
});

const processPortalCase = (object: any): PortalCase => {
  return {
    ...object,
    displayNumber: parsePortalCaseNumber(object?.number),
    displayInstitution: InstitutionEnum[object?.institution as InstitutionType],
    displayProceduralStage: DisplayProceduralStageEnum[object?.proceduralStage as keyof typeof ProceduralStageEnum],
    displayCategory: CaseCategoryEnum[object?.category as CaseCategoryType],
  };
};

export const parsePortalCase = async (object: any): Promise<PortalCase | ProjectErrorType> => {
  try {
    if (await isValidYupSchema(PortalCaseSchema, object)) {
      return processPortalCase(object as PortalCase);
    }
    return getProjectErrorForCode(ProjectErrors.portalCaseInvalid);
  } catch (error) {
    console.log('Error at validating portal case (id:', object?.id, '):', error);
    return getProjectErrorFromRawError(error as Error);
  }
};

export const parsePortalCases = async (items: any): Promise<PortalCase[]> => {
  if (!(items && Array.isArray(items))) {
    console.log('[PORTAL CASES] Error: No array found.');
    return [];
  }

  const list: PortalCase[] = [];
  for (const item of items) {
    const o = await parsePortalCase(item);
    o && !isProjectError(o) && list.push(o as PortalCase);
  }

  return list;
};
