import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Case } from '../api/types/case';
import { parseDateToStringWithFormat } from '../utils/dateFormat';
import { format } from '../utils/dateFormat/types';
import { CaseCategoryEnum, InstitutionEnum } from '../api/portal/enums';
import { ProceduralStageEnum } from '../api/enums/caseEnums';
import { CaseCategoryType, InstitutionType, PortalCaseParty } from '../api/types/portal';

const getPortalCreatedAtTimestamp = (portalCreatedAt: string): number => {
  const portalCreatedAtString = new Date(portalCreatedAt).toISOString();
  const _portalCreatedAt = portalCreatedAtString?.substring(0, 19);
  return new Date(_portalCreatedAt)?.getTime();
};

const useCasesExport = () => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const processExcelData = (cases: Case[]) => {
    const casesObject: {
      [key: string]: {
        number: string;
        parties: PortalCaseParty[];
        category: CaseCategoryType;
        institution: InstitutionType;
        lastCase: Case;
        fond?: Case;
        apel?: Case;
        recurs?: Case;
      };
    } = {};
    cases?.forEach((item) => {
      const fond = item?.proceduralStage === ProceduralStageEnum.Fond ? item : undefined;
      const apel = item?.proceduralStage === ProceduralStageEnum.Apel ? item : undefined;
      const recurs = item?.proceduralStage === ProceduralStageEnum.Recurs ? item : undefined;
      const number = item?.displayNumber;
      const parties = item?.parties;
      const category = item?.category;
      const lastCase =
        casesObject[item?.displayNumber] === undefined
          ? item
          : item?.portalCreatedAt && casesObject[item?.displayNumber].lastCase?.portalCreatedAt
          ? getPortalCreatedAtTimestamp(item?.portalCreatedAt) >
            getPortalCreatedAtTimestamp(casesObject[item?.displayNumber].lastCase?.portalCreatedAt as string)
            ? item
            : casesObject[item?.displayNumber].lastCase
          : item;

      casesObject[item?.displayNumber] = {
        ...casesObject[item?.displayNumber],
        number,
        parties,
        category,
        institution: item?.institution,
        lastCase,
      };

      if (fond) {
        casesObject[item?.displayNumber].fond = fond;
      }
      if (apel) {
        casesObject[item?.displayNumber].apel = apel;
      }
      if (recurs) {
        casesObject[item?.displayNumber].recurs = recurs;
      }
    });

    const result = Object.values(casesObject)?.map((item) => {
      const fondLastHearing = item?.fond?.hearings?.[0]
        ? parseDateToStringWithFormat(item?.fond?.hearings?.[0]?.portalDate, format.monthDayYear)?.concat(
            ', ',
            item?.fond?.hearings?.[0]?.hour,
          )
        : 'Necunoscut';
      const apelLastHearing = item?.apel?.hearings?.[0]
        ? parseDateToStringWithFormat(item?.apel?.hearings?.[0]?.portalDate, format.monthDayYear)?.concat(
            ', ',
            item?.apel?.hearings?.[0]?.hour,
          )
        : 'Necunoscut';
      const recursLastHearing = item?.recurs?.hearings?.[0]
        ? parseDateToStringWithFormat(item?.recurs?.hearings?.[0]?.portalDate, format.monthDayYear)?.concat(
            ', ',
            item?.recurs?.hearings?.[0]?.hour,
          )
        : 'Necunoscut';

      const parties = item?.parties?.map((item) => item?.name).join(', ');

      const fondSolution = item?.fond?.hearings?.[0] ? item?.fond?.hearings?.[0]?.solutionSummary : 'Necunoscut';
      const apelSolution = item?.apel?.hearings?.[0] ? item?.apel?.hearings?.[0]?.solutionSummary : 'Necunoscut';
      const recursSolution = item?.recurs?.hearings?.[0] ? item?.recurs?.hearings?.[0]?.solutionSummary : 'Necunoscut';

      const category = item?.category ? CaseCategoryEnum[item?.category] : 'Necunoscut';

      return {
        'Natura litigiu': category,
        'Nr dosar procedural': item?.number,
        'Instanta fond': item?.fond?.displayInstitution,
        'Termen fond': fondLastHearing,
        'Solutie fond': fondSolution,
        'Instanta apel': item?.apel?.displayInstitution,
        'Termen apel': apelLastHearing,
        'Solutie apel': apelSolution,
        'Instanta recurs': item?.recurs?.displayInstitution,
        'Termen recurs': recursLastHearing,
        'Solutie recurs': recursSolution,
        'Nume parti': parties,
        'Status Portal': item?.lastCase?.proceduralStage,
        'Termen Portal': item?.lastCase?.hearings?.[0]
          ? parseDateToStringWithFormat(
              getPortalCreatedAtTimestamp(item?.lastCase?.hearings?.[0]?.portalDate),
              format.monthDayYear,
            )
          : 'Necunoscut',
        'Sedinta sumar Portal': item?.lastCase?.hearings?.[0]?.solution,
        'Solutie Portal': item?.lastCase?.hearings?.[0]?.solutionSummary,
        'Repartizarea catre avocat': '-',
        'Observatii/Note': '-',
      };
    });

    return result;
  };

  const exportToExcel = async (excelData: Case[]) => {
    const processedCases = processExcelData(excelData);

    const now = new Date().toISOString();
    const fileName = `Raport Dosare - ${now}`;

    const ws = XLSX.utils.json_to_sheet(processedCases);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return { exportToExcel };
};

export default useCasesExport;
