import styled from 'styled-components';
import AppCSS from '../../utils/styled';
import MuiAlert from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert/Alert';

export const Components = {
  Text: styled.span`
    ${AppCSS.fonts.label14Bold};
  `,
  Alert: styled(MuiAlert)<{ severity: AlertColor }>`
    ${({ severity }) => {
      switch (severity) {
        case 'error':
          return `
            background-color: ${AppCSS.colors.red};
          `;
        case 'info':
        case 'warning':
          return `
            background-color: ${AppCSS.colors.orange};
          `;
        case 'success':
          return `
            background-color: ${AppCSS.colors.primaryGreenGoth};
          `;
      }
    }}
  `,
};
