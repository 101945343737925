import * as yup from 'yup';
import { isValidYupSchema, yupUtils } from '../utils';
import { Note } from '../types/note';
import { ProjectErrors, ProjectErrorType } from '../../utils/errors/types';
import { getProjectErrorForCode, getProjectErrorFromRawError, isProjectError } from '../../utils/errors';

const NoteSchema = yup.object().shape({
  id: yupUtils.stringRequired,
  createdAt: yupUtils.numberRequired,
  updatedAt: yupUtils.numberRequired,
  profileId: yupUtils.stringRequired,
  caseId: yupUtils.stringRequired,
  text: yupUtils.stringRequired,
});

const processNote = (object: any): Note => {
  return {
    ...object,
    createdAt: new Date(object?.createdAt),
    updatedAt: new Date(object?.updatedAt),
  };
};

export const parseNote = async (object: any): Promise<Note | ProjectErrorType> => {
  try {
    if (await isValidYupSchema(NoteSchema, object)) {
      return processNote(object);
    }
    return getProjectErrorForCode(ProjectErrors.noteInvalid);
  } catch (error) {
    console.log('Error at validating note (id:', object?.id, '):', error);
    return getProjectErrorFromRawError(error as Error);
  }
};

export const parseNotes = async (items: any): Promise<Note[]> => {
  if (!(items && Array.isArray(items))) {
    console.log('[NOTES] Error: No array found.');
    return [];
  }

  const list: Note[] = [];
  for (const item of items) {
    const o = await parseNote(item);
    o && !isProjectError(o) && list.push(o as Note);
  }

  return list;
};
