import React from 'react';
import { Components } from './styled';
import ActivityIndicator from '../index';

const ActivityIndicatorScreenView = ({ leftPadding }: { leftPadding: number }) => {
  return (
    <Components.Container leftPadding={leftPadding}>
      <ActivityIndicator />
    </Components.Container>
  );
};

export default ActivityIndicatorScreenView;
