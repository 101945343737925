import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import styled from 'styled-components';
import AppCSS from '../../../../utils/styled';

export const Components = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    padding: 24px 16px;
    background-color: ${AppCSS.colors.white};
    ${AppCSS.boxShadows.block};
    overflow: hidden;
    height: min-content;
    width: 100%;
  `,
  Header: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
  `,
  HeaderTitle: styled.span`
    ${AppCSS.fonts.h2};
    color: ${AppCSS.colors.greens.green90};
  `,
  HeaderSubtitle: styled.span`
    ${AppCSS.fonts.label14Regular};
    color: ${AppCSS.colors.greens.green80};
  `,
  ProgressWrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
  `,
  ProgressLabel: styled.span`
    ${AppCSS.fonts.label14Regular};
    color: ${AppCSS.colors.greens.green80};
  `,
  ProgressLabelAccent: styled.span`
    ${AppCSS.fonts.label14Medium};
    color: ${AppCSS.colors.greens.green80};
  `,
  ProgressBar: styled(LinearProgress)`
    width: 100%;
    height: 10px !important;
    ${AppCSS.radiuses.default}
    background-color: ${AppCSS.colors.greens.green50} !important;
    & .${linearProgressClasses.bar} {
      ${AppCSS.radiuses.default}
      background-color: ${AppCSS.colors.primaryGreenGoth};
    }
  `,
  OnboardingStepsWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
  `,
  SuccessBannerWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 24px;
    gap: 24px;
  `,
  SuccessHeader: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
  `,
  SuccessTitle: styled.span`
    ${AppCSS.fonts.h3};
    color: ${AppCSS.colors.greens.green90};
  `,
  SuccessSubtitle: styled.span`
    ${AppCSS.fonts.label14Regular};
    color: ${AppCSS.colors.greens.green80};
  `,
  DoneButton: styled(AppCSS.buttonStyles.SecondaryButtonWithIconStyle)`
    padding: 10px 8px 10px 12px;
  `,
};
