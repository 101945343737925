import styled from 'styled-components/';
import { UIView } from '../../../../utils/styled/styled';

export const Components = {
  Container: styled(UIView)``,
  Button: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    &:hover {
      cursor: pointer;
    }
  `,
};
