import styled from 'styled-components';
import AppCSS from '../../../utils/styled';

export const Components = {
  Container: styled.div`
    width: 100%;
    border: 1px solid ${AppCSS.colors.greens.green60};
    ${AppCSS.radiuses.small};
    background-color: ${AppCSS.colors.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  `,
  SecureIconWrapper: styled.div<{ isPressable?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    padding: 4px;
    margin-right: 4px;
    ${({ isPressable }) => isPressable && 'cursor: pointer;'};
  `,
  TextField: styled.input<{ isDisabled?: boolean }>`
    flex: 1;
    width: 100%;
    padding: 12px;
    height: 38px;
    ${AppCSS.fonts.label12Medium};
    color: ${AppCSS.colors.greens.green90};
    background-color: transparent;
    ${({ isDisabled }) =>
      isDisabled &&
      `
      background-color: ${AppCSS.colors.greens.green40};
      color: ${AppCSS.colors.greens.green70};
    `}
    border: none;
    -webkit-appearance: none;
    &:focus-visible {
      -webkit-appearance: none;
      border: none;
      outline: none;
    }
    &:focus {
      -webkit-appearance: none;
      border: none;
      outline: none;
    }
    &::placeholder {
      color: ${AppCSS.colors.greens.green60};
    }
  `,
};
