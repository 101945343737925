import React from 'react';
import { CommonComponents } from './styled';

export const AccountTabPanel = ({
  children,
  tab,
  index,
  ...rest
}: {
  children: React.ReactNode;
  tab: number;
  index: number;
  [x: string]: any;
}) => {
  return (
    <CommonComponents.TabContainer
      fullWidth
      role='tabpanel'
      hidden={tab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}>
      {tab === index && <>{children}</>}
    </CommonComponents.TabContainer>
  );
};
