import React, { useContext, useEffect, useState } from 'react';
import ProjectContainer from '../../components/containers/ProjectContainer';
import { Components } from './styled';
import PortalInstructions from './components/PortalInstructions';
import PortalSearch from './components/PortalSearch';
import { PortalCase } from '../../api/types/portal';
import PortalCases from './components/PortalCases';
import { ActivityIndicatorContext } from '../../providers/ActivityIndicatorProvider/context';
import { saveCaseToPortal } from '../../api/portal';
import { isProjectError } from '../../utils/errors';
import { ToastContext } from '../../providers/ToastProvider/context';
import { SuccessResultDto } from '../../api/types';
import { SessionContext } from '../../providers/SessionProvider/context';
import { MEDIA_QUERY_BREAKPOINTS } from '../../utils/responsive/constants';
import { createMediaQuery } from '../../utils/responsive';
import { useMediaQuery } from 'react-responsive';
import { useQueryClient } from '@tanstack/react-query';
import { SavedCase } from '../../api/types/profile';
import _ from 'lodash';
import { SubscriptionContext } from '../../providers/SubsctiptionProvider/context';
import { ProjectErrors } from '../../utils/errors/types';
import { EVENTS, logEvent } from '../../_analytics';

const PortalJust = () => {
  const { setIsLoading } = useContext(ActivityIndicatorContext);
  const { showErrorToast, showWarningToast, showSuccessToast } = useContext(ToastContext);
  const { refreshProfile, currentProfile } = useContext(SessionContext);
  const { isSubscriptionActive } = useContext(SubscriptionContext);

  const [data, setData] = useState<PortalCase[]>([]);

  const [savedCases, setSavedCases] = useState<SavedCase[]>([]);

  useEffect(() => {
    if (currentProfile) {
      const _savedCases = _.unionBy(savedCases, currentProfile?.cases, 'id');
      setSavedCases(_savedCases);
    }
  }, [currentProfile]);

  const queryClient = useQueryClient();
  const isMobile = useMediaQuery({
    query: createMediaQuery(MEDIA_QUERY_BREAKPOINTS.largeMobile, true),
  });

  const onSavePortalCase = async (value: PortalCase) => {
    if (!isSubscriptionActive()) {
      showWarningToast(ProjectErrors.expiredSubscription);
      return;
    }
    if (currentProfile) {
      setIsLoading(true);
      const result = await saveCaseToPortal(value, currentProfile?.id);
      setIsLoading(false);
      const resultError = isProjectError(result);
      if (resultError) {
        showErrorToast(resultError);
      } else {
        const _result = result as SuccessResultDto;
        if (_result?.count > 0) {
          showSuccessToast(
            'Felicitări! Ai salvat dosarul în lista ta de dosare. Acum, îl poți viziona în secțiunea de dosare.',
          );
          logEvent(
            EVENTS.save_case({
              displayNumber: value?.displayNumber,
              displayInstitution: value?.displayInstitution,
              category: value?.displayCategory,
            }),
          ).then();
          refreshProfile().then();
          await queryClient.invalidateQueries({ queryKey: ['cases'] });
        } else {
          showWarningToast('Din păcate, nu am putut salva dosarul în lista ta. Încearcă mai târziu.');
        }
      }
    } else {
      showWarningToast(ProjectErrors.genericTryAgain);
    }
  };

  const isCaseAlreadyAdded = (item: PortalCase) => {
    return !!savedCases?.find((caseItem) => caseItem?.number === item?.number);
  };

  return (
    <ProjectContainer title={'Portal Just'}>
      <Components.Container isMobile={isMobile}>
        <Components.LeftSideContainer>
          <Components.HeaderContainer>
            <span>
              <Components.LeftSideTitle>
                Caută dosarul tău, <br /> direct de pe
              </Components.LeftSideTitle>
              <Components.LeftSideTitleAccent> Portal Just</Components.LeftSideTitleAccent>
            </span>
            <Components.LeftSideDescription>
              Aici puteți găsi dosarele de pe portal. Dacă doriți, le puteți salva în lista dumneavoastră de dosare.
            </Components.LeftSideDescription>
          </Components.HeaderContainer>
          <PortalSearch setData={setData} />
          <PortalCases data={data} isCaseAlreadyAdded={isCaseAlreadyAdded} onSaveCase={onSavePortalCase} />
        </Components.LeftSideContainer>
        <PortalInstructions />
      </Components.Container>
    </ProjectContainer>
  );
};

export default PortalJust;
