import React, { useContext, useEffect, useState } from 'react';
import ProjectContainer from '../../../components/containers/ProjectContainer';
import { Components } from './styled';
import { Case } from '../../../api/types/case';
import { ActivityIndicatorContext } from '../../../providers/ActivityIndicatorProvider/context';
import { ToastContext } from '../../../providers/ToastProvider/context';
import { useNavigate, useParams } from 'react-router-dom';
import { getProjectErrorForCode, isProjectError } from '../../../utils/errors';
import { ProjectErrors } from '../../../utils/errors/types';
import { getCaseById } from '../../../api/case';
import CaseGeneralInfo from './components/CaseGeneralInfo';
import SecondaryTag from '../../../components/tags/SecondaryTag';
import { parseDateToStringWithFormat } from '../../../utils/dateFormat';
import { format } from '../../../utils/dateFormat/types';
import { MEDIA_QUERY_BREAKPOINTS } from '../../../utils/responsive/constants';
import { useMediaQuery } from 'react-responsive';
import { createMediaQuery } from '../../../utils/responsive';
import { SessionContext } from '../../../providers/SessionProvider/context';
import CaseNotes from './components/CaseNotes';
import CaseHearings from '../../../components/caseComponents/CaseHearings';

const CaseDetails = () => {
  const { id: initialId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { setIsLoading } = useContext(ActivityIndicatorContext);
  const { showErrorToast } = useContext(ToastContext);
  const { currentProfile, isLoggedIn, userIsAdmin } = useContext(SessionContext);

  const [data, setData] = useState<Case | undefined>();

  const isLaptop = useMediaQuery({
    query: createMediaQuery(MEDIA_QUERY_BREAKPOINTS.smallLaptop),
  });

  const refreshCase = async () => {
    if (initialId) {
      setIsLoading(true);
      const result = await getCaseById(initialId);
      const resultError = isProjectError(result);
      if (resultError) {
        showErrorToast(resultError);
      } else {
        setData(result as Case);
      }
      setIsLoading(false);
    } else {
      showErrorToast(getProjectErrorForCode(ProjectErrors.genericTryAgain));
    }
  };

  useEffect(() => {
    if (currentProfile && isLoggedIn && !data) {
      refreshCase().then();
    }
  }, [currentProfile, isLoggedIn]);

  const onViewLogs = () => {
    navigate(`/activitate-dosar/${initialId}`);
  };

  const _createdAt = data?.portalCreatedAt
    ? parseDateToStringWithFormat(data?.portalCreatedAt, format.monthDayYearTime)
    : undefined;
  const _updatedAt = data?.portalUpdatedAt
    ? parseDateToStringWithFormat(data?.portalUpdatedAt, format.monthDayYearTime)
    : undefined;
  return (
    <ProjectContainer hasBack>
      <Components.Container isLaptop={isLaptop}>
        <Components.HeaderContainer>
          <Components.HeaderContentWrapper>
            <Components.HeaderTitleWrapper>
              {data?.displayNumber && <Components.HeaderTitle>{data?.displayNumber}</Components.HeaderTitle>}
              {data?.displayProceduralStage && <SecondaryTag text={data?.displayProceduralStage} />}
            </Components.HeaderTitleWrapper>
            <Components.HeaderDetailsWrapper>
              {_createdAt && (
                <Components.HeaderDetailsLabel>Adăugat la data de {_createdAt}</Components.HeaderDetailsLabel>
              )}
              {_updatedAt && (
                <Components.HeaderDetailsLabel>Actualizat ultima dată pe {_updatedAt}</Components.HeaderDetailsLabel>
              )}
            </Components.HeaderDetailsWrapper>
          </Components.HeaderContentWrapper>
          {userIsAdmin && <Components.TertiaryButton onClick={onViewLogs}>Vezi log-uri</Components.TertiaryButton>}
        </Components.HeaderContainer>
        <Components.BodyContainer isLaptop={isLaptop}>
          {data && (
            <>
              <Components.ColumnContainer>
                <CaseGeneralInfo data={data} />
                <CaseNotes caseData={data} />
              </Components.ColumnContainer>
              <CaseHearings data={data} />
            </>
          )}
        </Components.BodyContainer>
      </Components.Container>
    </ProjectContainer>
  );
};

export default CaseDetails;
