import {
  GridCallbackDetails,
  GridColumns,
  GridFilterModel,
  GridInputSelectionModel,
  GridRowParams,
  GridSelectionModel,
  MuiEvent,
} from '@mui/x-data-grid';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Components } from './styled';
import { ProjectTableDataType, ProjectTableType } from './types';
import * as React$1 from 'react';
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT, TABLE_DEFAULT_SHOWN_ITEMS } from './constants';
import Placeholder from '../Placeholder';
import { DEFAULT_PLACEHOLDER_TEXT } from '../Placeholder/constants';
import { parseDataToRows } from './utils';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/icon-arrow-right.svg';

const ProjectTable = ({
  data,
  columns,
  checkboxSelection = false,
  onSelectionChange,
  selectedRows,
  placeholder = DEFAULT_PLACEHOLDER_TEXT,
  filteringModel,
  resetPageTrigger,
}: {
  data: ProjectTableDataType;
  columns: {
    columns: GridColumns;
    type: ProjectTableType;
    primaryAction?: (
      params: GridRowParams,
      event: MuiEvent<React$1.SyntheticEvent>,
      details: GridCallbackDetails,
    ) => void;
  };
  checkboxSelection?: boolean;
  onSelectionChange?: (selectionModel: GridSelectionModel) => void;
  selectedRows?: GridInputSelectionModel;
  placeholder?: string;
  filteringModel?: GridFilterModel;
  resetPageTrigger?: string;
}) => {
  const { columns: tableColumns, type: tableType, primaryAction } = columns;
  const tableRows = parseDataToRows(tableType, data);

  const [pageSize, setPageSize] = useState(TABLE_DEFAULT_SHOWN_ITEMS);
  const [pageNavText, setPageNavText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(Math.ceil(tableRows?.length / pageSize));

  useEffect(() => {
    if (tableRows?.length > 0) {
      setPageSize(TABLE_DEFAULT_SHOWN_ITEMS <= tableRows?.length ? TABLE_DEFAULT_SHOWN_ITEMS : tableRows?.length);
    }
    if (tableRows?.length <= 10) {
      setCurrentPage(1);
    }
  }, [tableRows.length]);

  useEffect(() => {
    if (resetPageTrigger) {
      setCurrentPage(1);
    }
  }, [resetPageTrigger]);

  const onNavInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const _value = event.target.value;
    if ((_value && _value.length <= 3) || _value.length === 0) {
      setPageNavText(_value);
    }
    const parsedPageNumber = parseInt(_value);
    if (parsedPageNumber <= Math.ceil(tableRows?.length / pageSize)) {
      setCurrentPage && setCurrentPage(parsedPageNumber);
    }
  };

  const onPaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage && setCurrentPage(page);
  };

  const _onSelectionChange = (selectionModel: GridSelectionModel) => {
    onSelectionChange?.(selectionModel);
  };

  const onRowDoubleClick = (
    params: GridRowParams,
    event: MuiEvent<React$1.SyntheticEvent>,
    details: GridCallbackDetails,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    primaryAction?.(params, event, details);
  };

  return (
    <Components.Container>
      <Components.TableWrapper>
        <Components.Table
          rows={tableRows}
          columns={tableColumns}
          checkboxSelection={checkboxSelection}
          onStateChange={(state) => setPageCount(Math.ceil(state.pagination.rowCount / pageSize))}
          onSelectionModelChange={_onSelectionChange}
          selectionModel={selectedRows}
          filterModel={filteringModel}
          page={(currentPage ? currentPage : 2) - 1}
          pageSize={pageSize}
          disableColumnMenu
          rowHeight={TABLE_ROW_HEIGHT}
          pagination={undefined}
          headerHeight={TABLE_HEADER_HEIGHT}
          disableColumnSelector
          disableColumnFilter
          onRowDoubleClick={onRowDoubleClick}
          autoHeight
          hideFooter
          components={{
            NoRowsOverlay: () => (
              <Components.PlaceholderWrapper>
                <Placeholder type={'fullHeight'} text={placeholder} />
              </Components.PlaceholderWrapper>
            ),
            NoResultsOverlay: () => (
              <Components.PlaceholderWrapper>
                <Placeholder type={'fullHeight'} text={placeholder} />
              </Components.PlaceholderWrapper>
            ),
          }}
        />
      </Components.TableWrapper>
      {pageCount > 1 && (
        <Components.TablePaginationContainer>
          <Components.PaginationRightContainer>
            <Components.TablePagination
              page={currentPage}
              variant={'outlined'}
              count={pageCount}
              onChange={onPaginationChange}
            />
            {pageCount > 1 && (
              <>
                <Components.NavigationSeparator />
                <Components.PaginationNavigation>
                  <Components.NavigationText>Mergi la pagina</Components.NavigationText>
                  <Components.NavigationInput
                    value={pageNavText}
                    type={'number'}
                    variant={'outlined'}
                    size={'small'}
                    onChange={onNavInputChange}
                  />
                </Components.PaginationNavigation>
              </>
            )}
          </Components.PaginationRightContainer>
        </Components.TablePaginationContainer>
      )}
    </Components.Container>
  );
};

export default ProjectTable;
