import styled from 'styled-components/';
import Dialog from '@mui/material/Dialog';
import AppCSS from '../../utils/styled';
import DialogContent from '@mui/material/DialogContent';
import { DEFAULT_PROJECT_MODAL_WIDTH, MEDIUM_PROJECT_MODAL_WIDTH } from '../../constants';

export const Components = {
  Modal: styled(Dialog)<{ modalWidth?: number | 'auto' }>`
    & .MuiDialog-paper {
      ${({ modalWidth }) =>
        modalWidth === 'auto'
          ? 'width: auto;'
          : `
        width: ${modalWidth ? modalWidth : MEDIUM_PROJECT_MODAL_WIDTH}px;
        max-width: ${modalWidth ? modalWidth : MEDIUM_PROJECT_MODAL_WIDTH}px;
      `}
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: ${AppCSS.colors.white};
      ${AppCSS.boxShadows.modal};
      ${AppCSS.radiuses.default};
    }
  `,
  ModalHeader: styled.div<{ noBottomLine: boolean | undefined }>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding-bottom: 16px;
    border-bottom: ${(props) => (props.noBottomLine ? null : `1px solid ${AppCSS.colors.greens.green40}`)}};
  `,
  TitleWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 6px 0;
  `,
  ModalHeaderTitle: styled.span`
    ${AppCSS.fonts.label16Bold};
    color: ${AppCSS.colors.greens.green90};
  `,
  ModalHeaderIconWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 4px 0;
    &:hover {
      cursor: pointer;
    }
  `,
  ModalButtonsContainer: styled.div`
    width: 100%;
    padding: 20px 0 12px 0;
    grid-gap: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `,
  ModalContentContainer: styled(DialogContent)<{ noBottomLine: boolean | undefined }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 8px;
    padding: 0 0 8px 0 !important;
  `,
};
