import React, { useMemo, useState } from 'react';
import { UIProviderContext } from './context';

const UIProvider = ({ children }: { children: React.ReactNode }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      isModalOpen,
      setIsModalOpen,
    }),
    [isModalOpen],
  );

  return <UIProviderContext.Provider value={value}>{children}</UIProviderContext.Provider>;
};

export default UIProvider;
