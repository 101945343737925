import React, { useContext, useEffect, useState } from 'react';
import ProjectContainer from '../../components/containers/ProjectContainer';
import { Components } from './styled';
import ProjectTable from '../../components/ProjectTable';
import { Case } from '../../api/types/case';
import { filteringModel, tableColumns } from '../../components/ProjectTable/constants';
import { getAllCases } from '../../api/case';
import { SessionContext } from '../../providers/SessionProvider/context';
import { ActivityIndicatorContext } from '../../providers/ActivityIndicatorProvider/context';
import ProjectTableHeader from '../../components/ProjectTableHeader';
import useFormTextState from '../../hooks/useFormTextState';
import useFormSelectState from '../../hooks/useFormSelectState';
import { useNavigate } from 'react-router-dom';
import { archiveCase } from '../../api/profile';
import { ReactComponent as SearchIcon } from '../../assets/icons/icon-search.svg';
import useCasesExport from '../../hooks/useCasesExport';
import { useQuery } from '@tanstack/react-query';

const Cases = () => {
  const navigate = useNavigate();

  const { currentProfile, refreshProfile } = useContext(SessionContext);
  const { setIsLoading } = useContext(ActivityIndicatorContext);

  const { isLoading, data } = useQuery({
    queryKey: ['cases'],
    queryFn: () => getAllCases(currentProfile?.caseIds || []),
    enabled: !!currentProfile,
  });

  const searchProps = useFormTextState();
  const proceduralStageProps = useFormSelectState();
  const { exportToExcel } = useCasesExport();

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  const onViewCaseDetails = (id: string) => {
    navigate(`/dosare/${id}`);
  };
  const onArchiveCase = async (id: string) => {
    if (currentProfile) {
      setIsLoading(true);
      await archiveCase(id, currentProfile?.id);
      await refreshProfile();
      setIsLoading(false);
    }
  };

  const onSearchPortalPress = () => {
    navigate('/portal-just');
  };

  const onExportExcel = async () => {
    await exportToExcel(data || []);
  };

  // todo - fix filtering
  const _filteringModel = filteringModel([
    { columnField: 'searchValue', operatorValue: 'contains', value: searchProps.value },
    // { columnField: 'proceduralStage', operatorValue: 'contains', value: proceduralStageProps.value },
  ]);
  return (
    <ProjectContainer title={'Dosare'}>
      <Components.Container>
        <ProjectTableHeader
          onSearchChange={searchProps.onChangeText}
          searchLabel={'Caută după numărul dosarului, obiect, numele părții sau instanță'}
          searchPlaceholder={'Introduceți număr dosar, obiect, numele părții, instanță'}>
          {/*<Components.FieldWrapper>*/}
          {/*  <Components.FieldLabel>Stadiu procesual</Components.FieldLabel>*/}
          {/*  <ProjectSelect*/}
          {/*    value={proceduralStageProps.value}*/}
          {/*    onChangeSelectedItem={proceduralStageProps.onChangeSelectedItem}>*/}
          {/*    {Object.keys(DisplayProceduralStageEnum)?.map((item, key) => (*/}
          {/*      <Components.MenuItem value={item} key={key}>*/}
          {/*        {DisplayProceduralStageEnum[item as keyof typeof DisplayProceduralStageEnum]}*/}
          {/*      </Components.MenuItem>*/}
          {/*    ))}*/}
          {/*  </ProjectSelect>*/}
          {/*</Components.FieldWrapper>*/}
          <Components.SearchBarChildrenWrapper>
            <Components.PrimaryButtonAdjacentLabel>SAU</Components.PrimaryButtonAdjacentLabel>
            <Components.PrimaryButton onClick={onSearchPortalPress}>
              <SearchIcon />
              Caută pe Portal
            </Components.PrimaryButton>
          </Components.SearchBarChildrenWrapper>
        </ProjectTableHeader>
        <Components.TableHeaderWrapper>
          <Components.TabsWrapper />
          <Components.SecondaryButton onClick={onExportExcel}>Exportă Excel</Components.SecondaryButton>
        </Components.TableHeaderWrapper>
        <Components.CaseTableWrapper>
          <ProjectTable
            data={data || []}
            columns={tableColumns.cases({ onViewDetails: onViewCaseDetails, onArchive: onArchiveCase })}
            filteringModel={_filteringModel}
          />
        </Components.CaseTableWrapper>
      </Components.Container>
    </ProjectContainer>
  );
};

export default Cases;
