import styled from 'styled-components';
import { ProjectCalendarType } from './types';
import Calendar from 'react-calendar';
import AppCSS from '../../../utils/styled';
import { DAY_TILE_HEIGHT_BIG, DAY_TILE_HEIGHT_SMALL, DAY_TILE_WIDTH_BIG, DAY_TILE_WIDTH_SMALL } from './constants';

export const Components = {
  CalendarWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  Calendar: styled(Calendar)<{ variant: ProjectCalendarType; isMinified?: boolean }>`
    border: none !important;
    & .react-calendar__navigation {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .react-calendar__navigation button {
      ${AppCSS.fonts.label14Bold};
      color: ${AppCSS.colors.primaryMatteBlack};
      text-transform: capitalize;
      padding: 9px 8px;
      background-color: transparent;
      border: none;
      &:hover {
        cursor: pointer;
      }
    }
    & .react-calendar__tile {
      ${(p) => (p.isMinified ? AppCSS.fonts.label14Bold : AppCSS.fonts.label16Bold)};
      color: ${AppCSS.colors.primaryMatteBlack};
      text-transform: capitalize;
      ${AppCSS.radiuses.small};
      &:hover {
        cursor: pointer;
      }
    }
    & .react-calendar__month-view__days abbr,
    .react-calendar__year-view__months abbr {
      display: none;
    }
    & .react-calendar__month-view__weekdays {
      align-items: center;
      justify-content: space-between;
      ${AppCSS.fonts.label10Regular};
      color: ${AppCSS.colors.greens.green90};
      text-transform: capitalize;
      border-bottom: 1px solid ${AppCSS.colors.utility.borders};
      padding: 8px 4px;
      margin-bottom: 8px;
      & abbr[title] {
        text-decoration: none !important;
      }
    }
    & .react-calendar__month-view__weekdays__weekday {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: auto !important;
      ${AppCSS.fonts.label14Medium};
      color: ${AppCSS.colors.greens.green70};
      width: 32px;
    }
    & .react-calendar__month-view__days {
      display: grid !important;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: ${(p) => (p.isMinified ? 10 : 20)}px;
    }
    & .react-calendar__month-view__days__day {
      width: ${(p) => (p.isMinified ? DAY_TILE_WIDTH_SMALL : DAY_TILE_WIDTH_BIG)}px;
      max-width: ${(p) => (p.isMinified ? DAY_TILE_WIDTH_SMALL : DAY_TILE_WIDTH_BIG)}px !important;
      height: ${(p) => (p.isMinified ? DAY_TILE_HEIGHT_SMALL : DAY_TILE_HEIGHT_BIG)}px;
      border: none;
      background-color: transparent;
      &:hover,
      :focus {
        background: ${AppCSS.colors.greens.green50};
      }
    }
    & .react-calendar__tile--active,
    & .react-calendar__tile--hasActive {
      background-color: ${AppCSS.colors.primaryGreenGoth};
      color: ${AppCSS.colors.greens.green40};
      &:hover,
      :focus {
        background-color: ${AppCSS.colors.primaryGreenGoth};
        color: ${AppCSS.colors.greens.green50};
      }
    }
    & .react-calendar__month-view__days__day--neighboringMonth {
      color: ${AppCSS.colors.greens.green60};
    }
    & .react-calendar__year-view__months {
      display: grid !important;
      grid-template-columns: repeat(3, 1fr);
      column-width: 80px;
      grid-gap: 4px;
      & .react-calendar__year-view__months__month {
        padding: 12px;
        width: 100%;
        max-width: 100% !important;
        background-color: transparent;
        border: none;
        &:hover {
          background: ${AppCSS.colors.greens.green50};
        }
      }
    }
    & .react-calendar__decade-view__years {
      display: grid !important;
      grid-template-columns: repeat(3, 1fr);
      column-width: 80px;
      grid-gap: 4px;
      & .react-calendar__decade-view__years__year {
        padding: 12px;
        width: 100%;
        max-width: 100% !important;
        background-color: transparent;
        border: none;
        &:hover {
          background: ${AppCSS.colors.greens.green50};
        }
      }
    }
    & .react-calendar__century-view__decades {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
      column-width: 80px;
      grid-gap: 4px;
      & .react-calendar__century-view__decades__decade {
        padding: 12px;
        width: 100%;
        max-width: 100% !important;
        background-color: transparent;
        border: none;
        text-align: center;
        &:hover {
          background: ${AppCSS.colors.greens.green50};
        }
      }
    }
  `,
};
