import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { SessionContext } from './context';
import { Profile } from '../../api/types/profile';
import { ProjectErrors, ProjectErrorType } from '../../utils/errors/types';
import {
  changePassword,
  firebaseLogout,
  loginWithEmail,
  sendPasswordRecoverEmail,
  sendPhoneVerificationCode as sendPhoneVerificationCodeMethod,
  signUpWithEmail,
  verifyPhoneNumber as verifyPhoneNumberMethod,
} from '../../api/auth';
import { getProjectErrorForCode, isProjectError } from '../../utils/errors';
import { User, UserCredential } from '@firebase/auth';
import { getOrCreateProfile, getProfileByUserId } from '../../api/profile';
import { auth } from '../../firebase';
import { createAuthHeaders } from '../../api/utils';
import { ActivityIndicatorContext } from '../ActivityIndicatorProvider/context';
import useLocalStorage from '../../hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '../../defines';
import { TRAITS, updateLoggedInState, updateTrait } from '../../_analytics';
import { subscribeTo, unsubscribeFrom } from '../../utils/event-listeners';
import { ToastContext } from '../ToastProvider/context';

const SessionProvider = ({ children }: { children: React.ReactNode }) => {
  const { setIsLoading } = useContext(ActivityIndicatorContext);
  const { showErrorToast } = useContext(ToastContext);

  const [currentProfile, setCurrentProfile] = useState<Profile | undefined>();
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage(LOCAL_STORAGE_KEYS.isLoggedIn, false);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const initializingRef = useRef(true);

  const [doesVerifyPhoneNumber, setDoesVerifyPhoneNumber] = useState(false);
  const doesVerifyPhoneNumberRef = useRef(false);

  const cachedUserRef = useRef<User | undefined>(undefined);
  const cachedPasswordRef = useRef<string | undefined>(undefined);
  const cachedPhoneNumberRef = useRef<string | undefined>(undefined);
  const cachedPhoneNumberCodeRef = useRef<string | undefined>(undefined);
  const [cachedPhoneNumber, setCachedPhoneNumber] = useState<string | undefined>(undefined);

  const signUpCampaignRef = useRef<string | undefined>();
  const signUpCampaignIdRef = useRef<string | undefined>();

  const setDoesVerifyPhoneAction = (value: boolean) => {
    doesVerifyPhoneNumberRef.current = value;
    setDoesVerifyPhoneNumber(value);
  };

  const updateCurrentProfile = (profile: Profile) => {
    setCurrentProfile(profile);
  };

  const resumeSession = async (initialUserUid: string) => {
    if (doesVerifyPhoneNumberRef.current) {
      return;
    }
    setIsLoading(true);
    const profile = await getProfileByUserId(initialUserUid);
    setIsLoading(false);
    const error = isProjectError(profile);
    if (error) {
      console.log('[Auth]', 'Failed to resume session. Profile is invalid. Details:', error?.code);
      logout();
      return;
    }

    const typedProfile = profile as Profile;
    onLogin(typedProfile);
  };

  const onAuthStateChanged = async (user: User | null | undefined) => {
    if (user && initializingRef.current) {
      await resumeSession(user.uid);
    }
    if (!user) {
      onLogout();
    }
    initializingRef.current = false;
  };

  useEffect(() => {
    return auth.onAuthStateChanged(onAuthStateChanged);
  }, []);

  useEffect(() => {
    subscribeTo('forceLogout', onForceLogout);
    return () => {
      unsubscribeFrom('forceLogout');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onForceLogout = async () => {
    showErrorToast(getProjectErrorForCode(ProjectErrors?.forbidden));
    logout();
  };

  const refreshProfile = async () => {
    const userId = await auth?.currentUser?.uid;
    if (userId) {
      const result = await getProfileByUserId(userId);
      const resultError = isProjectError(result);
      if (resultError) {
        logout();
      } else if (result && 'id' in result) {
        setCurrentProfile(result);
      }
    }
  };

  const signUp = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    phoneNumberCode: string,
  ): Promise<ProjectErrorType | undefined> => {
    const signupResult = await signUpWithEmail(email, password);
    const signupError = isProjectError(signupResult);
    if (signupError) {
      logout();
      return signupError;
    }

    const { user } = signupResult as UserCredential;
    if (!user.uid) {
      logout();
      return getProjectErrorForCode(ProjectErrors.authInvalidUserId);
    }

    const authHeaders = await createAuthHeaders(user);
    const profileResult = await getOrCreateProfile(
      authHeaders,
      user?.uid,
      firstName,
      lastName,
      email,
      phoneNumber,
      phoneNumberCode,
      signUpCampaignRef.current,
      signUpCampaignIdRef.current,
    );
    const profileError = isProjectError(profileResult);
    if (profileError) {
      logout();
      return profileError;
    }

    const typedResult = profileResult as Profile;
    if (!typedResult?.isPhoneNumberVerified) {
      cachedUserRef.current = user;
      cachedPhoneNumberRef.current = phoneNumber;
      cachedPhoneNumberCodeRef.current = phoneNumberCode;
      setCachedPhoneNumber(phoneNumberCode?.concat(' ', phoneNumber));
      cachedPasswordRef.current = password;
      setDoesVerifyPhoneAction(true);
      logout();
      const twilioResult = await sendPhoneVerificationCode();
      const twilioError = isProjectError(twilioResult);
      if (twilioError) {
        return twilioError;
      }
      return getProjectErrorForCode(ProjectErrors.phoneNumberNotVerified);
    }

    const typedProfile = profileResult as Profile;
    updateTrait(TRAITS.sign_up_date(typedProfile?.createdAt?.toISOString())).then();
    onLogin(typedProfile);
  };

  const login = async (email: string, password: string): Promise<ProjectErrorType | undefined> => {
    const loginResult = await loginWithEmail(email, password);
    const loginError = isProjectError(loginResult);
    if (loginError) {
      logout();
      return loginError;
    }

    const { user } = loginResult as UserCredential;
    if (!user.uid) {
      logout();
      return getProjectErrorForCode(ProjectErrors.authInvalidUserId);
    }

    const authHeaders = await createAuthHeaders(user);
    const profileResult = await getOrCreateProfile(authHeaders, user?.uid);
    const profileError = isProjectError(profileResult);
    if (profileError) {
      logout();
      return profileError;
    }

    const typedResult = profileResult as Profile;
    if (!typedResult?.isPhoneNumberVerified) {
      cachedUserRef.current = user;
      cachedPhoneNumberRef.current = typedResult?.phoneNumber;
      cachedPhoneNumberCodeRef.current = typedResult?.phoneNumberCode;
      setCachedPhoneNumber(typedResult?.phoneNumberCode?.concat(' ', typedResult?.phoneNumber));
      cachedPasswordRef.current = password;
      setDoesVerifyPhoneAction(true);
      logout();
      const twilioResult = await sendPhoneVerificationCode();
      const twilioError = isProjectError(twilioResult);
      if (twilioError) {
        return twilioError;
      }
      return getProjectErrorForCode(ProjectErrors.phoneNumberNotVerified);
    }

    onLogin(typedResult);
  };

  const sendPhoneVerificationCode = async (): Promise<ProjectErrorType | undefined> => {
    if (!(cachedUserRef.current && cachedPhoneNumberRef.current && cachedPhoneNumberCodeRef.current)) {
      return getProjectErrorForCode(ProjectErrors.genericTryAgain);
    }

    const authHeaders = await createAuthHeaders(cachedUserRef.current);
    const result = await sendPhoneVerificationCodeMethod(
      authHeaders,
      cachedPhoneNumberRef.current,
      cachedPhoneNumberCodeRef.current,
    );
    const resultError = isProjectError(result);
    if (resultError) {
      logout();
      return resultError;
    }
  };

  const verifyPhoneNumber = async (smsCode: string): Promise<ProjectErrorType | undefined> => {
    if (
      !(
        cachedUserRef.current &&
        cachedPhoneNumberRef.current &&
        cachedPhoneNumberCodeRef.current &&
        cachedPasswordRef.current
      )
    ) {
      return getProjectErrorForCode(ProjectErrors.genericTryAgain);
    }

    const authHeaders = await createAuthHeaders(cachedUserRef.current);
    const result = await verifyPhoneNumberMethod(
      authHeaders,
      cachedUserRef.current?.uid,
      cachedPhoneNumberRef.current,
      cachedPhoneNumberCodeRef.current,
      smsCode,
    );
    const resultError = isProjectError(result);
    if (resultError) {
      logout();
      return resultError;
    }

    if (cachedUserRef.current?.email) {
      return await login(cachedUserRef.current?.email, cachedPasswordRef.current);
    }
    return getProjectErrorForCode(ProjectErrors.genericTryAgain);
  };

  const changePasswordAction = async (newPassword: string): Promise<ProjectErrorType | undefined> => {
    return await changePassword(newPassword);
  };

  const recoverPassword = async (email: string): Promise<void> => {
    return await sendPasswordRecoverEmail(email);
  };

  const setSignUpCampaignDetails = (campaign: string, campaignIdentifier: string) => {
    signUpCampaignRef.current = campaign;
    signUpCampaignIdRef.current = campaignIdentifier;
  };

  const logout = () => {
    onLogout();
    try {
      firebaseLogout().then();
    } catch (error) {}
  };

  const value = useMemo(
    () => ({
      currentProfile,
      isLoggedIn,
      userIsAdmin,
      doesVerifyPhoneNumber,
      cachedPhoneNumber,
      updateCurrentProfile,
      refreshProfile,
      signUp,
      login,
      sendPhoneVerificationCode,
      verifyPhoneNumber,
      recoverPassword,
      setSignUpCampaignDetails,
      changePassword: changePasswordAction,
      logout,
    }),
    [currentProfile, isLoggedIn, userIsAdmin, doesVerifyPhoneNumber, cachedPhoneNumber],
  );

  const onLogin = (profile: Profile) => {
    setUserIsAdmin(profile?.isAdmin || false);
    setCurrentProfile(profile);
    setIsLoggedIn(true);
    setDoesVerifyPhoneAction(false);
    updateLoggedInState(profile?.id).then();
    console.log('[AUTH] User just logged in');
  };

  const onLogout = () => {
    setCurrentProfile(undefined);
    setIsLoggedIn(false);
    console.log('[AUTH] User just logged out');
  };

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
