import styled from 'styled-components';
import AppCSS from '../../utils/styled';

export const Components = {
  Container: styled.div<{ isLaptop: boolean }>`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: ${(p) => (p.isLaptop ? 'row' : 'column-reverse')};
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px ${(p) => (p.isLaptop ? 24 : 16)}px;
    background-color: ${AppCSS.colors.utility.screenBackground};
    gap: ${(p) => (p.isLaptop ? 72 : 24)}px;
  `,
  LeftSideContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    max-width: min-content;
    margin-top: 20px;
  `,
  LeftSideHeader: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
  `,
  LeftSideTitleWrapper: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
  `,
  TodayButton: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    ${AppCSS.radiuses.default};
    border: 1px solid ${AppCSS.colors.utility.borders};
    ${AppCSS.fonts.label14Bold};
    color: ${AppCSS.colors.primaryGreenGoth};
    cursor: pointer;
    background-color: ${AppCSS.colors.greens.green50};
    gap: 4px;
  `,
  ArrowsWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    position: relative;
    top: 6px;
  `,
  ArrowWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    ${AppCSS.radiuses.forHeight(32)};
    background-color: ${AppCSS.colors.white};
    ${AppCSS.boxShadows.block};
    cursor: pointer;
  `,
  LeftSideTitle: styled.span`
    ${AppCSS.fonts.h1};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  LeftSideSubtitle: styled.span`
    ${AppCSS.fonts.paragraph12Regular};
    color: ${AppCSS.colors.greens.green70};
    margin-top: 4px;
  `,
  RightSideContainer: styled.div<{ isLaptop: boolean }>`
    display: flex;
    width: 100%;
    margin-top: ${(p) => (p.isLaptop ? 24 : 12)}px;
  `,
};
