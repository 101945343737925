import styled from 'styled-components';
import AppCSS from '../../../../../utils/styled';

export const Components = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    width: 100%;
  `,
  HeaderWrapper: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  Title: styled.span`
    ${AppCSS.fonts.label14Regular};
    color: ${AppCSS.colors.greens.green70};
  `,
  NotSetLabel: styled.span`
    ${AppCSS.fonts.label16Medium};
    color: ${AppCSS.colors.greens.green60};
  `,
  PickPartyButton: styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border: 1px solid ${AppCSS.colors.greens.green50};
    ${AppCSS.radiuses.small};
    ${AppCSS.fonts.h6};
    color: ${AppCSS.colors.primaryGreenGoth};
  `,
  CancelButton: styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border: 1px solid ${AppCSS.colors.greens.green50};
    ${AppCSS.radiuses.small};
    ${AppCSS.fonts.h6};
    color: ${AppCSS.colors.orange};
  `,
};
