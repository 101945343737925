import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { COUNTRIES_ARRAY } from '../../components/inputs/ProjectPhoneNumberField/data';

export const isValidEmail = (text: string | undefined): boolean => {
  if (!text) {
    return false;
  }
  const result =
    text && text.length > 0 && /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(text);
  return !!result;
};

export const isValidPassword = (text: string | undefined): boolean => {
  if (!text) {
    return false;
  }
  const result = text && text.length > 0 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(text);
  return !!result;
};

export const isValidPhoneNumber = (phoneNumber: string | undefined, phoneNumberCode: string): boolean => {
  if (!(phoneNumber && phoneNumberCode)) {
    return false;
  }
  const text = phoneNumberCode?.concat(phoneNumber);
  const country = COUNTRIES_ARRAY.find((c) => text?.split(' ')[text.split(' ').length - 1]?.startsWith(c.dialCode));
  if (!country) {
    return false;
  }
  const _phoneNumber = parsePhoneNumberFromString(text);
  return !!_phoneNumber?.isValid();
};
