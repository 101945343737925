import styled from 'styled-components';
import AppCSS from '../../utils/styled';

export const Components = {
  Container: styled.div<{ isMobile: boolean }>`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 16px ${(p) => (p.isMobile ? 16 : 24)}px;
    background-color: ${AppCSS.colors.utility.screenBackground};
    gap: 32px;
  `,
  LeftSideContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
  `,
  HeaderContainer: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    flex-direction: column;
  `,
  LeftSideTitle: styled.span`
    ${AppCSS.fonts.h1};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  LeftSideTitleAccent: styled.span`
    ${AppCSS.fonts.h1};
    color: ${AppCSS.colors.primaryGreenGoth};
  `,
  LeftSideDescription: styled.span`
    ${AppCSS.fonts.paragraph12Regular};
    color: ${AppCSS.colors.greens.green70};
  `,
};
