export enum InstitutionEnum {
  TribunalulCONSTANTA = 'Tribunalul Constanța',
  CurteadeApelBUCURESTI = 'Curtea de Apel București',
  TribunalulBUCURESTI = 'Tribunalul București',
  JudecatoriaSECTORUL4BUCURESTI = 'Judecătoria Sectorului 4 din București',
  TribunalulTIMIS = 'Tribunalul Timiș',
  CurteadeApelBACAU = 'Curtea de Apel Bacău',
  CurteadeApelCLUJ = 'Curtea de Apel Cluj',
  CurteadeApelORADEA = 'Curtea de Apel Oradea',
  CurteadeApelCONSTANTA = 'Curtea de Apel Constanța',
  CurteadeApelSUCEAVA = 'Curtea de Apel Suceava',
  TribunalulBOTOSANI = 'Tribunalul Botoșani',
  CurteadeApelPLOIESTI = 'Curtea de Apel Ploiești',
  CurteadeApelTARGUMURES = 'Curtea de Apel Târgu Mureș',
  CurteadeApelGALATI = 'Curtea de Apel Galați',
  CurteadeApelIASI = 'Curtea de Apel Iași',
  CurteadeApelPITESTI = 'Curtea de Apel Pitești',
  CurteadeApelCRAIOVA = 'Curtea de Apel Craiova',
  JudecatoriaARAD = 'Judecătoria Arad',
  CurteadeApelALBAIULIA = 'Curtea de Apel Alba Iulia',
  CurteadeApelTIMISOARA = 'Curtea de Apel Timișoara',
  TribunalulBRASOV = 'Tribunalul Brașov',
  TribunalulDOLJ = 'Tribunalul Dolj',
  CurteadeApelBRASOV = 'Curtea de Apel Brașov',
  CurteaMilitaradeApelBUCURESTI = 'Curtea Militară de Apel București',
  TribunalulSATUMARE = 'Tribunalul Satu Mare',
  TribunalulSALAJ = 'Tribunalul Sălaj',
  TribunalulSIBIU = 'Tribunalul Sibiu',
  TribunalulSUCEAVA = 'Tribunalul Suceava',
  TribunalulTELEORMAN = 'Tribunalul Teleorman',
  TribunalulTULCEA = 'Tribunalul Tulcea',
  TribunalulVASLUI = 'Tribunalul Vaslui',
  TribunalulVALCEA = 'Tribunalul Vâlcea',
  TribunalulVRANCEA = 'Tribunalul Vrancea',
  TribunalulMilitarBUCURESTI = 'Tribunalul Militar București',
  TribunalulILFOV = 'Tribunalul Ilfov',
  JudecatoriaBUFTEA = 'Judecătoria Buftea',
  TribunalulGORJ = 'Tribunalul Gorj',
  TribunalulHARGHITA = 'Tribunalul Harghita',
  TribunalulHUNEDOARA = 'Tribunalul Hunedoara',
  TribunalulIALOMITA = 'Tribunalul Ialomița',
  TribunalulIASI = 'Tribunalul Iași',
  TribunalulMARAMURES = 'Tribunalul Maramureș',
  TribunalulMEHEDINTI = 'Tribunalul Mehedinți',
  TribunalulMURES = 'Tribunalul Mureș',
  TribunalulNEAMT = 'Tribunalul Neamț',
  TribunalulOLT = 'Tribunalul Olt',
  TribunalulPRAHOVA = 'Tribunalul Prahova',
  TribunalulALBA = 'Tribunalul Alba',
  TribunalulARAD = 'Tribunalul Arad',
  TribunalulARGES = 'Tribunalul Argeș',
  TribunalulBACAU = 'Tribunalul Bacău',
  TribunalulBIHOR = 'Tribunalul Bihor',
  TribunalulBISTRITANASAUD = 'Tribunalul Bistrița Năsăud',
  TribunalulBRAILA = 'Tribunalul Brăila',
  TribunalulBUZAU = 'Tribunalul Buzău',
  TribunalulCARASSEVERIN = 'Tribunalul Caraș-Severin',
  TribunalulCALARASI = 'Tribunalul Călărași',
  TribunalulCLUJ = 'Tribunalul Cluj',
  TribunalulCOVASNA = 'Tribunalul Covasna',
  TribunalulDAMBOVITA = 'Tribunalul Dâmbovița',
  TribunalulGALATI = 'Tribunalul Galați',
  TribunalulGIURGIU = 'Tribunalul Giurgiu',
  JudecatoriaADJUD = 'Judecătoria Adjub',
  JudecatoriaAGNITA = 'Judecătoria Agnita',
  JudecatoriaAIUD = 'Judecătoria Aiud',
  JudecatoriaALBAIULIA = 'Judecătoria Alba Iulia',
  JudecatoriaALESD = 'Judecătoria Aleșd',
  JudecatoriaBABADAG = 'Judecătoria Babadag',
  JudecatoriaBACAU = 'Judecătoria Bacău',
  JudecatoriaBAIADEARAMA = 'Judecătoria Baia de Aramă',
  JudecatoriaBAIAMARE = 'Judecătoria Baia Mare',
  JudecatoriaBAILESTI = 'Judecătoria Băilești',
  JudecatoriaBALS = 'Judecătoria Balș',
  JudecatoriaBALCESTI = 'Judecătoria Bălcești',
  JudecatoriaBECLEAN = 'Judecătoria Beclean',
  JudecatoriaBEIUS = 'Judecătoria Beiuș',
  JudecatoriaBICAZ = 'Judecătoria Bicaz',
  JudecatoriaBARLAD = 'Judecătoria Bârlad',
  JudecatoriaBISTRITA = 'Judecătoria Bistrița',
  JudecatoriaBLAJ = 'Judecătoria Blaj',
  JudecatoriaBOLINTINVALE = 'Judecătoria Bolintin-Vale',
  JudecatoriaBOTOSANI = 'Judecătoria Botoșani',
  JudecatoriaBOZOVICI = 'Judecătoria Bozovici',
  JudecatoriaBRAD = 'Judecătoria Brad',
  JudecatoriaBRAILA = 'Judecătoria Brăila',
  JudecatoriaBRASOV = 'Judecătoria Brașov',
  JudecatoriaBREZOI = 'Judecătoria Brezoi',
  JudecatoriaBUHUSI = 'Judecătoria Buhuși',
  JudecatoriaBUZAU = 'Judecătoria Buzău',
  JudecatoriaCALAFAT = 'Judecătoria Calafat',
  JudecatoriaCALARASI = 'Judecătoria Calarași',
  JudecatoriaCAMPENI = 'Judecătoria Câmpeni',
  JudecatoriaCAMPINA = 'Judecătoria Câmpina',
  JudecatoriaCAMPULUNG = 'Judecătoria Câmpulung',
  JudecatoriaCAMPULUNGMOLDOVENESC = 'Judecătoria Câmpulung Moldovonesc',
  JudecatoriaCARACAL = 'Judecătoria Caracal',
  JudecatoriaCARANSEBES = 'Judecătoria Caransebeș',
  JudecatoriaCHISINEUCRIS = 'Judecătoria Chișineu-Criș',
  JudecatoriaCLUJNAPOCA = 'Judecătoria Cluj-Napoca',
  JudecatoriaCONSTANTA = 'Judecătoria Constanța',
  JudecatoriaCORABIA = 'Judecătoria Corabia',
  JudecatoriaCOSTESTI = 'Judecătoria Costești',
  JudecatoriaCRAIOVA = 'Judecătoria Craiova',
  JudecatoriaCURTEADEARGES = 'Judecătoria Curtea de Argeș',
  JudecatoriaDarabani = 'Judecătoria Darabani', // "JudecatoriaDarabani" - Nu e greșit, așa era pe site
  JudecatoriaCAREI = 'Judecătoria Carei',
  JudecatoriaDEJ = 'Judecătoria Dej',
  JudecatoriaDETA = 'Judecătoria Deta',
  JudecatoriaDEVA = 'Judecătoria Deva',
  JudecatoriaDOROHOI = 'Judecătoria Dorohoi',
  JudecatoriaDRAGASANI = 'Judecătoria Drăgășani',
  JudecatoriaDRAGOMIRESTI = 'Judecătoria Dragomirești',
  JudecatoriaDROBETATURNUSEVERIN = 'Judecătoria Drobeta-Turnu Severin',
  JudecatoriaFAGARAS = 'Judecătoria Făgăraș',
  JudecatoriaFALTICENI = 'Judecătoria Fălticeni',
  JudecatoriaFAUREI = 'Judecătoria Faurei',
  JudecatoriaFETESTI = 'Judecătoria Fetești',
  JudecatoriaFILIASI = 'Judecătoria Filiași',
  JudecatoriaFOCSANI = 'Judecătoria Focșani',
  JudecatoriaGAESTI = 'Judecătoria Găești',
  JudecatoriaGALATI = 'Judecătoria Galați',
  JudecatoriaGHEORGHENI = 'Judecătoria Gheorgheni',
  JudecatoriaGHERLA = 'Judecătoria Gherla',
  JudecatoriaGIURGIU = 'Judecătoria Giurgiu',
  JudecatoriaGURAHUMORULUI = 'Judecătoria Gura Humorului',
  JudecatoriaGURAHONT = 'Judecătoria Gurahonț',
  JudecatoriaHARLAU = 'Judecătoria Hârlău',
  JudecatoriaHATEG = 'Judecătoria Hațeg',
  JudecatoriaHOREZU = 'Judecătoria Horezu',
  JudecatoriaHUEDIN = 'Judecătoria Huedin',
  JudecatoriaHUNEDOARA = 'Judecătoria Hunedoara',
  JudecatoriaHUSI = 'Judecătoria Huși',
  JudecatoriaIASI = 'Judecătoria Iași',
  JudecatoriaINEU = 'Judecătoria Ineu',
  JudecatoriaINSURATEI = 'Judecătoria Însurăței',
  JudecatoriaINTORSURABUZAULUI = 'Judecătoria Întorsura Buzăului',
  JudecatoriaLEHLIUGARA = 'Judecătoria Lehliu-Gară',
  JudecatoriaLIPOVA = 'Judecătoria Lipova',
  JudecatoriaLUDUS = 'Judecătoria Luduș',
  JudecatoriaLUGOJ = 'Judecătoria Lugoj',
  JudecatoriaMACIN = 'Judecătoria Măcin',
  JudecatoriaMANGALIA = 'Judecătoria Mangalia',
  JudecatoriaMARGHITA = 'Judecătoria Marghita',
  JudecatoriaMEDGIDIA = 'Judecătoria Medgidia',
  JudecatoriaMEDIAS = 'Judecătoria Mediaș',
  JudecatoriaMIERCUREACIUC = 'Judecătoria Miercurea Ciuc',
  JudecatoriaMIZIL = 'Judecătoria Mizil',
  JudecatoriaMOINESTI = 'Judecătoria Moinești',
  JudecatoriaMOLDOVANOUA = 'Judecătoria Moldova Nouă',
  JudecatoriaMORENI = 'Judecătoria Moreni',
  JudecatoriaMOTRU = 'Judecătoria Motru',
  JudecatoriaMURGENI = 'Judecătoria Murgeni',
  JudecatoriaNASAUD = 'Judecătoria Năsăud',
  JudecatoriaNEGRESTIOAS = 'Judecătoria Negrești-Oaș',
  JudecatoriaNOVACI = 'Judecătoria Novaci',
  JudecatoriaODORHEIULSECUIESC = 'Judecătoria Odorheiu Secuiesc',
  JudecatoriaOLTENITA = 'Judecătoria Oltenița',
  JudecatoriaONESTI = 'Judecătoria Onești',
  JudecatoriaORADEA = 'Judecătoria Oradea',
  JudecatoriaORASTIE = 'Judecătoria Orăștie',
  JudecatoriaORAVITA = 'Judecătoria Oravița',
  JudecatoriaORSOVA = 'Judecătoria Orșova',
  JudecatoriaPANCIU = 'Judecătoria Panciu',
  JudecatoriaPATARLAGELE = 'Judecătoria Pătârlagele',
  JudecatoriaPETROSANI = 'Judecătoria Petroșani',
  JudecatoriaPIATRANEAMT = 'Judecătoria Piatra Neamț',
  JudecatoriaPITESTI = 'Judecătoria Pitești',
  JudecatoriaPLOIESTI = 'Judecătoria Ploiești',
  JudecatoriaPOGOANELE = 'Judecătoria Pogoanele',
  JudecatoriaPUCIOASA = 'Judecătoria Pucioasa',
  JudecatoriaRACARI = 'Judecătoria Răcari',
  JudecatoriaRADAUTI = 'Judecătoria Rădăuți',
  JudecatoriaRADUCANENI = 'Judecătoria Răducăneni',
  JudecatoriaRAMNICUSARAT = 'Judecătoria Râmnicu Sărat',
  JudecatoriaRAMNICUVALCEA = 'Judecătoria Râmnicu Vâlcea',
  JudecatoriaREGHIN = 'Judecătoria Reghin',
  JudecatoriaRESITA = 'Judecătoria Reșița',
  JudecatoriaROMAN = 'Judecătoria Roman',
  JudecatoriaROSIORIDEVEDE = 'Judecătoria Roșiori de Vede',
  JudecatoriaRUPEA = 'Judecătoria Rupea',
  JudecatoriaSALISTE = 'Judecătoria Săliște',
  JudecatoriaSANNICOLAULMARE = 'Judecătoria Sânnicolau Mare',
  JudecatoriaSATUMARE = 'Judecătoria Satu Mare',
  JudecatoriaSAVENI = 'Judecătoria Săveni',
  JudecatoriaSEBES = 'Judecătoria Sebeș',
  JudecatoriaSECTORUL1BUCURESTI = 'Judecătoria Sectorul 1 București',
  JudecatoriaSECTORUL2BUCURESTI = 'Judecătoria Sectorul 2 București',
  JudecatoriaSECTORUL3BUCURESTI = 'Judecătoria Sectorul 3 București',
  JudecatoriaSECTORUL5BUCURESTI = 'Judecătoria Sectorul 5 București',
  JudecatoriaSECTORUL6BUCURESTI = 'Judecătoria Sectorul 6 București',
  JudecatoriaSEGARCEA = 'Judecătoria Segarcea',
  JudecatoriaSFANTUGHEORGHE = 'Judecătoria Sfântu Gheorghe',
  JudecatoriaSIBIU = 'Judecătoria Sibiu',
  JudecatoriaSIGHETUMARMATIEI = 'Judecătoria Sighetu Marmației',
  JudecatoriaSIGHISOARA = 'Judecătoria Sighișoara',
  JudecatoriaSIMLEULSILVANIEI = 'Judecătoria Șimleu Silvaniei',
  JudecatoriaSINAIA = 'Judecătoria Sinaia',
  JudecatoriaSLATINA = 'Judecătoria Slatina',
  JudecatoriaSLOBOZIA = 'Judecătoria Slobozia',
  JudecatoriaSTREHAIA = 'Judecătoria Strehaia',
  JudecatoriaSUCEAVA = 'Judecătoria Suceava',
  JudecatoriaTARGOVISTE = 'Judecătoria Târgoviște',
  JudecatoriaTARGUBUJOR = 'Judecătoria Târgu Bujor',
  JudecatoriaTARGUCARBUNESTI = 'Judecătoria Târgu Cărbunești',
  JudecatoriaTARGUJIU = 'Judecătoria Târgu Jiu',
  JudecatoriaTARGULAPUS = 'Judecătoria Lăpuș',
  JudecatoriaTARGUMURES = 'Judecătoria Târgu Mureș',
  JudecatoriaTARGUNEAMT = 'Judecătoria Târgu Neamț',
  JudecatoriaTARGUSECUIESC = 'Judecătoria Târgu Secuiesc',
  JudecatoriaTARNAVENI = 'Judecătoria Târnăveni',
  JudecatoriaTECUCI = 'Judecătoria Tecuci',
  JudecatoriaTIMISOARA = 'Judecătoria Timișoara',
  JudecatoriaTOPLITA = 'Judecătoria Toplița',
  JudecatoriaTULCEA = 'Judecătoria Tulcea',
  JudecatoriaTURDA = 'Judecătoria Turda',
  JudecatoriaTURNUMAGURELE = 'Judecătoria Turnu Măgurele',
  JudecatoriaURZICENI = 'Judecătoria Urziceni',
  JudecatoriaVALENIIDEMUNTE = 'Judecătoria Vălenii de Munte',
  JudecatoriaVANJUMARE = 'Judecătoria Vânju Mare',
  JudecatoriaVASLUI = 'Judecătoria Vaslui',
  JudecatoriaVATRADORNEI = 'Judecătoria Vatra Dornei',
  JudecatoriaVIDELE = 'Judecătoria Videle',
  JudecatoriaVISEUDESUS = 'Judecătoria Vișeu de Sus',
  JudecatoriaZALAU = 'Judecătoria Zalău',
  JudecatoriaZARNESTI = 'Judecătoria Zărnești',
  JudecatoriaZIMNICEA = 'Judecătoria Zimnicea',
  TribunalulMilitarIASI = 'Tribunalul Militar Iași',
  JudecatoriaALEXANDRIA = 'Judecătoria Alexandria',
  TribunalulMilitarTIMISOARA = 'Tribunalul Militar Timișoara',
  TribunalulMilitarCLUJNAPOCA = 'Tribunalul Militar Cluj-Napoca',
  TribunalulMilitarTeritorialBUCURESTI = 'Tribunalul Militar Teritorial București',
  JudecatoriaAVRIG = 'Judecătoria Avrig',
  JudecatoriaTOPOLOVENI = 'Judecătoria Topoloveni',
  JudecatoriaPODUTURCULUI = 'Judecătoria Podu Turcului',
  JudecatoriaFAGET = 'Judecătoria Făget',
  JudecatoriaSALONTA = 'Judecătoria Salonta',
  JudecatoriaLIESTI = 'Judecătoria Liești',
  JudecatoriaHARSOVA = 'Judecătoria Hârșova',
  JudecatoriaSOMCUTAMARE = 'Judecătoria Șomcuta Mare',
  JudecatoriaPASCANI = 'Judecătoria Pașcani',
  TribunalulComercialARGES = 'Tribunalul Comercial Argeș',
  TribunalulComercialCLUJ = 'Tribunalul Comercial Cluj',
  TribunalulComercialMURES = 'Tribunalul Comercial Mureș',
  TribunalulpentruminoriSifamilieBRASOV = 'Tribunalul pentru minori și familie Brașov',
  JudecatoriaCORNETU = 'Judecătoria Cornetu',
  JudecatoriaJIBOU = 'Judecătoria Jibou',
}

export enum CaseCategoryEnum {
  Penal = 'Penal',
  Civil = 'Civil',
  Litigiicuprofesionistii = 'Litigii cu profesioniștii',
  Contenciosadministrativsifiscal = 'Contencios administrativ și fiscal',
  Minorisifamilie = 'Minori și familie',
  Litigiidemunca = 'Litigii de muncă',
  Altematerii = 'Alte materii',
  Asigurarisociale = 'Asigurări sociale',
  Faliment = 'Faliment',
  ProprietateIntelectuala = 'Proprietate intelectuală',
  Dreptmaritimsifluvial = 'Drept maritim și fluvial',
  Insolventapersoaneifizice = 'Insolvența persoanei fizice',
}

export enum DisplayProceduralStageEnum {
  Fond = 'Fond',
  Apel = 'Apel',
  Recurs = 'Recurs',
  Recursinanulare = 'Recurs în anulare',
  Recursininteresullegii = 'Recurs în interesul legii',
  Sesizareprealabila = 'Sesizare prealabilă',
  ContestaţieNCPP = 'Contestație NCPP',
  Recursincasatie = 'Recurs în casație',
  ContestatieICCJ = 'Contestație ICCJ',
  ContestatieInAnulareNCPP = 'Contestație în anulare NCPP',
  RevizuireContestatieNCPP = 'Revizuire contestație NCPP',
  Contestatie = 'Contestație',
  Revizuire = 'Revizuire',
  Contestatieinanulare = 'Contestație în anulare',
  Stabilireacompetentei = 'Stabilirea competenței',
  Recursimpotrivaincheierii = 'Recurs împotriva încheierii',
  Recuzare = 'Recuzare',
  Stramutare = 'Strămutare',
  Indreptareeroaremateriala = 'Îndreptare eroare materială',
  ContestatieinanulareFond = 'Contestație în anulare Fond',
  ContestatieinanulareApel = 'Contestație în anulare Apel',
  ContestatieinanulareRecurs = 'Contestație în anulare Recurs',
  RevizuireFond = 'Revizuire Fond',
  RevizuireApel = 'Revizuire Apel',
  RevizuireRecurs = 'Revizuire Recurs',
}
