import styled from 'styled-components';
import AppCSS from '../../utils/styled';

export const Components = {
  Container: styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    background-color: ${AppCSS.colors.utility.screenBackground};
    gap: 24px;
  `,
  CardContainer: styled.div`
    background-color: ${AppCSS.colors.white};
    ${AppCSS.boxShadows.menu};
    padding: 36px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 322px;
    gap: 44px;
    ${AppCSS.radiuses.rounded};
    overflow: hidden;
  `,
  CardHeader: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
  `,
  CardTitle: styled.span`
    ${AppCSS.fonts.h2};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  CardSubtitle: styled.span`
    ${AppCSS.fonts.label20Regular};
    color: ${AppCSS.colors.greens.green80};
  `,
  FreeLabel: styled.span`
    ${AppCSS.fonts.h2};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  PriceLabel: styled.span`
    ${AppCSS.fonts.h1};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  PriceAccent: styled.span`
    ${AppCSS.fonts.h3};
    color: ${AppCSS.colors.greens.green70};
  `,
  PricePeriod: styled.span`
    ${AppCSS.fonts.label20Regular};
    color: ${AppCSS.colors.greens.green80};
  `,
  SecondaryButton: styled(AppCSS.buttonStyles.SecondaryButtonWithIconStyle)`
    padding: 10px 12px;
    width: 100%;
  `,
  PrimaryButton: styled(AppCSS.buttonStyles.PrimaryButtonWithIconStyle)`
    padding: 10px 12px;
    width: 100%;
  `,
  BenefitsWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
  `,
  BenefitsTitle: styled.span`
    ${AppCSS.fonts.label16Bold};
    color: ${AppCSS.colors.greens.green90};
  `,
  BenefitItem: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  `,
  BenefitLabel: styled.span`
    ${AppCSS.fonts.label16Regular};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  BenefitLabelAccent: styled.span`
    ${AppCSS.fonts.label16Bold};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
};
