import styled from 'styled-components';
import AppCSS from '../../../utils/styled';

export const Components = {
  Container: styled.div<{ leftPadding: number }>`
    display: flex;
    flex: 1;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding-left: ${({ leftPadding }) => leftPadding}px;

    align-items: center;
    justify-content: center;

    background-color: ${AppCSS.colors.utility.activityIndicator.background};

    z-index: 99999;
  `,
};
