import React, { useMemo } from 'react';
import { Components } from './styled';
import { useQuery } from '@tanstack/react-query';
import { filteringModel, tableColumns } from '../../../components/ProjectTable/constants';
import ProjectTable from '../../../components/ProjectTable';
import { getSyncLogs } from '../../../api/case';
import ProjectContainer from '../../../components/containers/ProjectContainer';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectTableHeader from '../../../components/ProjectTableHeader';
import useFormTextState from '../../../hooks/useFormTextState';

const CasesSyncLog = () => {
  const { id: initialId } = useParams<{ id: string }>();

  const navigate = useNavigate();
  const { data } = useQuery({ queryKey: ['casesSyncLogs'], queryFn: getSyncLogs });

  const fetchedCasesSearchProps = useFormTextState();
  const syncedCasesSearchProps = useFormTextState();

  const onViewCaseDetails = (id: string) => {
    navigate(`/dosare/${id}`);
  };

  const syncLog = useMemo(() => data?.find((item) => item?.id === initialId), [data]);
  const _filteringModel = filteringModel([
    { columnField: 'searchValue', operatorValue: 'contains', value: fetchedCasesSearchProps.value },
  ]);
  return (
    <ProjectContainer hasBack>
      <Components.Container>
        <ProjectTableHeader
          onSearchChange={fetchedCasesSearchProps.onChangeText}
          searchLabel={'Caută în dosarele verificate'}
          searchPlaceholder={'Introdu numărul dosarului sau instanța de judecată'}
        />
        <ProjectTable
          data={syncLog?.casesFetchedFromFirestore || []}
          columns={tableColumns.casesPreview({ onViewDetails: onViewCaseDetails })}
          filteringModel={_filteringModel}
        />
        <ProjectTableHeader
          onSearchChange={syncedCasesSearchProps.onChangeText}
          searchLabel={'Caută în dosarele sincronizate'}
          searchPlaceholder={'Introdu numărul dosarului sau instanța de judecată'}
        />
        <ProjectTable
          data={syncLog?.syncedCases || []}
          columns={tableColumns.casesPreview({ onViewDetails: onViewCaseDetails })}
          filteringModel={_filteringModel}
        />
      </Components.Container>
    </ProjectContainer>
  );
};

export default CasesSyncLog;
