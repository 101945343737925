import styled from 'styled-components';
import AppCSS from '../../../../utils/styled';
import Checkbox from '@mui/material/Checkbox';

export const Components = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 36px;
  `,
  FieldWrapper: styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 16px;
  `,
  FieldLabelAndDescription: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  FieldLabel: styled.span`
    ${AppCSS.fonts.label12Bold};
    color: ${AppCSS.colors.greens.green80};
  `,
  FieldDescription: styled.span`
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green70};
  `,
  Checkbox: styled(Checkbox)`
    &.Mui-checked {
      color: ${AppCSS.colors.primaryGreenGoth} !important;
    }
    &.Mui-disabled {
      color: ${AppCSS.colors.greens.green60} !important;
    }
    color: ${AppCSS.colors.greens.green60} !important;
    width: 20px;
    height: 20px;
  `,
  InputsWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
  `,
  ButtonsWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  `,
  EditTertiaryButton: styled(AppCSS.buttonStyles.TertiaryButtonStyle)`
    max-width: min-content;
    margin-top: 10px;
  `,
  PrimaryButton: styled(AppCSS.buttonStyles.PrimaryButtonWithIconStyle)``,
  TertiaryButton: styled(AppCSS.buttonStyles.TertiaryButtonStyle)``,
  SecondaryButton: styled(AppCSS.buttonStyles.SecondaryButtonWithIconStyle)``,
};
