import React from 'react';
import { CalendarTileProperties, Detail } from 'react-calendar';
import { Components } from './styled';
import { CalendarConfigurationType } from './types';
import DayEvents from './tiles/DayEvents';
import { hasHearings } from './utils';
import { isSameDay } from 'date-fns';
import { getMonthFromDate, parseDateToStringWithFormat } from '../../../utils/dateFormat';
import { format } from '../../../utils/dateFormat/types';

const TileContent = ({
  date,
  view,
  hasHearings,
  isCurrentDate,
}: {
  date: Date;
  view: Detail;
  hasHearings: boolean;
  isCurrentDate?: boolean;
}) => {
  switch (view) {
    case 'month':
      return <DayEvents date={date} hasHearings={hasHearings} isCurrentDate={isCurrentDate} />;
    case 'year':
      return <span>{getMonthFromDate(date)}</span>;
  }
  return null;
};

const ProjectCalendar = ({ calendarConfig }: { calendarConfig: CalendarConfigurationType }) => {
  const { value, onValueChange, variant, events, activeStartDate, isMinified } = calendarConfig;

  const _onValueChange = (value: Date | [Date, Date] | null) => {
    onValueChange?.(value as Date | null);
  };

  return (
    <Components.CalendarWrapper>
      <Components.Calendar
        // @ts-ignore
        value={value}
        onChange={_onValueChange}
        {...(activeStartDate && { activeStartDate })}
        locale={'ro'}
        variant={variant}
        prev2Label={null}
        next2Label={null}
        minDetail={'year'}
        defaultView={'month'}
        showNavigation={false}
        isMinified={isMinified}
        tileContent={({ date, view }: CalendarTileProperties) => (
          <TileContent
            date={date}
            view={view}
            hasHearings={events ? hasHearings(date, events) : false}
            isCurrentDate={value ? isSameDay(value, date) : false}
          />
        )}
        formatShortWeekday={(locale, date) => parseDateToStringWithFormat(date, format.shortDay)}
      />
    </Components.CalendarWrapper>
  );
};

export default ProjectCalendar;
