import styled from 'styled-components';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import AppCSS from '../../../utils/styled';

export const Components = {
  Tooltip: styled(Tooltip)``,
};

export const styles = {
  TooltipStyle: `
    background-color: ${AppCSS.colors.greens.green80};
    ${AppCSS.radiuses.small};
    padding: 10px;
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green40};
  `,
};
