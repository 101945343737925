import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import useFormState from './useFormState';

export interface FormTextStateType {
  value: string | undefined;
  setValue: Dispatch<SetStateAction<string | undefined>>;
  onChangeText: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const useFormTextState = (
  initialValue?: string | undefined,
  process?: (value: string) => string | undefined,
  debounceTime?: number,
): FormTextStateType => {
  const props = useFormState<string, ChangeEvent<HTMLInputElement | HTMLTextAreaElement>>(
    initialValue,
    process,
    debounceTime,
  );

  return { onChangeText: props.onChange, ...props };
};

export default useFormTextState;
