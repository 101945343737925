import React from 'react';
import { Components } from './styled';
import { Profile } from '../../api/types/profile';

const ProfileAvatar = ({
  profile,
  onClick,
  size,
  initials,
}: {
  profile?: Profile;
  onClick?: (event: any) => void;
  size: 24 | 32 | 42 | 56 | 64 | 96;
  initials?: string;
}) => {
  const _initials = profile ? profile?.initials : initials;

  const _onClick = (event: any) => {
    onClick?.(event);
  };

  return (
    <Components.Container onClick={_onClick} canBeClicked={!!onClick}>
      <Components.UserBgIcon dimension={size}>
        <Components.UserInitials size={size}>{_initials}</Components.UserInitials>
      </Components.UserBgIcon>
    </Components.Container>
  );
};

export default ProfileAvatar;
