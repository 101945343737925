import { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import { Components, styles } from './styled';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/icon-angle-down.svg';

const ProjectSelect = ({
  value,
  onChangeSelectedItem,
  disabled,
  placeholder,
  processShownLabel,
  children,
}: {
  value: string | undefined;
  onChangeSelectedItem: (e: SelectChangeEvent<string | undefined>) => void;
  placeholder?: string;
  disabled?: boolean;
  processShownLabel?: (value: any) => any;
  children: React.ReactNode;
}) => {
  const _onChangeSelectedItem = (e: SelectChangeEvent<unknown>) => {
    onChangeSelectedItem?.(e as SelectChangeEvent<string | undefined>);
  };

  const defaultProcessShownLabel = (value: string | undefined) => value;

  return (
    <Components.Select
      value={value || ''}
      onChange={_onChangeSelectedItem}
      IconComponent={ArrowDownIcon}
      {...(placeholder &&
        placeholder.length > 0 && {
          displayEmpty: true,
          renderValue: (value: string | undefined) =>
            value !== '' && value !== undefined
              ? processShownLabel
                ? processShownLabel(value)
                : defaultProcessShownLabel(value)
              : placeholder,
        })}
      disabled={disabled}
      // @ts-ignore
      MenuProps={{ sx: styles.SelectMenu }}>
      {children}
    </Components.Select>
  );
};

export default ProjectSelect;
