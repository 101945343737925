import { SearchPortalCaseDto } from '../typeDtos/portal';
import apiFetch from '../index';
import { ENDPOINTS } from '../endpoints';
import { PortalCase } from '../types/portal';
import { parsePortalCases } from './parser';
import { ProjectErrorType } from '../../utils/errors/types';
import { SuccessResultDto } from '../types';
import { getProjectErrorFromRawError } from '../../utils/errors';

export const getCasesFromPortal = async (dto: SearchPortalCaseDto): Promise<PortalCase[]> => {
  try {
    const result = (
      await apiFetch({
        path: ENDPOINTS.case.searchPortalCase,
        body: dto,
        method: 'POST',
      })
    )?.data;
    return parsePortalCases(result);
  } catch (e) {
    console.log('[getCasesFromPortal] Error:', e);
    return [];
  }
};

export const saveMultipleCasesFromPortal = async (
  profileId: string,
  numbers: string[],
): Promise<SuccessResultDto | ProjectErrorType> => {
  try {
    return await apiFetch({
      path: ENDPOINTS.case.saveMultipleCases,
      body: { userId: profileId, numbers },
      method: 'POST',
    });
  } catch (error) {
    console.log('[getMultipleCasesFromPortal] Error:', error);
    return getProjectErrorFromRawError(error);
  }
};

export const saveCaseToPortal = async (
  dto: PortalCase,
  userId: string,
): Promise<ProjectErrorType | SuccessResultDto> => {
  try {
    return await apiFetch({
      path: ENDPOINTS.case.savePortalCase,
      body: { dto, userId },
      method: 'POST',
    });
  } catch (error) {
    console.log('[saveCaseToPortal] Error:', error);
    return getProjectErrorFromRawError(error);
  }
};
