import _ from 'lodash';

export const truncateString = (value: string, maxLength: number) => {
  return value?.length > maxLength ? `${value.substring(0, maxLength - 1)} …` : value;
};

export const concatArrayOfStrings = (specialities: string[]) => {
  return specialities && specialities?.length > 0
    ? specialities.reduce((result, item) => result.concat(', ', item), '' as string).substring(2)
    : undefined;
};

export const getArrayOfStringsFromString = (value: string | undefined): string[] => {
  return value && value.length > 0 ? value.split(',').map((t) => t.trim()) : [];
};

export const isArrayOfStrings = (value: any): boolean => {
  return Array.isArray(value) && value?.length > 0 && value.every((item) => typeof item === 'string');
};

export const createRandomKey = () => {
  const validChars = '0123456789';
  return _.sampleSize(validChars, 10).join('');
};
