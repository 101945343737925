import React, { useContext, useEffect, useState } from 'react';
import { Components } from './styled';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectContainer from '../../../components/containers/ProjectContainer';
import { useMediaQuery } from 'react-responsive';
import { createMediaQuery } from '../../../utils/responsive';
import { MEDIA_QUERY_BREAKPOINTS } from '../../../utils/responsive/constants';
import { ActivityIndicatorContext } from '../../../providers/ActivityIndicatorProvider/context';
import { ToastContext } from '../../../providers/ToastProvider/context';
import { Profile } from '../../../api/types/profile';
import { parseDateToStringWithFormat } from '../../../utils/dateFormat';
import { format } from '../../../utils/dateFormat/types';
import { getProjectErrorForCode, isProjectError } from '../../../utils/errors';
import { ProjectErrors } from '../../../utils/errors/types';
import { getUserById, updateUser } from '../../../api/users';
import { getAllCases, getLatestCases } from '../../../api/case';
import useModalState from '../../../hooks/useModalState';
import AddCasesModal from './components/AddCasesModal';
import { saveMultipleCasesFromPortal } from '../../../api/portal';
import TomorrowCases from './components/TomorrowCases';
import useCasesExport from '../../../hooks/useCasesExport';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import ProjectTable from '../../../components/ProjectTable';
import ProjectTableHeader from '../../../components/ProjectTableHeader';
import useFormTextState from '../../../hooks/useFormTextState';
import { filteringModel, tableColumns } from '../../../components/ProjectTable/constants';
import { deleteCasesForUser } from '../../../api/profile';

const UserDetails = () => {
  const { id: initialId } = useParams<{ id: string }>();

  const { setIsLoading } = useContext(ActivityIndicatorContext);
  const { showErrorToast } = useContext(ToastContext);

  const [profile, setProfile] = useState<Profile | undefined>();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery({
    queryKey: [`${initialId}-cases`],
    queryFn: () => getLatestCases(profile?.caseIds || []),
    enabled: !!profile,
  });

  const isLaptop = useMediaQuery({
    query: createMediaQuery(MEDIA_QUERY_BREAKPOINTS.smallLaptop),
  });

  const searchProps = useFormTextState();
  const addCaseModalProps = useModalState<boolean>();
  const { exportToExcel } = useCasesExport();

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  const refreshProfile = async () => {
    if (initialId) {
      setIsLoading(true);
      const result = await getUserById(initialId);
      const resultError = isProjectError(result);
      if (resultError) {
        showErrorToast(resultError);
      } else {
        setProfile(result as Profile);
      }
      setIsLoading(false);
    } else {
      showErrorToast(getProjectErrorForCode(ProjectErrors.genericTryAgain));
    }
  };

  useEffect(() => {
    refreshProfile().then();
  }, []);

  const onExportExcel = async () => {
    if (profile) {
      setIsLoading(true);
      const result = await getAllCases(profile?.caseIds);
      setIsLoading(false);
      if (result && Array.isArray(result)) {
        exportToExcel(result);
      } else {
        showErrorToast(getProjectErrorForCode(ProjectErrors.couldNotExportExcel));
      }
    } else {
      showErrorToast(getProjectErrorForCode(ProjectErrors.couldNotExportExcel));
    }
  };

  const onAddCase = () => {
    addCaseModalProps.open(true);
  };

  const onDeleteCases = async () => {
    if (profile) {
      setIsLoading(true);
      await deleteCasesForUser(profile?.id);
      await queryClient.invalidateQueries({ queryKey: [`${initialId}-cases`] });
      refreshProfile().then();
      setIsLoading(false);
    } else {
      showErrorToast(getProjectErrorForCode(ProjectErrors.genericTryAgain));
    }
  };

  const onSaveCases = async (numbers: string[]) => {
    if (profile) {
      setIsLoading(true);
      const result = await saveMultipleCasesFromPortal(profile?.id, numbers);
      setIsLoading(false);
      if ('success' in result && result?.success) {
        refreshProfile().then();
        await queryClient.invalidateQueries({ queryKey: [`${initialId}-cases`] });
      }
    } else {
      showErrorToast(getProjectErrorForCode(ProjectErrors.genericTryAgain));
    }
  };

  const onToggleTester = async () => {
    if (initialId) {
      const newValue = profile?.isTester !== true;
      setIsLoading(true);
      const result = await updateUser({ userId: initialId, isTester: newValue });
      setIsLoading(false);
      const resultError = isProjectError(result);
      if (resultError) {
        showErrorToast(resultError);
      } else {
        refreshProfile().then();
      }
    }
  };

  const onViewCaseDetails = (id: string) => {
    navigate(`/dosare/${id}`);
  };

  const _createdAt = profile?.createdAt
    ? parseDateToStringWithFormat(profile?.createdAt, format.monthDayYearTime)
    : undefined;
  const _updatedAt = profile?.updatedAt
    ? parseDateToStringWithFormat(profile?.updatedAt, format.monthDayYearTime)
    : undefined;
  const _filteringModel = filteringModel([
    { columnField: 'searchValue', operatorValue: 'contains', value: searchProps.value },
  ]);
  return (
    <ProjectContainer hasBack>
      <Components.Container isLaptop={isLaptop}>
        <Components.HeaderContainer>
          <Components.HeaderLeftSideWrapper>
            <Components.HeaderTitleWrapper>
              {profile?.fullName && <Components.HeaderTitle>{profile?.fullName}</Components.HeaderTitle>}
            </Components.HeaderTitleWrapper>
            <Components.HeaderDetailsWrapper>
              {_createdAt && (
                <Components.HeaderDetailsLabel>Adăugat la data de {_createdAt}</Components.HeaderDetailsLabel>
              )}
              {_updatedAt && (
                <Components.HeaderDetailsLabel>Actualizat ultima dată pe {_updatedAt}</Components.HeaderDetailsLabel>
              )}
            </Components.HeaderDetailsWrapper>
          </Components.HeaderLeftSideWrapper>
          <Components.HeaderRightSideWrapper>
            <Components.Checkbox checked={profile?.isTester || false} onClick={onToggleTester} />
            <Components.CheckboxLabel>Tester</Components.CheckboxLabel>
          </Components.HeaderRightSideWrapper>
        </Components.HeaderContainer>
        <Components.BodyContainer>
          <Components.ButtonsWrapper>
            <Components.RedSecondaryButton onClick={onDeleteCases}>Șterge dosarele</Components.RedSecondaryButton>
            <Components.SecondaryButton onClick={onExportExcel}>
              Exportă Excel - toate dosarele
            </Components.SecondaryButton>
            <Components.SecondaryButton onClick={onAddCase}>Adaugă dosare</Components.SecondaryButton>
          </Components.ButtonsWrapper>
          {/*<TomorrowCases cases={data || []} />*/}
          <ProjectTableHeader
            onSearchChange={searchProps.onChangeText}
            searchLabel={'Caută dosarele utilizatorului'}
            searchPlaceholder={'Introdu numărul dosarului sau instanța de judecată'}
          />
          <ProjectTable
            data={profile?.cases || []}
            columns={tableColumns.casesPreview({ onViewDetails: onViewCaseDetails })}
            filteringModel={_filteringModel}
          />
        </Components.BodyContainer>
      </Components.Container>
      <AddCasesModal {...addCaseModalProps} onSave={onSaveCases} />
    </ProjectContainer>
  );
};

export default UserDetails;
