import styled from 'styled-components';
import AppCSS from '../../../../utils/styled';

export const Components = {
  Container: styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    background-color: ${AppCSS.colors.white};
    ${AppCSS.boxShadows.block};
  `,
  EmptyStateWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin: 32px 0;
  `,
  EmptyStateLabel: styled.span`
    ${AppCSS.fonts.label16Regular};
    color: ${AppCSS.colors.greens.green80};
  `,
  CaseContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 8px;
    justify-content: center;
    ${AppCSS.radiuses.small};
    border: 1px solid ${AppCSS.colors.greens.green50};
  `,
  CaseHeaderWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
  CaseHeaderNumberWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  `,
  CaseNumber: styled.span`
    ${AppCSS.fonts.label16Bold};
    color: ${AppCSS.colors.primaryGreenGoth};
  `,
  CaseMatter: styled.span`
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green80};
  `,
  PartiesWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    flex-wrap: wrap;
  `,
  PartyWrapper: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    ${AppCSS.radiuses.small};
    border: 1px solid ${AppCSS.colors.greens.green50};
    gap: 4px;
    padding: 4px 8px;
  `,
  PartyIconWrapper: styled.div`
    min-width: max-content;
  `,
  PartyName: styled.span`
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green70};
  `,
  LocationWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
  `,
  LocationName: styled.span`
    ${AppCSS.fonts.label10Regular};
    color: ${AppCSS.colors.greens.green70};
  `,
  SaveToListButton: styled(AppCSS.buttonStyles.TertiaryButtonStyle)<{ isMobile: boolean }>`
    ${(p) => p.isMobile && 'margin-top: 8px;'};
  `,
  ViewMoreButton: styled(AppCSS.buttonStyles.TertiaryButtonStyle)``,
};
