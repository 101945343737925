import styled from 'styled-components';
import AppCSS from '../../utils/styled';

export const Components = {
  Container: styled.div<{ height: number | string }>`
    width: 100%;
    display: flex;
    justify-content: center;
    height: ${({ height }) => (typeof height === 'string' ? height : `${height}px`)};
    align-items: center;
  `,

  Label: styled.span`
    text-align: center;
    ${AppCSS.fonts.label14Regular}
    color: ${AppCSS.colors.greens.green80};
  `,
};
