import { CreateCheckoutSessionDto } from '../typeDtos/subscription';
import apiFetch from '../index';
import { ENDPOINTS } from '../endpoints';
import { getProjectErrorFromRawError } from '../../utils/errors';
import { ProjectErrorType } from '../../utils/errors/types';

export const createCheckoutSession = async (dto: CreateCheckoutSessionDto): Promise<string | ProjectErrorType> => {
  try {
    const result = await apiFetch({ method: 'POST', path: ENDPOINTS.subscription.createCheckoutSession, body: dto });
    return result?.redirectUrl;
  } catch (error) {
    console.log('Error at createCheckoutSession', error);
    return getProjectErrorFromRawError(error);
  }
};

export const createCustomerPortalSession = async (customerId: string): Promise<string | ProjectErrorType> => {
  try {
    const result = await apiFetch({
      method: 'POST',
      path: ENDPOINTS.subscription.createCustomerPortalSession,
      body: { customerId },
    });
    return result?.redirectUrl;
  } catch (error) {
    console.log('Error at createCustomerPortalSession', error);
    return getProjectErrorFromRawError(error);
  }
};
