import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Components } from './styled';
import ProjectTextField from '../../../../components/inputs/ProjectTextField';
import useFormTextState from '../../../../hooks/useFormTextState';
import useFormSelectState from '../../../../hooks/useFormSelectState';
import ProjectSelect from '../../../../components/inputs/ProjectSelect';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/icon-search.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/icon-angle-down-alt.svg';
import { ReactComponent as ArrowUp } from '../../../../assets/icons/icon-angle-up-alt.svg';
import { getCasesFromPortal } from '../../../../api/portal';
import { ActivityIndicatorContext } from '../../../../providers/ActivityIndicatorProvider/context';
import { InstitutionType, PortalCase } from '../../../../api/types/portal';
import { InstitutionEnum } from '../../../../api/portal/enums';
import { useMediaQuery } from 'react-responsive';
import { MEDIA_QUERY_BREAKPOINTS } from '../../../../utils/responsive/constants';
import { createMediaQuery } from '../../../../utils/responsive';
import { CASE_NUMBER_EXAMPLE } from '../../../../defines';

const PortalSearch = ({ setData }: { setData: Dispatch<SetStateAction<PortalCase[]>> }) => {
  const { setIsLoading } = useContext(ActivityIndicatorContext);

  const [showFilters, setShowFilters] = useState(false);

  const isTablet = useMediaQuery({
    query: createMediaQuery(MEDIA_QUERY_BREAKPOINTS.smallLaptop, true),
  });

  const caseNumberProps = useFormTextState();
  const casePartyProps = useFormTextState();
  const caseMatterProps = useFormTextState();
  const instanceProps = useFormSelectState();

  const clearStates = () => {
    caseNumberProps.setValue(undefined);
    casePartyProps.setValue(undefined);
    caseMatterProps.setValue(undefined);
    instanceProps.setValue(undefined);
  };

  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
    clearStates();
  };

  const onSearchPress = async () => {
    if (caseNumberProps.value || caseMatterProps.value || casePartyProps.value || instanceProps.value) {
      setIsLoading(true);
      const result = await getCasesFromPortal({
        number: caseNumberProps.value?.trim(),
        matter: caseMatterProps.value?.trim(),
        partyName: casePartyProps.value?.trim(),
        institution: instanceProps.value,
      });
      setIsLoading(false);
      if (result && Array.isArray(result)) {
        setData(result);
      }
    }
    clearStates();
  };

  return (
    <Components.Container>
      <Components.SearchBarContainer isTablet={isTablet}>
        <Components.TextFieldsContainer>
          <Components.TextFieldWrapper>
            <Components.TextFieldLabel>
              Caută după numărul dosarului (exemplu: {CASE_NUMBER_EXAMPLE})
            </Components.TextFieldLabel>
            <ProjectTextField
              value={caseNumberProps.value}
              onChangeText={caseNumberProps.onChangeText}
              placeholder={'Introduceți numărul dosarului'}
            />
          </Components.TextFieldWrapper>
          {showFilters && (
            <>
              <Components.TextFieldWrapper>
                <Components.TextFieldLabel>Numele părții</Components.TextFieldLabel>
                <ProjectTextField
                  value={casePartyProps.value}
                  onChangeText={casePartyProps.onChangeText}
                  placeholder={'Introduceți numele părții'}
                />
              </Components.TextFieldWrapper>
              <Components.TextFieldWrapper>
                <Components.TextFieldLabel>Obiect dosar</Components.TextFieldLabel>
                <ProjectTextField
                  value={caseMatterProps.value}
                  onChangeText={caseMatterProps.onChangeText}
                  placeholder={'Introduceți obiectul dosar'}
                />
              </Components.TextFieldWrapper>
              <Components.TextFieldWrapper>
                <Components.TextFieldLabel>Instanța de judecată</Components.TextFieldLabel>
                <ProjectSelect
                  value={instanceProps.value}
                  onChangeSelectedItem={instanceProps.onChangeSelectedItem}
                  placeholder={'Selectați instanța de judecată'}
                  processShownLabel={(value: InstitutionType) => InstitutionEnum[value]}>
                  {Object.keys(InstitutionEnum)?.map((item, key) => (
                    <Components.MenuItem key={key} value={item}>
                      {InstitutionEnum[item as InstitutionType]}
                    </Components.MenuItem>
                  ))}
                </ProjectSelect>
              </Components.TextFieldWrapper>
            </>
          )}
        </Components.TextFieldsContainer>
        <Components.FiltersButton onClick={toggleShowFilters} isTablet={isTablet}>
          {showFilters ? <ArrowUp /> : <ArrowDown />}
          Căutare detaliată
        </Components.FiltersButton>
        <Components.SearchButton onClick={onSearchPress} isTablet={isTablet}>
          <SearchIcon />
          Caută
        </Components.SearchButton>
      </Components.SearchBarContainer>
    </Components.Container>
  );
};

export default PortalSearch;
