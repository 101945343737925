import { Components } from './styled';
import React from 'react';

const MultilineText = ({ text }: { text: string }) => {
  return (
    <Components.Container>
      <Components.Text>{text && text.length ? text : '-'}</Components.Text>
    </Components.Container>
  );
};

export default MultilineText;
