import React, { useContext, useEffect } from 'react';
import { Components } from './styled';
import { UIProviderContext } from '../../providers/UIProvider/context';

const ProjectSimpleModal = ({
  bodyChildren,
  isModalOpen,
  onModalClose,
  scrollType,
  modalWidth,
  disableCloseOnClickAway,
}: {
  bodyChildren: React.ReactNode;
  isModalOpen: boolean;
  onModalClose?: () => void;
  scrollType?: 'body' | 'paper';
  modalWidth?: number | 'auto';
  disableCloseOnClickAway?: boolean;
}) => {
  const { setIsModalOpen } = useContext(UIProviderContext);

  useEffect(() => {
    if (isModalOpen) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [isModalOpen]);

  const _onModalClose = () => {
    onModalClose?.();
  };

  return (
    <Components.Modal
      open={isModalOpen}
      onClose={(event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (!(disableCloseOnClickAway && reason === 'backdropClick')) {
          _onModalClose?.();
        }
      }}
      scroll={scrollType}
      modalWidth={modalWidth}>
      <Components.ModalContentContainer>{bodyChildren}</Components.ModalContentContainer>
    </Components.Modal>
  );
};

export default ProjectSimpleModal;
