import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Components } from './styled';
import { PortalCase } from '../../../../api/types/portal';
import { ReactComponent as EmptyStateIllustration } from '../../../../assets/illustrations/illustration-cases-empty-state.svg';
import SecondaryTag from '../../../../components/tags/SecondaryTag';
import { ReactComponent as PinIcon } from '../../../../assets/icons/icon-pin.svg';
import { ReactComponent as SaveIcon } from '../../../../assets/icons/icon-save.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/icon-check-alt.svg';
import { ReactComponent as AngleDownIcon } from '../../../../assets/icons/icon-angle-down-alt.svg';
import { ReactComponent as AngleUpIcon } from '../../../../assets/icons/icon-angle-up-alt.svg';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { MEDIA_QUERY_BREAKPOINTS } from '../../../../utils/responsive/constants';
import { createMediaQuery } from '../../../../utils/responsive';
import Parties from '../../../../components/caseComponents/Parties';
import { JustCaseHearings } from '../../../../components/caseComponents/CaseHearings';
import { SessionContext } from '../../../../providers/SessionProvider/context';

const _PortalCase = ({
  caseData,
  onSaveCase,
  isCaseSaved,
}: {
  caseData: PortalCase;
  onSaveCase: (value: PortalCase) => void;
  isCaseSaved: boolean;
}) => {
  const [showHearings, setShowHearings] = useState(false);

  const isMobile = useMediaQuery({
    query: createMediaQuery(MEDIA_QUERY_BREAKPOINTS.largeMobile, true),
  });

  const toggleShowHearings = () => {
    setShowHearings(!showHearings);
  };

  return (
    <Components.CaseContainer>
      <Components.CaseHeaderWrapper>
        <Components.CaseHeaderNumberWrapper>
          <Components.CaseNumber>{caseData?.displayNumber}</Components.CaseNumber>
          {caseData?.displayProceduralStage && <SecondaryTag text={caseData?.displayProceduralStage} />}
        </Components.CaseHeaderNumberWrapper>
        <MediaQuery minWidth={MEDIA_QUERY_BREAKPOINTS.largeMobile}>
          <Components.SaveToListButton
            onClick={() => onSaveCase?.(caseData)}
            disabled={isCaseSaved}
            isMobile={isMobile}>
            {isCaseSaved ? (
              <>
                <CheckIcon />
                Adăugat
              </>
            ) : (
              <>
                <SaveIcon />
                Salvează în lista ta
              </>
            )}
          </Components.SaveToListButton>
        </MediaQuery>
      </Components.CaseHeaderWrapper>
      {caseData?.parties && caseData?.parties?.length > 0 && <Parties parties={caseData?.parties} />}
      {caseData?.matter && <Components.CaseMatter>{caseData?.matter}</Components.CaseMatter>}
      {caseData?.displayInstitution && (
        <Components.LocationWrapper>
          <PinIcon />
          <Components.LocationName>{caseData?.displayInstitution}</Components.LocationName>
        </Components.LocationWrapper>
      )}
      <MediaQuery maxWidth={MEDIA_QUERY_BREAKPOINTS.largeMobile}>
        <Components.SaveToListButton onClick={() => onSaveCase?.(caseData)} disabled={isCaseSaved} isMobile={isMobile}>
          {isCaseSaved ? (
            <>
              <CheckIcon />
              Adăugat
            </>
          ) : (
            <>
              <SaveIcon />
              Salvează în lista ta
            </>
          )}
        </Components.SaveToListButton>
      </MediaQuery>
      <Components.ViewMoreButton onClick={toggleShowHearings}>
        {showHearings ? (
          <>
            <AngleUpIcon />
            Ascunde termenele
          </>
        ) : (
          <>
            <AngleDownIcon />
            Vezi termenele
          </>
        )}
      </Components.ViewMoreButton>
      {showHearings && <JustCaseHearings hearings={caseData?.hearings} />}
    </Components.CaseContainer>
  );
};

const PortalCases = ({
  data,
  isCaseAlreadyAdded,
  onSaveCase,
}: {
  data: PortalCase[];
  isCaseAlreadyAdded: (item: PortalCase) => boolean;
  onSaveCase: (value: PortalCase) => void;
}) => {
  return (
    <Components.Container>
      {data && data?.length > 0 ? (
        data?.map((item, key) => {
          const isCaseSaved = isCaseAlreadyAdded?.(item);
          return <_PortalCase key={key} caseData={item} onSaveCase={onSaveCase} isCaseSaved={isCaseSaved} />;
        })
      ) : (
        <Components.EmptyStateWrapper>
          <EmptyStateIllustration />
          <Components.EmptyStateLabel>Rezultatele căutării tale vor apărea aici</Components.EmptyStateLabel>
        </Components.EmptyStateWrapper>
      )}
    </Components.Container>
  );
};

export default PortalCases;
