import React, { useState, useContext, useEffect } from 'react';
import { Components } from './styled';
import ProjectContainer from '../../components/containers/ProjectContainer';
import { SubscriptionContext } from '../../providers/SubsctiptionProvider/context';
import { PRODUCT_PRICE_ID } from '../../defines';
import { ToastContext } from '../../providers/ToastProvider/context';
import { getProjectErrorForCode, isProjectError } from '../../utils/errors';
import { ProjectErrors } from '../../utils/errors/types';
import { ActivityIndicatorContext } from '../../providers/ActivityIndicatorProvider/context';
import { useMediaQuery } from 'react-responsive';
import { createMediaQuery } from '../../utils/responsive';
import { MEDIA_QUERY_BREAKPOINTS } from '../../utils/responsive/constants';
import { SessionContext } from '../../providers/SessionProvider/context';
import { useQuery } from '../../router';
import AccountTabs from './components/AccountTabs';
import ProfileTab from './tabs/ProfileTab';
import SecurityTab from './tabs/NotificationsTab';
import BillingTab from './tabs/BillingTab';
import { useNavigate } from 'react-router-dom';
import { MY_ACCOUNT_TABS } from './constants';
import NewSubscriptionModal from '../../components/modals/NewSubscriptionModal';
import useModalState from '../../hooks/useModalState';

const Profile = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const stripeSessionId = query.get('stripe_session_id') as string | undefined;
  const queryTab = query.get('tab') as string | undefined;
  // @ts-ignore
  const initialTab = queryTab ? MY_ACCOUNT_TABS[queryTab]?.index : undefined;

  const { refreshProfile } = useContext(SessionContext);
  const { createCustomerPortalSession } = useContext(SubscriptionContext);
  const { showErrorToast } = useContext(ToastContext);
  const { setIsLoading } = useContext(ActivityIndicatorContext);

  const [tab, setTab] = useState(initialTab || 0);

  const susscesfulSubModalProps = useModalState<boolean>();

  const isMobile = useMediaQuery({
    query: createMediaQuery(MEDIA_QUERY_BREAKPOINTS.largeMobile, true),
  });

  useEffect(() => {
    if (stripeSessionId) {
      susscesfulSubModalProps.open(true);
      refreshProfile().then();
    }
  }, []);

  const onBuySubPress = () => {
    navigate('/abonamente');
  };

  const onCustomerPortalPress = async () => {
    setIsLoading(true);
    const redirectUrl = await createCustomerPortalSession();
    setIsLoading(false);
    const redirectUrlError = isProjectError(redirectUrl);
    if (redirectUrlError) {
      showErrorToast(redirectUrlError);
    } else if (redirectUrl && (redirectUrl as string)?.length > 0) {
      window.location.href = redirectUrl as string;
    } else {
      showErrorToast(getProjectErrorForCode(ProjectErrors.genericTryAgain));
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, value: number | string) => {
    const _value = typeof value === 'number' ? value : 0;
    setTab(_value);
  };

  return (
    <ProjectContainer title={'Profil'}>
      <Components.Container isOnMobile={isMobile}>
        <Components.BlockContainer>
          <Components.Tabs onChange={handleTabChange} value={tab}>
            <AccountTabs />
            <ProfileTab tab={tab} />
            <SecurityTab tab={tab} />
            <BillingTab tab={tab} onBuySub={onBuySubPress} onCustomerPortalPress={onCustomerPortalPress} />
          </Components.Tabs>
        </Components.BlockContainer>
      </Components.Container>
      <NewSubscriptionModal {...susscesfulSubModalProps} />
    </ProjectContainer>
  );
};

export default Profile;
