import styled from 'styled-components';
import AppCSS from '../../utils/styled';
import { SIDEBAR_WIDTH } from '../../utils/styled/dimensions';

export const Components = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: ${SIDEBAR_WIDTH}px;
    height: 100%;
    min-height: 100vh;
    background-color: ${AppCSS.colors.primaryMatteBlack};
    padding: 24px;
    z-index: 99;
    overflow-x: hidden;
    overflow-y: auto;
  `,
  LogoContainer: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    cursor: pointer;
  `,
  InnerContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    overflow-y: auto;
    margin-top: 32px;
    border-top: 1px solid ${AppCSS.colors.greens.green50};
    padding-top: 16px;
  `,
  VersionLabel: styled.span`
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green60};
    margin-top: auto;
  `,
  Separator: styled.div`
    width: 100%;
    height: 1px;
    background-color: ${AppCSS.colors.greens.green80};
  `,
};
