import { CalendarConfigurationType } from './types';
import { CalendarEvents } from '../../../api/types/calendar';

export const DAY_TILE_WIDTH_SMALL = 32;
export const DAY_TILE_HEIGHT_SMALL = 32;
export const DAY_TILE_WIDTH_BIG = 40;
export const DAY_TILE_HEIGHT_BIG = 40;

export const calendarConfigs = {
  events: (
    value: Date | null,
    onValueChange: (value: Date | null) => void,
    events: CalendarEvents,
    activeStartDate: Date,
    isMinified?: boolean,
  ): CalendarConfigurationType => ({
    value,
    onValueChange,
    activeStartDate,
    events,
    isMinified,
    variant: 'events',
  }),
  picker: (
    value: Date | null,
    onValueChange: (value: Date | null) => void,
    minDate: Date | undefined,
    maxDate: Date | undefined,
  ): CalendarConfigurationType => ({
    value,
    onValueChange,
    minDate,
    maxDate,
    variant: 'picker',
    navigationType: 'default',
  }),
};
