import styled from 'styled-components';
import AppCSS from '../../../../utils/styled';

export const Components = {
  Container: styled.div`
    cursor: pointer;
  `,
  Text: styled.span`
    ${AppCSS.fonts.label14Regular};
    color: ${AppCSS.colors.greens.green80};
  `,
};
