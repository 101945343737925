import styled from 'styled-components';
import AppCSS from '../../utils/styled';

export const Components = {
  Container: styled.div<{ isOnMobile: boolean }>`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px ${(p) => (p.isOnMobile ? 16 : 24)}px;
    background-color: ${AppCSS.colors.utility.screenBackground};
  `,
  WideColumnContainer: styled.div`
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  `,
  SmallColumnContainer: styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  `,
};
