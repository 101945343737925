import _ from 'lodash';
import { parseDateToStringWithFormat } from '../utils/dateFormat';
import { format } from '../utils/dateFormat/types';
import MobileDetect from 'mobile-detect';

export const generateDeviceId = (): string => {
  const validChars = '0123456789';
  return _.sampleSize(validChars, 10).concat(_.sampleSize(validChars, 9)).join('');
};

export const getAnalyticsDateFormat = (date: Date): string => {
  return parseDateToStringWithFormat(date, format.dayMonthYearAlternative);
};

export const getDevicePlatform = () => {
  const userAgent = navigator.userAgent;
  const md = new MobileDetect(userAgent);

  let operatingSystem = 'Unknown';

  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'Mac'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE', 'Win'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (macosPlatforms.some((p) => md.match(p))) {
    operatingSystem = 'MacOS';
  } else if (iosPlatforms.some((p) => md.match(`/${p}/i`)) || md.match(/ios/i)) {
    operatingSystem = 'iOS';
  } else if (windowsPlatforms.some((p) => md.match(`/${p}/i`))) {
    operatingSystem = 'Windows';
  } else if (md.match(/android/i)) {
    operatingSystem = 'Android';
  } else if (md.match(/linux/i)) {
    operatingSystem = 'Linux';
  }

  let result = 'web';
  if (operatingSystem === 'iOS') {
    result = 'iOS';
  } else if (operatingSystem === 'Android') {
    result = 'Android';
  }

  return result?.toLowerCase();
};
