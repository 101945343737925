import React, { Dispatch, SetStateAction } from 'react';

export interface ActivityIndicatorContextType {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const ActivityIndicatorContext = React.createContext<ActivityIndicatorContextType>({
  isLoading: false,
  setIsLoading: () => {
    return;
  },
});
