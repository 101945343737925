import React from 'react';
import { Components } from './styled';

const CaseNumber = ({ params, onPress }: { params: any; onPress?: (id: string) => void }) => {
  const { row }: { row: any } = params;
  const caseNumber = row?.caseNumber;

  const _onPress = () => {
    onPress?.(row?.id);
  };

  return (
    <Components.Container onClick={_onPress}>
      <Components.Text>{caseNumber}</Components.Text>
    </Components.Container>
  );
};

export default CaseNumber;
