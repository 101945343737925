import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from 'firebase/auth';
import { auth } from '../../firebase';
import { ProjectErrors, ProjectErrorType } from '../../utils/errors/types';
import { UserCredential } from '@firebase/auth';
import { getProjectErrorForCode, getProjectErrorFromRawError } from '../../utils/errors';
import apiFetch from '..';
import { ENDPOINTS } from '../endpoints';

export const signUpWithEmail = async (email: string, password: string): Promise<UserCredential | ProjectErrorType> => {
  try {
    return await createUserWithEmailAndPassword(auth, email, password);
  } catch (error) {
    const typedError = error as { code: string };
    if (typedError?.code === 'auth/email-already-in-use') {
      console.log('That email address is already in use!');
      return getProjectErrorForCode(ProjectErrors.signupEmailInUse);
    }

    if (typedError?.code === 'auth/account-exists-with-different-credential') {
      console.log('An account already exists with the same email address but different sign-in credentials.');
      return getProjectErrorForCode(ProjectErrors.authExistingAccount);
    }

    if (typedError?.code === 'auth/invalid-email') {
      console.log('That email address is invalid!');
      return getProjectErrorForCode(ProjectErrors.signupInvalidEmail);
    }

    return getProjectErrorFromRawError(error as Error);
  }
};

export const loginWithEmail = async (email: string, password: string): Promise<UserCredential | ProjectErrorType> => {
  try {
    return await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    const typedError = error as { code: string };
    if (typedError?.code === 'auth/invalid-email') {
      console.log('That email address is invalid!');
      return getProjectErrorForCode(ProjectErrors.signupInvalidEmail);
    }

    if (typedError?.code === 'auth/account-exists-with-different-credential') {
      console.log('An account already exists with the same email address but different sign-in credentials.');
      return getProjectErrorForCode(ProjectErrors.authExistingAccount);
    }

    if (typedError?.code === 'auth/user-not-found') {
      console.log('User not found!');
      return getProjectErrorForCode(ProjectErrors.loginUserNotFound);
    }

    if (typedError?.code === 'auth/wrong-password') {
      console.log('Wrong password!');
      return getProjectErrorForCode(ProjectErrors.loginWrongPassword);
    }

    return getProjectErrorFromRawError(error as Error);
  }
};

export const sendPhoneVerificationCode = async (
  authHeaders: { [_: string]: string },
  phoneNumber: string,
  phoneNumberCode: string,
) => {
  try {
    return await apiFetch({
      path: ENDPOINTS.messaging.sendPhoneVerificationCode,
      method: 'POST',
      body: { phoneNumber, phoneNumberCode },
      headers: authHeaders,
    });
  } catch (error) {
    console.log('[sendPhoneVerificationCode] Error:', error);
    return getProjectErrorFromRawError(error as Error);
  }
};

export const verifyPhoneNumber = async (
  authHeaders: { [_: string]: string },
  userUid: string,
  phoneNumber: string,
  phoneNumberCode: string,
  smsCode: string,
) => {
  try {
    return await apiFetch({
      path: ENDPOINTS.messaging.verifyPhoneNumber,
      method: 'POST',
      body: { userUid, phoneNumber, phoneNumberCode, code: smsCode },
      headers: authHeaders,
    });
  } catch (error) {
    console.log('[verifyPhoneNumber] Error:', error);
    return getProjectErrorFromRawError(error as Error);
  }
};

export const changePassword = async (newPassword: string): Promise<ProjectErrorType | undefined> => {
  try {
    if (auth.currentUser) {
      await updatePassword(auth.currentUser, newPassword);
      return;
    }
    return getProjectErrorForCode(ProjectErrors.changePassword);
  } catch (error) {
    const typedError = error as { code: string };
    if (typedError.code === 'auth/requires-recent-login') {
      console.log('Requires recent login.');
      return getProjectErrorForCode(ProjectErrors.signupInvalidEmail);
    }
    console.log('[resetPassword] Error:', error);
    return getProjectErrorFromRawError(error as Error);
  }
};

export const sendPasswordRecoverEmail = async (email: string): Promise<void> => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {}
};

export const firebaseLogout = async (): Promise<void> => {
  try {
    await signOut(auth);
  } catch (error) {}
};
