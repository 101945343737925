import styled from 'styled-components';
import AppCSS from '../../utils/styled';
import MenuItem from '@mui/material/MenuItem';

export const Components = {
  Container: styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 24px;
    background-color: ${AppCSS.colors.utility.screenBackground};
    gap: 32px;
  `,
  CaseTableWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    ${AppCSS.boxShadows.block};
  `,
  FieldWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    width: 100%;
    max-width: 320px;
  `,
  FieldLabel: styled.span`
    ${AppCSS.fonts.label12Bold};
    color: ${AppCSS.colors.greens.green70};
  `,
  MenuItem: styled(MenuItem)`
    padding: 8px;
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  SearchBarChildrenWrapper: styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 24px;
    margin-left: 8px;
    height: 100%;
  `,
  PrimaryButtonAdjacentLabel: styled.span`
    text-transform: uppercase;
    ${AppCSS.fonts.h3};
    color: ${AppCSS.colors.greens.green70};
    margin-bottom: 5px;
  `,
  PrimaryButton: styled(AppCSS.buttonStyles.PrimaryButtonWithIconStyle)``,
  TableHeaderWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
  SecondaryButton: styled(AppCSS.buttonStyles.SecondaryButtonStyle)``,
  TabsWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};
