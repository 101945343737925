import styled from 'styled-components';
import AppCSS from '../../../utils/styled';

export const Components = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    padding: 24px 16px;
    background-color: ${AppCSS.colors.white};
    ${AppCSS.boxShadows.block};
    overflow: hidden;
    height: min-content;
  `,
  HeaderContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid ${AppCSS.colors.utility.borders};
    width: 100%;
  `,
  HeaderTitle: styled.span`
    ${AppCSS.fonts.label16Medium};
    color: ${AppCSS.colors.greens.green90};
  `,
  BodyContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
  `,
  HearingContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    padding: 12px;
    background-color: ${AppCSS.colors.greens.green40};
  `,
  HearingHeaderContainer: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
  `,
  ClockWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 3px;
  `,
  HearingHeaderTitle: styled.span`
    color: ${AppCSS.colors.primaryMatteBlack};
    ${AppCSS.fonts.h3};
  `,
  HearingSolution: styled.span`
    ${AppCSS.fonts.label14Regular};
    color: ${AppCSS.colors.greens.green90};
  `,
  HearingPanelLabel: styled.span`
    ${AppCSS.fonts.label14Regular};
    color: ${AppCSS.colors.greens.green80};
  `,
  HearingSolutionSummary: styled.span`
    ${AppCSS.fonts.paragraph12Regular};
    color: ${AppCSS.colors.greens.green70};
  `,
};
