import styled from 'styled-components';
import AppCSS from '../../../utils/styled';

export const Components = {
  Container: styled.div`
    background-color: ${AppCSS.colors.white};
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
  `,
};
