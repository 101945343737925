import React, { useContext, useEffect, useState } from 'react';
import { Components } from './styled';
import ProjectTableHeader from '../../../components/ProjectTableHeader';
import ProjectTable from '../../../components/ProjectTable';
import { filteringModel, tableColumns } from '../../../components/ProjectTable/constants';
import ProjectContainer from '../../../components/containers/ProjectContainer';
import { useNavigate } from 'react-router-dom';
import { ActivityIndicatorContext } from '../../../providers/ActivityIndicatorProvider/context';
import useFormTextState from '../../../hooks/useFormTextState';
import { Profile } from '../../../api/types/profile';
import { getAllUsers } from '../../../api/users';
import { useQuery } from '@tanstack/react-query';

const Users = () => {
  const navigate = useNavigate();

  const { setIsLoading } = useContext(ActivityIndicatorContext);

  const searchProps = useFormTextState();
  const { isLoading, data } = useQuery({ queryKey: ['users'], queryFn: getAllUsers });

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  const onViewProfileDetails = (id: string) => {
    navigate(`/users/${id}`);
  };

  const _filteringModel = filteringModel([
    { columnField: 'searchValue', operatorValue: 'contains', value: searchProps.value },
  ]);
  return (
    <ProjectContainer title={'Utilizatori'}>
      <Components.Container>
        <ProjectTableHeader
          onSearchChange={searchProps.onChangeText}
          searchLabel={'Caută după ID-ul de pe Firebase, după numele utilizatorului, sau adresa de e-mail'}
          searchPlaceholder={'Introduceți termenul de căutare'}
        />
        <Components.CaseTableWrapper>
          <ProjectTable
            data={data || []}
            columns={tableColumns.users({ onViewDetails: onViewProfileDetails })}
            filteringModel={_filteringModel}
          />
        </Components.CaseTableWrapper>
      </Components.Container>
    </ProjectContainer>
  );
};

export default Users;
