import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import styled from 'styled-components';
import AppCSS from '../../utils/styled';
import { Pagination, TextField } from '@mui/material';

export const Components = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  `,
  TableWrapper: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  `,
  Table: styled(DataGrid)`
    border: none !important;
    ${AppCSS.fonts.label14Regular};
    color: ${AppCSS.colors.primaryMatteBlack} !important;
    & .MuiDataGrid-main {
      border: none !important;
      overflow: hidden;
    }
    & .MuiDataGrid-cell {
      background-color: ${AppCSS.colors.white};
      ${AppCSS.fonts.label14Regular};
      color: ${AppCSS.colors.primaryMatteBlack};
      border-bottom: 1px solid ${AppCSS.colors.greens.green50} !important;
      &:focus-within,
      &:focus {
        outline: none !important;
      }
    }
    & .MuiDataGrid-checkboxInput {
      color: ${AppCSS.colors.greens.green50};
      width: 20px;
      height: 20px;
    }
    & .Mui-checked {
      color: ${AppCSS.colors.primaryGreenGoth} !important;
    }
    & .MuiDataGrid-columnHeader {
      ${AppCSS.fonts.label10Regular};
      color: ${AppCSS.colors.greens.green70};
      background-color: transparent;
    }

    & .MuiDataGrid-columnsContainer {
      border: none;
      flex-direction: row !important;
    }
    & .MuiDataGrid-columnHeaders {
      border: none;
      background-color: ${AppCSS.colors.utility.table.headerBackground};
    }
    & .MuiDataGrid-iconSeparator {
      display: none;
    }
    & .MuiDataGrid-columnHeader {
      &:focus-within,
      &:focus {
        outline: none !important;
      }
    }
    & .MuiDataGrid-row:last-child > .MuiDataGrid-cell {
      border-bottom: 0 !important;
    }
    & .MuiDataGrid-row:hover > div {
      background-color: ${AppCSS.colors.utility.headerBar.background};
    }
  `,

  TablePaginationContainer: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    background-color: ${AppCSS.colors.white};
    padding: 0 8px;
    margin-top: 8px;
  `,
  PaginationLeftContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
  `,
  PaginationText: styled.span`
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green70};
  `,

  PaginationRightContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  TablePagination: styled(Pagination)`
    & .MuiPaginationItem-outlined {
      color: ${AppCSS.colors.greens.green60};
      border: none;
      &:hover {
        background-color: ${AppCSS.colors.greens.green50};
      }
    }
    .Mui-selected {
      color: ${AppCSS.colors.primaryGreenGoth};
      border: 1px solid ${AppCSS.colors.primaryGreenGoth};
      background-color: transparent !important;
    }
  `,
  PaginationNavigation: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    gap: 10px;
  `,
  PaginationNavigationButton: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 8px;
    &:hover {
      cursor: pointer;
    }
  `,
  PaginationNavigationButtonText: styled.span`
    ${AppCSS.fonts.label12Bold};
    color: ${AppCSS.colors.primaryGreenGoth};
    margin-right: 4px;
  `,
  NavigationIconWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
  `,
  NavigationText: styled.span`
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green70};
  `,
  NavigationInput: styled(TextField)`
    background-color: ${AppCSS.colors.white};
    width: 48px;
    & .MuiOutlinedInput-root {
      ${AppCSS.fonts.label12Medium};
      color: ${AppCSS.colors.greens.green80};
      & .MuiOutlinedInput-input {
        text-align: center;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  `,
  NavigationSeparator: styled.div`
    height: 24px;
    width: 1px;
    background-color: ${AppCSS.colors.greens.green50};
  `,
  SelectButton: styled.div<{ isFocused: boolean }>`
    display: flex;
    width: 56px;
    height: 30px;
    border: 1px solid ${(p) => (p.isFocused ? AppCSS.colors.primaryGreenGoth : AppCSS.colors.greens.green50)};
    padding: 8px 12px;
    ${AppCSS.radiuses.small};
    margin-right: 8px;
    margin-left: 8px;
    &:hover {
      cursor: pointer;
    }
  `,
  TextButton: styled.span`
    ${AppCSS.fonts.h6};
    color: ${AppCSS.colors.greens.green80};
    align-self: center;
  `,
  IconWrapper: styled.div`
    margin-left: 8px;
    align-items: center;
    display: flex;
    justify-content: center;
  `,

  PlaceholderWrapper: styled(GridOverlay)`
    width: 100%;
  `,
};
