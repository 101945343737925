import styled from 'styled-components';
import { AUTHENTICATION_CONTAINER_MAX_WIDTH } from '../../utils/styled/dimensions';
import AppCSS from '../../utils/styled';
import { ReactComponent as LogoSquare } from '../../assets/logo/logo-square-blacksvg.svg';
import Checkbox from '@mui/material/Checkbox';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/icon-arrow-left-white.svg';

export const Components = {
  Container: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: ${AUTHENTICATION_CONTAINER_MAX_WIDTH}px;
    height: 650px;
    ${AppCSS.boxShadows.block};
    margin: 16px;

    @media only screen and (max-width: 1132px) {
      flex-direction: column;
      height: max-content;
      align-items: center;
      justify-content: flex-start;
    }
  `,
  LeftSideWrapper: styled.div`
    height: 100%;
    width: 100%;
    max-width: 412px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 32px;
    gap: 44px;
    overflow: hidden;
    padding-top: 114px;
    @media only screen and (max-width: 1132px) {
      min-width: 100%;
    }
  `,
  LeftSideBackgroundImageWrapper: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  `,
  LogoSquare: styled(LogoSquare)`
    width: 188px;
    min-width: 188px;
    height: 196px;
    min-height: 196px;
    z-index: 10;
  `,
  LeftSideFooterWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    z-index: 10;
    text-align: center;
  `,
  FooterTitle: styled.span`
    ${AppCSS.fonts.h2};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  FooterSubtitle: styled.span`
    ${AppCSS.fonts.label16Regular};
    color: ${AppCSS.colors.greens.green80};
  `,
  RightSideWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 80px 40px;
    gap: 48px;
    @media only screen and (max-width: 1132px) {
      padding: 24px 16px;
    }
  `,
  RightSideHeaderWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  `,
  HeaderTitle: styled.span`
    ${AppCSS.fonts.h2};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  HeaderSubtitle: styled.span`
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green70};
  `,
  RightSideContentWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
  `,
  MultipleFieldsWrapper: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  `,
  FieldWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 8px;
  `,
  FieldLabel: styled.span`
    ${AppCSS.fonts.label14Regular};
    color: ${AppCSS.colors.greens.green90};
  `,
  TextField: styled.input`
    width: 100%;
    max-height: 40px;
    padding: 12px;
    ${AppCSS.fonts.label12Medium};
    color: ${AppCSS.colors.greens.green90};
    border: 1px solid ${AppCSS.colors.greens.green60};
    ${AppCSS.radiuses.small};
    background-color: ${AppCSS.colors.white};
    -webkit-appearance: none;
    &:focus-visible {
      -webkit-appearance: none;
      border: 1px solid ${AppCSS.colors.greens.green60};
      outline: none;
    }
    &:focus {
      -webkit-appearance: none;
      border: 1px solid ${AppCSS.colors.greens.green60};
      outline: none;
    }
    &::placeholder {
      color: ${AppCSS.colors.greens.green60};
    }
  `,
  PasswordFieldWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 6px;
  `,
  ForgotPasswordButton: styled(AppCSS.buttonStyles.TertiaryButtonStyle)``,
  CallToActionWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    padding-top: 8px;
  `,
  CallToActionButton: styled(AppCSS.buttonStyles.PrimaryButtonStyle)``,
  CallToActionSubtitle: styled.span`
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green90};
  `,
  CallToActionSubtitleAccent: styled.span`
    ${AppCSS.fonts.label12Bold};
    color: ${AppCSS.colors.primaryGreenGoth};
    cursor: pointer;
    margin-left: 2px;
  `,
  CheckboxWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    width: 100%;
    padding: 8px 0;
  `,
  Checkbox: styled(Checkbox)`
    &.Mui-checked {
      color: ${AppCSS.colors.primaryGreenGoth} !important;
    }
    color: ${AppCSS.colors.greens.green60} !important;
    width: 20px;
    height: 20px;
  `,
  ArrowLeftIcon: styled(ArrowLeftIcon)`
    width: 20px;
    height: 20px;
  `,
};
