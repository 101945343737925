import apiFetch from '../index';
import { ENDPOINTS } from '../endpoints';
import { SuccessResultDto } from '../types';
import { ProjectErrorType } from '../../utils/errors/types';
import { getProjectErrorFromRawError } from '../../utils/errors';
import { Note } from '../types/note';
import { parseNotes } from './parser';

export const getAllNotes = async (profileId: string, caseId: string): Promise<Note[]> => {
  try {
    const result = await apiFetch({
      path: ENDPOINTS.note.getAll,
      method: 'GET',
      query: { profileId, caseId },
    });

    return await parseNotes(result?.data);
  } catch (error) {
    console.log('Error at getAllNotes', error);
    return [];
  }
};

export const addNote = async (
  profileId: string,
  caseId: string,
  noteText: string,
): Promise<SuccessResultDto | ProjectErrorType> => {
  try {
    return await apiFetch({
      path: ENDPOINTS.note.addNote,
      method: 'POST',
      body: { noteText, caseId, profileId },
    });
  } catch (error) {
    console.log('Error at addNote', error);
    return getProjectErrorFromRawError(error);
  }
};
