export type FontType = 'Poppins-Bold' | 'OpenSans-Regular' | 'OpenSans-SemiBold' | 'OpenSans-Bold';

const poppinsBold: FontType = 'Poppins-Bold';
const openSansRegular: FontType = 'OpenSans-Regular';
const openSansSemiBold: FontType = 'OpenSans-SemiBold';
const openSansBold: FontType = 'OpenSans-Bold';

const FONTS = { poppinsBold, openSansBold, openSansSemiBold, openSansRegular };

export default FONTS;
