import styled from 'styled-components';
import AppCSS from '../../../../utils/styled';

export const Components = {
  Container: styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 24px;
    grid-row-gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 22px;
  `,
  FieldLabel: styled.span`
    ${AppCSS.fonts.label12Bold};
    color: ${AppCSS.colors.greens.green80};
    margin-top: 12px;
  `,
  FieldValue: styled.span`
    ${AppCSS.fonts.label14Bold};
    color: ${AppCSS.colors.primaryMatteBlack};
    margin-top: 12px;
  `,
};
