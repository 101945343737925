import React, { useContext, useEffect, useState } from 'react';
import { Components } from './styled';
import { ReactComponent as PinIcon } from '../../../../assets/icons/icon-pin.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/icon-arrow-right.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/icon-plus-alt.svg';
import { ReactComponent as EmptyStateIllustration } from '../../../../assets/illustrations/illustration-home-hearings.svg';
import { PortalCaseHearing, SavedCaseParty } from '../../../../api/types/portal';
import { parseDateToStringWithFormat } from '../../../../utils/dateFormat';
import { format } from '../../../../utils/dateFormat/types';
import Parties from '../../../../components/caseComponents/Parties';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { createMediaQuery } from '../../../../utils/responsive';
import { MEDIA_QUERY_BREAKPOINTS } from '../../../../utils/responsive/constants';
import { SessionContext } from '../../../../providers/SessionProvider/context';

const HearingItem = ({ hearingData }: { hearingData: PortalCaseHearing }) => {
  const navigate = useNavigate();

  const { currentProfile } = useContext(SessionContext);

  const [savedParty, setSavedParty] = useState<SavedCaseParty | undefined>();

  const isMobile = useMediaQuery({
    query: createMediaQuery(MEDIA_QUERY_BREAKPOINTS.largeMobile, true),
  });

  const getSavedParty = () => {
    const foundParty = currentProfile
      ? currentProfile?.savedParties?.find((item) => item?.caseId === hearingData?.caseId)
      : undefined;
    if (foundParty) {
      setSavedParty(foundParty);
    } else {
      setSavedParty(undefined);
    }
  };

  useEffect(() => {
    if (!savedParty) {
      getSavedParty();
    }
  }, [currentProfile]);

  const seeInCalendar = () => {
    navigate('/calendar');
  };

  const seeInCaseDetails = (caseId?: string) => {
    if (caseId) {
      navigate(`/dosare/${caseId}`);
    }
  };

  return (
    <Components.HearingContainer>
      <Components.HearingContent>
        <Components.HearingTitleWrapper>
          <Components.HearingTitle>
            {hearingData?.portalDate &&
              parseDateToStringWithFormat(hearingData?.portalDate, format.weekDayMonthDay)?.concat(
                ', ',
                hearingData?.hour,
              )}
          </Components.HearingTitle>
          <Components.HearingTag>TERMEN</Components.HearingTag>
        </Components.HearingTitleWrapper>
        {hearingData?.case && (
          <>
            <Components.HearingCaseNumberWrapper isMobile={isMobile}>
              <Components.HearingCaseNumber onClick={() => seeInCaseDetails(hearingData?.caseId)}>
                {hearingData?.case?.displayNumber}
              </Components.HearingCaseNumber>
              {!isMobile && <Components.Separator />}
              <Parties parties={hearingData?.case?.parties} savedParty={savedParty} />
            </Components.HearingCaseNumberWrapper>
            <Components.LocationAndPanelWWrapper isMobile={isMobile}>
              <Components.LocationWrapper>
                <PinIcon height={20} width={20} />
                <Components.LocationLabel>{hearingData?.case?.displayInstitution}</Components.LocationLabel>
              </Components.LocationWrapper>
              {hearingData?.panel && (
                <>
                  {!isMobile && <Components.Separator />}
                  <Components.LocationLabel>{hearingData?.panel}</Components.LocationLabel>
                </>
              )}
            </Components.LocationAndPanelWWrapper>
          </>
        )}
      </Components.HearingContent>
      <Components.ButtonsWrapper>
        <Components.SeeInCalendarButton onClick={seeInCalendar}>
          Vezi în calendar
          <ArrowRightIcon />
        </Components.SeeInCalendarButton>
        <Components.SeeInCaseButton onClick={() => seeInCaseDetails(hearingData?.caseId)}>
          Vezi în dosar
          <ArrowRightIcon />
        </Components.SeeInCaseButton>
      </Components.ButtonsWrapper>
    </Components.HearingContainer>
  );
};

const RecentHearings = ({ hearings }: { hearings: PortalCaseHearing[] }) => {
  const navigate = useNavigate();

  const onAddCase = () => {
    navigate('/portal-just');
  };

  return (
    <Components.Container>
      <Components.Title>Termene viitoare</Components.Title>
      <Components.HearingsWrapper>
        {hearings && hearings?.length > 0 ? (
          hearings?.map((item, key) => <HearingItem key={key} hearingData={item} />)
        ) : (
          <Components.EmptyStateWrapper>
            <EmptyStateIllustration />
            <Components.EmptyStateLabel>Termenele tale viitoare vor apărea aici</Components.EmptyStateLabel>
            <Components.AddCaseButton onClick={onAddCase}>
              <PlusIcon />
              Adaugă dosar
            </Components.AddCaseButton>
          </Components.EmptyStateWrapper>
        )}
      </Components.HearingsWrapper>
    </Components.Container>
  );
};

export default RecentHearings;
