import React from 'react';
import { Components } from './styled';

const WarningBar = () => {
  return (
    <Components.Container>
      <Components.Text>
        Juridiq <Components.TextAccent>suspendă activitatea față de aplicație.</Components.TextAccent> Asta înseamnă că
        problemele apărute nu vor mai fi rezolvate. Ne pare rău să anunțăm asta. Vă invităm să folosiți soluții ale
        altor echipe, precum <a href='https://caselib.ro/'>https://caselib.ro/</a>
      </Components.Text>
    </Components.Container>
  );
};

export default WarningBar;
