import styled from 'styled-components';
import AppCSS from '../../../utils/styled';

export const Components = {
  PartiesWrapper: styled.div<{ wrapContent?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 12px;
    row-gap: 8px;
    ${({ wrapContent }) => wrapContent && 'flex-wrap: wrap;'};
  `,
  PartyWrapper: styled.div<{ isChoosingParty?: boolean; isSavedParty?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    ${AppCSS.radiuses.small};
    border: 1px solid ${AppCSS.colors.greens.green50};
    gap: 4px;
    padding: 4px 8px;
    min-width: max-content;
    &:hover {
      ${({ isChoosingParty }) =>
        isChoosingParty &&
        `
        border: 1px solid ${AppCSS.colors.primaryGreenGoth};
        cursor: pointer;
      `}
    }
    ${({ isSavedParty }) =>
      isSavedParty &&
      `
        border: 1px solid ${AppCSS.colors.primaryGreenGoth};
      `}
  `,
  PartyIconWrapper: styled.div`
    min-width: max-content;
    height: 17px;
  `,
  PartyName: styled.span<{ isChoosingParty?: boolean; isSavedParty?: boolean }>`
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green70};
    &:hover {
      ${({ isChoosingParty }) =>
        isChoosingParty &&
        `
        color: ${AppCSS.colors.primaryGreenGoth};
      `}
    }
    ${({ isSavedParty }) =>
      isSavedParty &&
      `
        color: ${AppCSS.colors.primaryGreenGoth};
      `}
  `,
};
