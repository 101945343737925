import React from 'react';
import { useMatch } from 'react-router-dom';
import { Components } from './styled';

const SideBarLink = ({
  label,
  to,
  activeOnlyWhenExact,
  Icon,
  IconActive,
  hasTopSeparator = false,
  isCollapsed = false,
  children,
}: {
  label: string;
  to: string;
  activeOnlyWhenExact?: boolean;
  Icon?: React.ReactNode;
  IconActive?: React.ReactNode;
  hasTopSeparator?: boolean;
  isCollapsed?: boolean;
  children?: React.ReactNode;
}) => {
  const match = useMatch({
    path: activeOnlyWhenExact ? to : to?.concat('/*'),
    end: activeOnlyWhenExact,
  });

  return (
    <Components.LinkContainer to={to}>
      <>
        <Components.IconWrapper>{match ? IconActive : Icon}</Components.IconWrapper>
        {!isCollapsed && <Components.LinkText active={!!match}>{label}</Components.LinkText>}
        {children}
      </>
    </Components.LinkContainer>
  );
};

export default SideBarLink;
