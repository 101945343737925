import React from 'react';
import { GridFilterModel, GridLinkOperator, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { GridFilterItem } from '@mui/x-data-grid/models/gridFilterItem';
import AdditionalActions from './cells/AdditionalActions';
import { ProjectTableType } from './types';
import MultilineText from './cells/MultilineText';
import TableParties from './cells/TableParties';
import ProceduralStage from './cells/ProceduralStage';
import CaseNumber from './cells/CaseNumber';
import Date from './cells/Date';
import FieldAndId from './cells/TitleAndId';

export const TABLE_DEFAULT_SHOWN_ITEMS = 10;
export const TABLE_ROW_HEIGHT = 62;
export const TABLE_HEADER_HEIGHT = 42;
export const TABLE_ADDITIONAL_ACTIONS_CELL_WIDTH = 52;

export const filteringModel = (filters: GridFilterItem[]): GridFilterModel => ({
  items: filters,
  linkOperator: GridLinkOperator.And,
});

const columnConstants = {
  caseNumberDefaultWidth: 160,
  firebaseIdWidth: 260,
  dateColumnWidth: 176,
  casesCount: 140,
};

const dateAndTimeColumn = (fieldTitle: string, headerName: string, showTime?: boolean) => {
  return {
    field: fieldTitle,
    headerName: headerName,
    renderCell: (params: GridRenderCellParams) => <Date params={params} showTime={showTime} />,
    width: columnConstants.dateColumnWidth,
    flex: showTime ? 2 : undefined,
  };
};

const tableColumnsUtils = {
  additionalActionsColumn: ({
    onEdit,
    onDelete,
    onArchive,
    onDisable,
    onEnable,
    onViewDetails,
  }: {
    onEdit?: (id: string) => void;
    onDelete?: (id: string) => void;
    onArchive?: (id: string) => void;
    onDisable?: (id: string, userId: string) => void;
    onEnable?: (id: string, userId: string) => void;
    onViewDetails?: (id: string) => void;
  }) => ({
    field: 'additionalActions',
    headerName: '',
    renderCell: (params: GridRenderCellParams) => (
      <AdditionalActions
        onEdit={onEdit}
        onDelete={onDelete}
        onArchive={onArchive}
        onDisable={onDisable}
        onEnable={onEnable}
        onViewDetails={onViewDetails}
        params={params}
      />
    ),
    width: TABLE_ADDITIONAL_ACTIONS_CELL_WIDTH,
  }),
  primaryAction: (primaryActionMethod: (id: string) => void) => ({
    primaryAction: (params: GridRowParams) => primaryActionMethod(params.id as string),
  }),
  multilineText: (field: string, headerTitle: string) => ({
    field,
    headerName: headerTitle,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => <MultilineText text={params.value} />,
  }),
  parties: {
    field: 'parties',
    headerName: 'Părți',
    flex: 2,
    renderCell: (params: GridRenderCellParams) => <TableParties params={params} />,
  },
  proceduralStage: {
    field: 'proceduralStage',
    headerName: 'Stadiu procesual',
    renderCell: (params: GridRenderCellParams) => <ProceduralStage params={params} />,
  },
  caseNumber: ({ onPress }: { onPress?: (id: string) => void }) => ({
    field: 'caseNumber',
    headerName: 'Număr dosar',
    width: columnConstants.caseNumberDefaultWidth,
    renderCell: (params: GridRenderCellParams) => <CaseNumber params={params} onPress={onPress} />,
  }),
  searchValueColumn: {
    field: 'searchValue',
    headerName: 'Row data',
    hide: true,
  },
  dateAndTimeColumn,
  userNameAndId: () => ({
    field: 'name',
    headerName: 'Nume',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => <FieldAndId params={{ ...params, fieldPropertyName: 'name' }} />,
  }),
};

export const tableColumns = {
  cases: ({ onViewDetails, onArchive }: { onViewDetails: (id: string) => void; onArchive: (id: string) => void }) => ({
    columns: [
      tableColumnsUtils.caseNumber({ onPress: onViewDetails }),
      tableColumnsUtils.multilineText('matter', 'Obiect'),
      tableColumnsUtils.parties,
      { field: 'institution', headerName: 'Instanță', flex: 1 },
      tableColumnsUtils.proceduralStage,
      tableColumnsUtils.additionalActionsColumn({ onViewDetails, onArchive }),
      tableColumnsUtils.searchValueColumn,
    ],
    type: 'cases' as ProjectTableType,
    ...tableColumnsUtils.primaryAction(onViewDetails),
  }),
  casesPreview: ({ onViewDetails }: { onViewDetails: (id: string) => void }) => ({
    columns: [
      { field: 'caseNumber', headerName: 'Număr dosar', flex: 1 },
      { field: 'institution', headerName: 'Instanță', flex: 1 },
      tableColumnsUtils.searchValueColumn,
    ],
    type: 'casesPreview' as ProjectTableType,
    ...tableColumnsUtils.primaryAction(onViewDetails),
  }),
  users: ({ onViewDetails }: { onViewDetails: (id: string) => void }) => ({
    columns: [
      tableColumnsUtils.userNameAndId(),
      { field: 'email', headerName: 'Adresa de email', flex: 1 },
      { field: 'phoneNumber', headerName: 'Telefon', flex: 1 },
      { field: 'casesCount', headerName: 'Dosare adăugate', width: columnConstants.casesCount },
      { field: 'notesCount', headerName: 'Notițe adăugate', width: columnConstants.casesCount },
      tableColumnsUtils.dateAndTimeColumn('createdAt', 'Înregistrat la'),
      tableColumnsUtils.additionalActionsColumn({ onViewDetails }),
      tableColumnsUtils.searchValueColumn,
    ],
    type: 'users' as ProjectTableType,
    ...tableColumnsUtils.primaryAction(onViewDetails),
  }),
  overallSyncLogs: ({ onViewDetails }: { onViewDetails: (id: string) => void }) => ({
    columns: [
      { field: 'id', headerName: 'ID', flex: 1 },
      { field: 'type', headerName: 'Tip', flex: 2 },
      tableColumnsUtils.dateAndTimeColumn('createdAt', 'Început la', true),
      tableColumnsUtils.dateAndTimeColumn('finishedAt', 'Finalizat la', true),
      { field: 'casesFetchedFromFirestore', headerName: 'Dosare verificate', flex: 2 },
      { field: 'syncedCases', headerName: 'Dosare sincronizate', flex: 2 },
      tableColumnsUtils.additionalActionsColumn({ onViewDetails }),
    ],
    type: 'overallSyncLogs' as ProjectTableType,
    ...tableColumnsUtils.primaryAction(onViewDetails),
  }),
};
