import styled from 'styled-components';
import Menu from '@mui/material/Menu';
import AppCSS from '../../utils/styled';
import { MENU_ITEM_HEIGHT, MENU_MAX_HEIGHT } from '../../constants';
import MenuItem from '@mui/material/MenuItem';

export const Components = {
  Menu: styled(Menu)`
    & .MuiPaper-root {
      overflow-x: hidden;
      overflow-y: auto;
      border-radius: 0;
      max-height: ${MENU_MAX_HEIGHT}px;
      ${AppCSS.boxShadows.menu};
      & .MuiList-root {
        padding: 0;
      }
      & .MuiMenuItem-root {
        ${AppCSS.fonts.label12Regular} !important;
        color: ${AppCSS.colors.greens.green90};
        padding: 12px !important;
        max-height: ${MENU_ITEM_HEIGHT}px;
        min-width: 145px;
        border-bottom: 1px solid ${AppCSS.colors.greens.green50};
        &:hover {
          background-color: ${AppCSS.colors.utility.headerBar};
        }
      }
      & .MuiMenuItem-root:last-child {
        border-bottom: 0;
      }
    }
  `,
  // TODO - fix the styling of the menu
  InfoMenuItem: styled(MenuItem)`
    padding: 4px 12px;
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green60};
    max-height: ${MENU_ITEM_HEIGHT}px;
    min-width: 145px;
    border-bottom: 1px solid ${AppCSS.colors.greens.green50};
    opacity: 1 !important;
    &:hover {
      background-color: ${AppCSS.colors.utility.headerBar};
    }
  `,
  MenuItem: styled(MenuItem)`
    ${AppCSS.fonts.label12Regular} !important;
    color: ${AppCSS.colors.greens.green90};
    padding: 12px !important;
    max-height: ${MENU_ITEM_HEIGHT}px;
    min-width: 145px;
    border-bottom: 1px solid ${AppCSS.colors.greens.green50};
    &:hover {
      background-color: ${AppCSS.colors.utility.headerBar};
    }
  `,
  AlternativeMenuItem: styled(MenuItem)`
    color: ${AppCSS.colors.orange} !important;
    ${AppCSS.fonts.label12Regular};
    padding: 12px;
    max-height: ${MENU_ITEM_HEIGHT}px;
    min-width: 145px;
    border-bottom: 1px solid ${AppCSS.colors.greens.green50};
    &:hover {
      background-color: ${AppCSS.colors.utility.headerBar};
    }
  `,
  DestructiveMenuItem: styled(MenuItem)`
    color: ${AppCSS.colors.red} !important;
    ${AppCSS.fonts.label12Regular};
    padding: 12px;
    max-height: ${MENU_ITEM_HEIGHT}px;
    min-width: 145px;
    border-bottom: 1px solid ${AppCSS.colors.greens.green50};
    &:hover {
      background-color: ${AppCSS.colors.utility.headerBar};
    }
  `,
};
