import React from 'react';
import { Components } from './styled';

const FieldAndId = ({ params }: { params?: any }) => {
  const { row, fieldPropertyName }: { row: any; fieldPropertyName: any } = params;
  const id = row?.id;
  const field = !!fieldPropertyName ? row?.[fieldPropertyName] : row?.title;
  return (
    <Components.Container>
      <Components.Title>{field}</Components.Title>
      <Components.Id>{id}</Components.Id>
    </Components.Container>
  );
};

export default FieldAndId;
