import styled from 'styled-components';
import { NAVIGATION_BAR_HEIGHT } from '../../utils/styled/dimensions';
import AppCSS from '../../utils/styled';

export const Components = {
  Container: styled.div`
    position: sticky;
    top: ${NAVIGATION_BAR_HEIGHT}px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    z-index: 99;
    padding: 12px 24px;
    gap: 24px;
    background-color: ${AppCSS.colors.greens.green50};
  `,
  Text: styled.span`
    ${AppCSS.fonts.label16Regular};
    color: ${AppCSS.colors.greens.green80};
    flex: 1;
  `,
  TextAccent: styled.span`
    ${AppCSS.fonts.label16Bold};
    color: ${AppCSS.colors.greens.green80};
  `,
  Link: styled.span`
    ${AppCSS.fonts.label16Bold};
    text-decoration: underline;
    cursor: pointer;
  `,
  CloseIconWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,
};
