import { Profile } from '../types/profile';
import apiFetch from '../index';
import { ENDPOINTS } from '../endpoints';
import { parseProfile, parseProfiles } from '../profile/parser';
import { ProjectErrorType } from '../../utils/errors/types';
import { getProjectErrorFromRawError } from '../../utils/errors';
import { UpdateUserDto } from '../typeDtos/users';
import { SuccessResultDto } from '../types';
import { sortByCreatedAt } from '../utils';

export const getAllUsers = async (): Promise<Profile[]> => {
  try {
    const result = await apiFetch({ method: 'GET', path: ENDPOINTS.admin.users.getAll });
    const parsedProfiles = await parseProfiles(result?.data);
    return sortByCreatedAt(parsedProfiles);
  } catch (error) {
    console.log('[getProfileByUserId] Error:', error);
    return [];
  }
};

export const getUserById = async (id: string): Promise<Profile | ProjectErrorType> => {
  try {
    const result = await apiFetch({ method: 'GET', path: ENDPOINTS.admin.users.getById(id) });
    return await parseProfile(result?.data);
  } catch (error) {
    console.log('[getProfileByUserId] Error:', error);
    return getProjectErrorFromRawError(error);
  }
};

export const updateUser = async (dto: UpdateUserDto): Promise<SuccessResultDto | ProjectErrorType> => {
  try {
    return await apiFetch({ method: 'POST', path: ENDPOINTS.admin.users.update, body: dto });
  } catch (error) {
    console.log('[updateUser] Error:', error);
    return getProjectErrorFromRawError(error);
  }
};
