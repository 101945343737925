import styled from 'styled-components';
import { EventType } from '../../types';
import AppCSS from '../../../../../utils/styled';

export const Components = {
  DayTileWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1px;
    margin-top: 4.5px;
  `,
  DotsWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 5px;
  `,
  Dot: styled.div<{ variant: EventType; isCurrentDate?: boolean }>`
    width: 5px;
    height: 5px;
    ${AppCSS.radiuses.forHeight(5)};
    background-color: ${({ variant, isCurrentDate }) => {
      switch (variant) {
        case 'hearing':
          if (isCurrentDate) {
            return AppCSS.colors.greens.green50;
          }
          return AppCSS.colors.primaryGreenGoth;
      }
    }};
  `,
};
