import React, { useContext, useEffect } from 'react';
import ProjectContainer from '../../components/containers/ProjectContainer';
import { Components } from './styled';
import RecentHearings from './components/RecentHearings';
import { ActivityIndicatorContext } from '../../providers/ActivityIndicatorProvider/context';
import { getUpcomingEvents } from '../../api/calendar';
import { SessionContext } from '../../providers/SessionProvider/context';
import { useMediaQuery } from 'react-responsive';
import { createMediaQuery } from '../../utils/responsive';
import { MEDIA_QUERY_BREAKPOINTS } from '../../utils/responsive/constants';
import { useQuery } from '@tanstack/react-query';
import Tasks from './components/Tasks';
import OnboardingBlock from './components/OnboardingBlock';
import { OnboardingContext } from '../../providers/OnboardingProvider/context';

const Home = () => {
  const { setIsLoading } = useContext(ActivityIndicatorContext);
  const { currentProfile, isLoggedIn } = useContext(SessionContext);
  const { shouldShowOnboarding } = useContext(OnboardingContext);

  const { isLoading, data } = useQuery({
    queryKey: ['home-cases'],
    queryFn: () => getUpcomingEvents(),
    enabled: !!currentProfile,
  });

  const isMobile = useMediaQuery({
    query: createMediaQuery(MEDIA_QUERY_BREAKPOINTS.largeMobile, true),
  });

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  return (
    <ProjectContainer title={'Acasă'}>
      <Components.Container isOnMobile={isMobile}>
        <Components.WideColumnContainer>
          <RecentHearings hearings={data?.events || []} />
        </Components.WideColumnContainer>
        {shouldShowOnboarding && (
          <Components.SmallColumnContainer>
            <OnboardingBlock />
          </Components.SmallColumnContainer>
        )}
      </Components.Container>
    </ProjectContainer>
  );
};

export default Home;
