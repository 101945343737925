import Emitter from './event-emitter';
import { EventListenerType } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const subscribeTo = (type: EventListenerType, callback: (data: any) => void) => {
  Emitter.addEventListener(type, callback);
};

export const unsubscribeFrom = (listener?: string) => {
  listener && Emitter.removeEventListener(listener);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const triggerEvent = (type: EventListenerType, data?: any) => {
  Emitter.emit(type, data);
};
