import React from 'react';
import { Components } from './styled';
import ProjectModal from '../../../../../components/ProjectModal';
import ProjectTextField from '../../../../../components/inputs/ProjectTextField';
import useFormTextState, { FormTextStateType } from '../../../../../hooks/useFormTextState';

const AddCasesModal = ({
  isModalOpen,
  onModalClose,
  onSave,
}: {
  isModalOpen: boolean;
  onModalClose: () => void;
  onSave: (numbers: string[]) => void;
}) => {
  const inputProps = useFormTextState();

  const _onModalClose = () => {
    onModalClose?.();
  };

  const _onSave = () => {
    if (inputProps?.value && inputProps?.value?.length > 0) {
      const formatedNumbers: string[] = inputProps?.value?.split(' ')?.map((item) => item?.trim());
      onSave?.(formatedNumbers);
    }
    _onModalClose();
  };

  return (
    <ProjectModal
      bodyChildren={<ModalBody inputProps={inputProps} />}
      buttonChildren={<ModalButtons onModalClose={_onModalClose} onContinue={_onSave} />}
      isModalOpen={isModalOpen}
      onModalClose={_onModalClose}
      modalTitle={'Adaugă dosare utilizatorului'}
    />
  );
};

const ModalBody = ({ inputProps }: { inputProps: FormTextStateType }) => {
  return (
    <Components.ModalBodyContainer>
      <Components.Label>Limita max. 500 dosare</Components.Label>
      <ProjectTextField {...inputProps} placeholder={'Introdu numerele de dosare, separate de virgulă'} />
    </Components.ModalBodyContainer>
  );
};

const ModalButtons = ({ onModalClose, onContinue }: { onModalClose: () => void; onContinue: () => void }) => {
  return (
    <Components.ModalButtonsContainer>
      <Components.CancelButton onClick={onModalClose}>Anulează</Components.CancelButton>
      <Components.Button onClick={onContinue}>Adaugă</Components.Button>
    </Components.ModalButtonsContainer>
  );
};

export default AddCasesModal;
