import React from 'react';
import { Components } from './styled';
import { ReactComponent as ThreeDotsIcon } from '../../../../assets/icons/icon-three-dots.svg';
import useDropdown from '../../../../hooks/useDropdown';
import ProjectDropdown from '../../../ProjectDropdown';

const AdditionalActions = ({
  params,
  onEdit,
  onDelete,
  onArchive,
  onDisable,
  onEnable,
  onViewDetails,
}: {
  params?: any;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  onArchive?: (id: string) => void;
  onDisable?: (id: string, userId: string) => void;
  onEnable?: (id: string, userId: string) => void;
  onViewDetails?: (id: string) => void;
}) => {
  const { row }: { row: any } = params;
  const dropdownProps = useDropdown();

  const _onDisable = () => {
    onDisable?.(row.id, row.userId);
  };

  const _onEnable = () => {
    onEnable?.(row.id, row.userId);
  };

  const _onEdit = () => {
    onEdit?.(row.id);
  };

  const _onDelete = async () => {
    onDelete?.(row.id);
  };

  const _onArchive = async () => {
    onArchive?.(row.id);
  };

  const _onViewDetails = () => {
    onViewDetails?.(row.id);
  };

  const hasOnDisable = row.state && onDisable;
  const hasOnEnable = !row.state && onEnable;
  return (
    <Components.Container fullWidth fullHeight displayFlex alignItems={'center'} justifyContent={'center'}>
      <Components.Button onClick={dropdownProps.show} aria-expanded={dropdownProps.open ? 'true' : undefined}>
        <ThreeDotsIcon />
      </Components.Button>
      <ProjectDropdown
        {...dropdownProps}
        onEdit={onEdit ? _onEdit : undefined}
        onDisable={hasOnDisable ? _onDisable : undefined}
        onEnable={hasOnEnable ? _onEnable : undefined}
        onDelete={onDelete ? _onDelete : undefined}
        onArchive={onArchive ? _onArchive : undefined}
        onViewDetails={onViewDetails ? _onViewDetails : undefined}
      />
    </Components.Container>
  );
};

export default AdditionalActions;
