import env from './env';
import { format } from './utils/dateFormat/types';

export const isProductionEnvironment = env.isProduction;

export const APP_VERSION = '1.0';
export const APP_BUILD_NUMBER = '1';

export const BROWSER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Etc/UTC';
export const BACKEND_BASE_URL = env.baseUrl;

export const SEPARATOR = '|||' as const;

export const EVENT_METADATA_DATE_FORMAT = format.monthDayYear;

export const DEFAULT_PHONE_NUMBER_CODE = '+40' as const;

export const STORAGE_KEYS = {
  deviceId: 'DEVICE_ID',
};

export const SUBSCRIPTION_TYPE = {
  trial: 'trial',
  pro: 'pro',
} as const;

export const CASE_NUMBER_EXAMPLE = '4148/2/2022' as const;

export const UPGRADE_FORM_LINK = 'https://share-eu1.hsforms.com/1gXzmJOm-S96e1YkoTb445wfbl1m' as const;

export const LOCAL_STORAGE_KEYS = {
  isLoggedIn: 'isLoggedIn',
  didCloseTrialBanner: 'didCloseTrialBanner',
  didCloseCampaignBanner: 'didCloseCampaignBanner',
} as const;

export const EXTERNAL_LINKS = {
  termsAndConditions: 'https://www.juridiq.ro/termeni-si-conditii',
  privacyPolicy: 'https://www.juridiq.ro/politica-de-confidentialitate',
} as const;

export const PRODUCT_PRICE_ID = {
  subMonthly: 'price_1NFljnGIxD8yZxqBpQ9dLCen',
  subDaily: 'price_1LcBnfGIxD8yZxqBbq3G9piJ',
} as const;

export const SIGN_UP_CAMPAIGNS = {
  BaroulConstanta: 'bOkDfNDuxK1XqAU62Dif',
};
