import React from 'react';
import { Components } from './styled';
import { ReactComponent as BlackClockIcon } from '../../../assets/icons/icon-clock-black.svg';
import { PortalCase, PortalCaseHearing } from '../../../api/types/portal';
import { parseDateToStringWithFormat } from '../../../utils/dateFormat';
import Placeholder from '../../Placeholder';
import { format } from '../../../utils/dateFormat/types';

export const JustCaseHearings = ({ hearings }: { hearings: PortalCaseHearing[] }) => {
  return (
    <Components.BodyContainer>
      {hearings && hearings?.length > 0 ? (
        hearings?.map((item, key) => {
          const title = parseDateToStringWithFormat(item?.portalDate, format.monthDayYear)?.concat(', ', item?.hour);

          return (
            <Components.HearingContainer key={key}>
              <Components.HearingHeaderContainer>
                <Components.ClockWrapper>
                  <BlackClockIcon />
                </Components.ClockWrapper>
                <Components.HearingHeaderTitle>{title}</Components.HearingHeaderTitle>
              </Components.HearingHeaderContainer>
              {item?.panel && (
                <>
                  <Components.HearingPanelLabel>
                    Complet - <Components.HearingSolution>{item?.panel}</Components.HearingSolution>
                  </Components.HearingPanelLabel>
                </>
              )}
              {item?.solution && <Components.HearingSolution>{item?.solution}</Components.HearingSolution>}

              {item?.solutionSummary && (
                <Components.HearingSolutionSummary>{item?.solutionSummary}</Components.HearingSolutionSummary>
              )}
            </Components.HearingContainer>
          );
        })
      ) : (
        <Placeholder text={'Nu există termene ce pot fi afișate'} />
      )}
    </Components.BodyContainer>
  );
};

const CaseHearings = ({ data }: { data: PortalCase }) => {
  return (
    <Components.Container>
      <Components.HeaderContainer>
        <Components.HeaderTitle>Termene</Components.HeaderTitle>
      </Components.HeaderContainer>
      <JustCaseHearings hearings={data?.hearings} />
    </Components.Container>
  );
};

export default CaseHearings;
