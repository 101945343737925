import { SEPARATOR } from '../../defines';
import { InstitutionEnum } from './enums';
import { InstitutionType } from '../types/portal';

export const parsePortalCaseNumber = (caseNumber: string): string => {
  return caseNumber?.split(SEPARATOR)?.[0];
};

export const parseCaseNumberAndInstitution = (
  caseNumber: string,
): { caseNumber: string; institution: string; displayInstitution: string } => {
  const [_caseNumber, institution] = caseNumber?.split(SEPARATOR);
  const displayInstitution = InstitutionEnum[institution as InstitutionType];
  return { caseNumber: _caseNumber, institution, displayInstitution };
};
