import styled from 'styled-components';
import AppCSS from '../../../../utils/styled';
import MenuItem from '@mui/material/MenuItem';

export const Components = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 24px;
  `,
  SearchBarContainer: styled.div<{ isTablet: boolean }>`
    width: 100%;
    display: flex;
    ${(p) => p.isTablet && 'flex-direction: column;'};
    align-items: flex-start;
    justify-content: flex-end;
    padding: 24px 16px;
    background-color: ${AppCSS.colors.white};
    ${AppCSS.boxShadows.block};
    gap: ${(p) => (p.isTablet ? 16 : 16)}px;
  `,
  TextFieldsContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  `,
  TextFieldWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    width: 100%;
  `,
  TextFieldLabel: styled.span`
    ${AppCSS.fonts.label12Bold};
    color: ${AppCSS.colors.greens.green70};
  `,
  FiltersButton: styled(AppCSS.buttonStyles.SecondaryButtonWithIconStyle)<{ isTablet: boolean }>`
    margin-top: ${(p) => !p.isTablet && 24}px;
  `,
  SearchButton: styled(AppCSS.buttonStyles.PrimaryButtonWithIconStyle)<{ isTablet: boolean }>`
    margin-top: ${(p) => !p.isTablet && 24}px;
  `,
  MenuItem: styled(MenuItem)`
    padding: 8px;
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  CasesContainer: styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${AppCSS.colors.white};
    ${AppCSS.boxShadows.block};
  `,
  EmptyStateWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin: 32px 0;
  `,
  EmptyStateLabel: styled.span`
    ${AppCSS.fonts.label16Regular};
    color: ${AppCSS.colors.greens.green80};
  `,
};
