import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AppCSS from '../../../utils/styled';

export const Components = {
  LinkContainer: styled(Link)<{ hasTopSeparator?: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 16px;
    ${(p) => !p.hasTopSeparator && `margin-top: ${16}px;`};
    ${(p) => p.hasTopSeparator && `padding-top: ${16}px;`};
    width: 100%;
    ${(p) => p.hasTopSeparator && `border-top: 1px solid ${AppCSS.colors.greens.green50};`};
  `,
  IconWrapper: styled.div`
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  `,
  LinkText: styled.span<{ active?: boolean }>`
    ${({ active }) => (active ? AppCSS.fonts.label14Medium : AppCSS.fonts.label14Regular)};
    color: ${AppCSS.colors.white};
  `,
};
