import styled from 'styled-components';
import AppCSS from '../../../../utils/styled';

export const Components = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
  `,
  Header: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    padding-bottom: 16px;
  `,
  HeaderTitle: styled.span`
    ${AppCSS.fonts.h3};
    color: ${AppCSS.colors.greens.green80};
  `,
  HearingsContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow-y: auto;
  `,
  HearingContainer: styled.div`
    width: 100%;
    border-top: 1px solid ${AppCSS.colors.utility.borders};
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
  `,
  HearingHeaderContainer: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  HearingCaseNumber: styled.span`
    ${AppCSS.fonts.h3};
    color: ${AppCSS.colors.primaryGreenGoth};
    cursor: pointer;
  `,
  HearingDate: styled.span`
    ${AppCSS.fonts.h3};
    color: ${AppCSS.colors.greens.green80};
  `,
  SeeInCaseButton: styled(AppCSS.buttonStyles.TertiaryButtonStyle)``,
  LocationWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-left: -4px;
  `,
  LocationLabel: styled.span`
    ${AppCSS.fonts.label14Medium};
    color: ${AppCSS.colors.greens.green80};
  `,
  PanelLabel: styled.div`
    ${AppCSS.fonts.label14Regular};
    color: ${AppCSS.colors.greens.green80};
  `,

  PanelAndPartyWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  `,
  Separator: styled.div`
    height: 24px;
    width: 1px;
    background-color: ${AppCSS.colors.utility.borders};
  `,
};
