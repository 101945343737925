import { CaseContext } from './context';
import React, { useContext, useMemo, useState } from 'react';
import { saveCaseParty as saveCasePartyMethod } from '../../api/profile';
import { SessionContext } from '../SessionProvider/context';
import { SavedCaseParty } from '../../api/types/portal';
import { isProjectError } from '../../utils/errors';
import { Profile } from '../../api/types/profile';

const CaseProvider = ({ children }: { children: React.ReactNode }) => {
  const { currentProfile, updateCurrentProfile } = useContext(SessionContext);

  const saveCaseParty = async (savedParty: SavedCaseParty) => {
    if (currentProfile) {
      const result = await saveCasePartyMethod(currentProfile?.id, savedParty).then();
      if (!isProjectError(result)) {
        updateCurrentProfile(result as Profile);
      }
    }
  };

  const value = useMemo(
    () => ({
      saveCaseParty,
    }),
    [currentProfile],
  );

  return <CaseContext.Provider value={value}>{children}</CaseContext.Provider>;
};

export default CaseProvider;
