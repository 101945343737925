import styled from 'styled-components';
import AppCSS from '../../../utils/styled';

export const Components = {
  HeaderSubtitle: styled.span`
    ${AppCSS.fonts.label14Regular};
    color: ${AppCSS.colors.greens.green90};
  `,
  HeaderSubtitleAccent: styled.span`
    ${AppCSS.fonts.label14Bold};
    color: ${AppCSS.colors.greens.green90};
  `,
  WhatsAppLabelWrapper: styled.div`
    display: inline-block;
    margin-top: -5px;
  `,
  WhatsAppIconWrapper: styled.div`
    display: inline-block;
    position: relative;
    top: 5px;
  `,
};
