import React, { ChangeEvent, useState } from 'react';
import { Components } from './styled';
import { ReactComponent as IconShow } from '../../../assets/icons/icon-eye-open.svg';
import { ReactComponent as IconHide } from '../../../assets/icons/icon-eye-closed.svg';
import { ProjectTextFieldType } from './types';

const ProjectTextField = ({
  value,
  onChangeText,
  type = 'text',
  placeholder,
  prefixIcon,
  secureTextEntry,
  isDisabled,
}: {
  value: string | undefined;
  onChangeText?: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: ProjectTextFieldType;
  placeholder?: string;
  prefixIcon?: React.ReactNode;
  secureTextEntry?: boolean;
  isDisabled?: boolean;
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const toggleVisiblePassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <Components.Container>
      <Components.TextField
        value={value || ''}
        onChange={onChangeText}
        placeholder={placeholder || (!isDisabled ? 'Introdu datele' : '')}
        type={secureTextEntry && !isPasswordVisible ? 'password' : type}
        disabled={isDisabled}
        isDisabled={isDisabled}
      />
      {secureTextEntry && (
        <Components.SecureIconWrapper onClick={toggleVisiblePassword} isPressable>
          {isPasswordVisible ? <IconHide /> : <IconShow />}
        </Components.SecureIconWrapper>
      )}
    </Components.Container>
  );
};

export default ProjectTextField;
