import { ProjectErrors, ProjectErrorType } from '../../utils/errors/types';
import { isValidYupSchema } from '../utils';
import { getProjectErrorForCode, getProjectErrorFromRawError, isProjectError } from '../../utils/errors';
import { PortalCaseHearing } from '../types/portal';
import { HearingSchema } from '../portal/parser';
import { CalendarEvents, EventsMetadata } from '../types/calendar';
import { parseDateToStringWithFormat } from '../../utils/dateFormat';
import { EVENT_METADATA_DATE_FORMAT } from '../../defines';
import { processCase } from '../case/parser';

export const parseCaseHearing = async (object: any): Promise<PortalCaseHearing | ProjectErrorType> => {
  try {
    if (await isValidYupSchema(HearingSchema, object)) {
      return processEvent(object as PortalCaseHearing);
    }
    return getProjectErrorForCode(ProjectErrors.caseHearingInvalid);
  } catch (error) {
    console.log('Error at validating case hearing (id:', object?.id, '):', error);
    return getProjectErrorFromRawError(error as Error);
  }
};

const processEvent = (event: PortalCaseHearing): PortalCaseHearing => {
  return {
    ...event,
    case: event?.case ? processCase(event?.case) : undefined,
  };
};

const processEventMetadata = (event: PortalCaseHearing): EventsMetadata => {
  if (event.portalDate) {
    const key = parseDateToStringWithFormat(event.portalDate, EVENT_METADATA_DATE_FORMAT);
    return {
      [key]: {
        hasHearings: true,
      },
    };
  }
  return {};
};

export const parseCaseHearings = async (items: any): Promise<CalendarEvents> => {
  if (!(items && Array.isArray(items))) {
    console.log('[CASE HEARINGS] Error: No array found.');
    return { events: [], eventsMetadata: {} };
  }

  const list: PortalCaseHearing[] = [];
  let eventsMetadata: EventsMetadata = {};
  for (const item of items) {
    const o = await parseCaseHearing(item);
    if (o && !isProjectError(o)) {
      const typedObject = o as PortalCaseHearing;
      list.push(typedObject);
      eventsMetadata = { ...eventsMetadata, ...processEventMetadata(typedObject) };
    }
  }

  return { events: list, eventsMetadata };
};
