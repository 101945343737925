export interface ProjectErrorType {
  code: ProjectErrors | 'custom' | string;
  message: string;
  details?: string;
  getUserMessage: () => string;
}

export enum ProjectErrors {
  generic = 'A apărut o eroare',
  genericTryAgain = 'A apărut o eroare. Vă rugăm încercați din nou.',
  serverResponseInvalid = 'A apărut o eroare. Vă rugăm încercați din nou.',
  genericCheckInternetAndTryAgain = 'A apărut o eroare. Vă rugăm să verificați conexiunea la internet încercați din nou.',
  forbidden = 'Nu aveți acces să folosiți serviciile de pe această platformă. Vă rugăm să consulați un administrator pentru mai multe detalii.',

  signupEmailInUse = 'Adresa de email introdusă este asociată unui cont existent. Vă rugăm încercați din nou cu o adresă de email nouă.',
  authExistingAccount = 'Există deja un cont cu adresa de email introdusă, dar cu alte credențiale.',
  signupInvalidEmail = 'Adresa de email introdusă este invalidă. Vă rugăm încercați din nou.',
  loginUserNotFound = 'Utilizatorul nu a fost găsit. Vă rugăm să verificați credențialele introduse și să încercați din nou',
  loginWrongPassword = 'Parola introdusă este greșită. Vă rugăm să verificați parola și să încercați din nou.',
  phoneNumberNotVerified = 'Numărul dvs. de telefon nu este verificat.',
  phoneVerificationCodeInvalid = 'Codul de verificare introdus este invalid. Vă rugăm încercați din nou.',
  changePassword = 'A apărut o eroare la setarea parolei. Vă rugăm încercați din nou.',
  changePasswordRecentLogin = 'Sunteți conectat de prea mult timp. Vă rugăm să vă de-conectați, reconectați și să încercați din nou.',

  expiredSubscription = 'Abonamentul dumneavoastră a expirat. Pentru a continua să folosiți serviciile Juridiq, cumpărați abonamentul Pro din secțiunea Contul meu. ',

  profileInvalid = 'A apărut o problemă la încărcarea profilului. Vă rugăm încercați din nou.',
  portalCaseInvalid = 'A apărut o problemă la încărcarea dosarului. Vă rugăm încercați din nou.',
  caseInvalid = 'A apărut o problemă la încărcarea dosarului. Încercați din nou.',
  caseHearingInvalid = 'A apărut o problemă la încărcarea termenului. Încercați din nou.',
  authInvalidUserId = 'Utilizator invalid. Se anulează acțiunea de autentificare.',
  couldNotCreateProfile = 'Nu am putut crea profilul utilizatorului. Vă rugăm încercați din nou',
  noteInvalid = 'A apărut o problemă la încărcarea notei. Vă rugăm încercați din nou.',

  couldNotExportExcel = 'Nu am putut procesa datele pentru a exporta fișierul Excel. Vă rugăm încercați din nou.',
}
