import { parsePortalCaseNumber } from '../portal/utils';
import { CaseCategoryEnum, DisplayProceduralStageEnum, InstitutionEnum } from '../portal/enums';
import { ProceduralStageEnum } from '../enums/caseEnums';
import { ProjectErrors, ProjectErrorType } from '../../utils/errors/types';
import { isValidYupSchema, yupUtils } from '../utils';
import { getProjectErrorForCode, getProjectErrorFromRawError, isProjectError } from '../../utils/errors';
import { PortalCaseSchema } from '../portal/parser';
import { Case } from '../types/case';
import { CaseCategoryType, InstitutionType } from '../types/portal';

const CaseSchema = PortalCaseSchema.shape({
  id: yupUtils.stringRequired,
  createdAt: yupUtils.numberRequired,
  updatedAt: yupUtils.numberRequired,
  lastSyncedAt: yupUtils.numberRequired,
});

export const processCase = (object: any): Case => {
  return {
    ...object,
    displayNumber: parsePortalCaseNumber(object?.number),
    displayInstitution: InstitutionEnum[object?.institution as InstitutionType],
    displayProceduralStage: DisplayProceduralStageEnum[object?.proceduralStage as keyof typeof ProceduralStageEnum],
    displayCategory: CaseCategoryEnum[object?.category as CaseCategoryType],
  };
};

export const parseCase = async (object: any): Promise<Case | ProjectErrorType> => {
  try {
    if (await isValidYupSchema(CaseSchema, object)) {
      return processCase(object as Case);
    }
    return getProjectErrorForCode(ProjectErrors.caseInvalid);
  } catch (error) {
    console.log('Error at validating case (id:', object?.id, '):', error);
    return getProjectErrorFromRawError(error as Error);
  }
};

export const parseCases = async (items: any): Promise<Case[]> => {
  if (!(items && Array.isArray(items))) {
    console.log('[CASES] Error: No array found.');
    return [];
  }

  const list: Case[] = [];
  for (const item of items) {
    const o = await parseCase(item);
    o && !isProjectError(o) && list.push(o as Case);
  }

  return list;
};
