/* eslint-disable @typescript-eslint/naming-convention */
import { generateDeviceId, getAnalyticsDateFormat, getDevicePlatform } from './utils';
import { logEvent as logEventAction, setUserProperties, setUserId as setUserIdAction } from 'firebase/analytics';
import { APP_BUILD_NUMBER, APP_VERSION } from '../defines';
import { isProductionEnvironment, STORAGE_KEYS } from '../defines';
import { LocalStorage } from '../hooks/useLocalStorage';
import { analytics } from '../firebase';
import { CaseCategoryEnum, InstitutionEnum } from '../api/portal/enums';

const ANALYTICS_APPLICATION = 'juridiq_web';
export const ANALYTICS_MAXIMUM_CHARACTERS = 99;

export const EVENTS = {
  save_case: ({
    displayNumber,
    displayInstitution,
    category,
  }: {
    displayNumber: string;
    displayInstitution: InstitutionEnum;
    category: CaseCategoryEnum;
  }) => ({
    name: 'save_case',
    number: displayNumber,
    institution: displayInstitution,
    category,
  }),
  add_case_note: ({
    caseNumber,
    caseInstitution,
    noteLength,
    caseCategory,
  }: {
    caseNumber: string;
    caseInstitution: InstitutionEnum;
    noteLength: number;
    caseCategory: CaseCategoryEnum;
  }) => ({
    name: 'add_case_note',
    number: caseNumber,
    institution: caseInstitution,
    length: noteLength?.toString(),
    category: caseCategory,
  }),
};

export const TRAITS = {
  trait_user_id: (value: string) => ({
    name: 'trait_user_id',
    value,
  }),
  last_seen_time: (value: number) => ({
    name: 'last_seen_time',
    value: value?.toString(),
  }),
  sign_up_date: (value: string) => ({
    name: 'sign_up_date',
    value,
  }),
};

export const logEvent = async ({ name, ...rest }: { name: string }) => {
  try {
    if (name && name.length > 0) {
      let deviceId;
      const existingDeviceId = LocalStorage.getItem(STORAGE_KEYS.deviceId);
      if (existingDeviceId) {
        deviceId = existingDeviceId;
      } else {
        const generatedDeviceId = generateDeviceId();
        LocalStorage.setItem(STORAGE_KEYS.deviceId, generatedDeviceId);
        deviceId = generatedDeviceId;
      }
      const now = new Date();
      const options: { [key: string]: any } = {
        now: now.toISOString(),
        now_ts: `${now.valueOf()}`,
        day: getAnalyticsDateFormat(new Date()),
        device_id: deviceId,
        version: APP_VERSION,
        build: APP_BUILD_NUMBER,
        application: ANALYTICS_APPLICATION,
        environment: isProductionEnvironment ? 'production' : 'staging',
        platform: getDevicePlatform(),
        ...(rest || {}),
      };
      await logEventAction(analytics, name, options);
    } else {
      console.log('[ANALYTICS] Invalid Event log data:', name);
    }
  } catch (error) {
    console.log(`[ANALYTICS] Error at log event(${name}):`, error);
  }
};

export const updateTrait = async ({ name, value }: { name: string; value: string }) => {
  try {
    if ((value === null || value === undefined || (value && value.length > 0)) && name && name.length > 0) {
      await setUserProperties(analytics, { name: value === undefined ? null : value });
    } else {
      console.log('[ANALYTICS] Invalid User traits data:', name, value);
    }
  } catch (error) {
    console.log(`[ANALYTICS] Invalid User traits. Error: ${error}. Data:`, name, value);
  }
};

export const updateLoggedInState = async (userId: string | undefined) => {
  try {
    if (userId) {
      await setUserIdAction(analytics, userId);
      await updateTrait(TRAITS.trait_user_id(userId));
      await updateTrait(TRAITS.last_seen_time(new Date().getTime()));
    } else {
      console.log('[ANALYTICS] Invalid provided user ID');
    }
  } catch (error) {
    console.log('[ANALYTICS] Invalid updateLoggedInState data. Error:', error);
  }
};
