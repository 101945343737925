import React from 'react';
import { Components, styles } from './styled';

const GenericTooltip = ({ text, children }: { text: string; children: React.ReactElement }) => {
  return (
    <Components.Tooltip
      title={text}
      componentsProps={{
        // @ts-ignore
        tooltip: { sx: styles.TooltipStyle },
      }}>
      <div>{children}</div>
    </Components.Tooltip>
  );
};

export default GenericTooltip;
