import React, { useContext, useEffect, useState } from 'react';
import ProjectContainer from '../../components/containers/ProjectContainer';
import { Components } from './styled';
import ProjectCalendar from '../../components/dateAndTime/ProjectCalendar';
import { calendarConfigs } from '../../components/dateAndTime/ProjectCalendar/constants';
import {
  addMonthsToDate,
  getStartOfMonth,
  isSameMonth,
  parseDateToStringWithFormat,
  subtractMonthsFromDate,
} from '../../utils/dateFormat';
import { format } from '../../utils/dateFormat/types';
import { ReactComponent as AngleLeftIcon } from '../../assets/icons/icon-angle-left.svg';
import { ReactComponent as AngleRightIcon } from '../../assets/icons/icon-angle-right.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/icon-arrow-right.svg';
import { ActivityIndicatorContext } from '../../providers/ActivityIndicatorProvider/context';
import { getCalendarEvents } from '../../api/calendar';
import { SessionContext } from '../../providers/SessionProvider/context';
import usePrevious from '../../hooks/usePrevious';
import { CalendarEvents } from '../../api/types/calendar';
import CalendarDayView from './components/CalendarDayView';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { MEDIA_QUERY_BREAKPOINTS } from '../../utils/responsive/constants';
import { createMediaQuery } from '../../utils/responsive';

const Calendar = () => {
  const navigate = useNavigate();

  const { setIsLoading } = useContext(ActivityIndicatorContext);
  const { currentProfile, isLoggedIn } = useContext(SessionContext);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events, setEvents] = useState<CalendarEvents>({ events: [], eventsMetadata: {} });
  const previousSelectedDate = usePrevious<Date>(selectedDate);

  const isLaptop = useMediaQuery({
    query: createMediaQuery(MEDIA_QUERY_BREAKPOINTS.smallLaptop),
  });
  const isLargePhone = useMediaQuery({
    query: createMediaQuery(MEDIA_QUERY_BREAKPOINTS.largeMobile, true),
  });

  const refreshEvents = async (date: string) => {
    setIsLoading(true);
    const result = await getCalendarEvents(date);
    if (result) {
      setEvents(result);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // todo - fix this refresh issue when refreshing browser page
    if (!isSameMonth(selectedDate, previousSelectedDate)) {
      refreshEvents(selectedDate.toISOString()).then();
    }
  }, [selectedDate, currentProfile]);

  const onSelectDateFromCalendar = (value: Date | null) => {
    if (value !== null) {
      setSelectedDate(value);
    }
  };

  const onPreviousMonthPress = () => {
    setSelectedDate(subtractMonthsFromDate(selectedDate, 1));
  };
  const onNextMonthPress = () => {
    setSelectedDate(addMonthsToDate(selectedDate, 1));
  };

  const onCurrentDayPress = () => {
    setSelectedDate(new Date());
  };

  const onSeeInCasePress = (caseId: string) => {
    navigate(`/dosare/${caseId}`);
  };

  return (
    <ProjectContainer title={'Calendar'}>
      <Components.Container isLaptop={isLaptop}>
        <Components.LeftSideContainer>
          <Components.LeftSideHeader>
            <Components.LeftSideTitleWrapper>
              <Components.LeftSideTitle>
                {parseDateToStringWithFormat(selectedDate, format.monthYear)}
              </Components.LeftSideTitle>
              <Components.ArrowsWrapper>
                <Components.ArrowWrapper onClick={onPreviousMonthPress}>
                  <AngleLeftIcon />
                </Components.ArrowWrapper>
                <Components.ArrowWrapper onClick={onNextMonthPress}>
                  <AngleRightIcon />
                </Components.ArrowWrapper>
              </Components.ArrowsWrapper>
            </Components.LeftSideTitleWrapper>
            <Components.TodayButton onClick={onCurrentDayPress}>
              Ziua curentă
              <ArrowRightIcon />
            </Components.TodayButton>
            <Components.LeftSideSubtitle>
              În calendar, poți vedea toate termenele asociate dosarelor din aplicație. Vei găsi informații despre
              fiecare termen în parte
            </Components.LeftSideSubtitle>
          </Components.LeftSideHeader>
          <ProjectCalendar
            calendarConfig={calendarConfigs.events(
              selectedDate,
              onSelectDateFromCalendar,
              events,
              getStartOfMonth(selectedDate),
              isLargePhone,
            )}
          />
        </Components.LeftSideContainer>
        <Components.RightSideContainer isLaptop={isLaptop}>
          <CalendarDayView date={selectedDate} hearings={events?.events} onSeeInCasePress={onSeeInCasePress} />
        </Components.RightSideContainer>
      </Components.Container>
    </ProjectContainer>
  );
};

export default Calendar;
