import React from 'react';
import { Profile } from '../../api/types/profile';
import { ProjectErrorType } from '../../utils/errors/types';

export interface SessionContextType {
  currentProfile: Profile | undefined;
  isLoggedIn: boolean;
  userIsAdmin: boolean;
  doesVerifyPhoneNumber: boolean;
  cachedPhoneNumber: string | undefined;
  updateCurrentProfile: (profile: Profile) => void;
  login: (email: string, password: string) => Promise<ProjectErrorType | undefined>;
  signUp: (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    phoneNumberCode: string,
  ) => Promise<ProjectErrorType | undefined>;
  recoverPassword: (email: string) => Promise<void>;
  changePassword: (newPassword: string) => Promise<undefined | ProjectErrorType>;
  sendPhoneVerificationCode: () => Promise<ProjectErrorType | undefined>;
  verifyPhoneNumber: (smsCode: string) => Promise<ProjectErrorType | undefined>;
  refreshProfile: () => Promise<void>;
  setSignUpCampaignDetails: (campaign: string, campaignIdentifier: string) => void;
  logout: () => void;
}

export const SessionContext = React.createContext<SessionContextType>({
  currentProfile: undefined,
  isLoggedIn: false,
  userIsAdmin: false,
  doesVerifyPhoneNumber: false,
  cachedPhoneNumber: undefined,
  updateCurrentProfile: () => {},
  login: async () => {
    return undefined;
  },
  signUp: async () => {
    return undefined;
  },
  sendPhoneVerificationCode: async () => {
    return undefined;
  },
  verifyPhoneNumber: async () => {
    return undefined;
  },
  recoverPassword: async () => {},
  changePassword: async () => {
    return undefined;
  },
  refreshProfile: async () => {},
  setSignUpCampaignDetails: (campaign: string, campaignIdentifier: string) => {},
  logout: () => {},
});
