import React, { useContext, useEffect, useState } from 'react';
import { Components } from './styled';
import SecondaryTag from '../../../components/tags/SecondaryTag';
import ProjectContainer from '../../../components/containers/ProjectContainer';
import { useMediaQuery } from 'react-responsive';
import { createMediaQuery } from '../../../utils/responsive';
import { MEDIA_QUERY_BREAKPOINTS } from '../../../utils/responsive/constants';
import { useParams } from 'react-router-dom';
import { ActivityIndicatorContext } from '../../../providers/ActivityIndicatorProvider/context';
import { getCaseById, getCaseLogs } from '../../../api/case';
import { getProjectErrorForCode, isProjectError } from '../../../utils/errors';
import { Case, CaseSyncLog } from '../../../api/types/case';
import { ProjectErrors } from '../../../utils/errors/types';
import { ToastContext } from '../../../providers/ToastProvider/context';
import Placeholder from '../../../components/Placeholder';
import ActivityLogItem from './components/ActivityLogItem';

const CaseActivityLog = () => {
  const { id: initialId } = useParams<{ id: string }>();

  const { setIsLoading } = useContext(ActivityIndicatorContext);
  const { showErrorToast } = useContext(ToastContext);

  const [logs, setLogs] = useState<CaseSyncLog[]>([]);

  const isLaptop = useMediaQuery({
    query: createMediaQuery(MEDIA_QUERY_BREAKPOINTS.smallLaptop),
  });

  const refreshLogs = async () => {
    if (initialId) {
      setIsLoading(true);
      const result = await getCaseLogs(initialId);
      const resultError = isProjectError(result);
      if (resultError) {
        showErrorToast(resultError);
      } else {
        const typedResult = result as CaseSyncLog[];
        const sortedResult = typedResult?.sort((a, b) => b?.createdAt - a?.createdAt);
        setLogs(sortedResult);
      }
      setIsLoading(false);
    } else {
      showErrorToast(getProjectErrorForCode(ProjectErrors.genericTryAgain));
    }
  };

  useEffect(() => {
    refreshLogs().then();
  }, []);

  return (
    <ProjectContainer hasBack>
      <Components.Container isLaptop={isLaptop}>
        <Components.HeaderContainer>
          <Components.HeaderTitleWrapper>
            <Components.HeaderTitle>Activitate Dosar</Components.HeaderTitle>
          </Components.HeaderTitleWrapper>
        </Components.HeaderContainer>
        <Components.BodyContainer>
          {logs && logs?.length ? (
            logs?.map((t, key) => <ActivityLogItem data={t} key={key} />)
          ) : (
            <Placeholder type={'fullHeight'} text={'Nu exista log-uri de afisat'} />
          )}
        </Components.BodyContainer>
      </Components.Container>
    </ProjectContainer>
  );
};

export default CaseActivityLog;
