import React, { useContext } from 'react';
import { MY_ACCOUNT_TABS } from '../../constants';
import { CommonComponents } from '../styled';
import { Components } from './styled';
import { AccountTabPanel } from '../index';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/icon-arrow-right-white.svg';
import { ReactComponent as ArrowRightAltIcon } from '../../../../assets/icons/icon-arrow-right.svg';
import { SessionContext } from '../../../../providers/SessionProvider/context';
import { SUBSCRIPTION_DATA } from './constants';
import { SubscriptionContext } from '../../../../providers/SubsctiptionProvider/context';
import { parseDateToStringWithFormat } from '../../../../utils/dateFormat';
import { format } from '../../../../utils/dateFormat/types';

const BillingTab = ({
  tab,
  onBuySub,
  onCustomerPortalPress,
}: {
  tab: number;
  onBuySub: () => void;
  onCustomerPortalPress: () => void;
}) => {
  const { currentProfile } = useContext(SessionContext);
  const { isPayingUser, isSubscriptionActive } = useContext(SubscriptionContext);

  const subLabel = currentProfile
    ? isSubscriptionActive()
      ? SUBSCRIPTION_DATA[currentProfile?.subscriptionType]?.label
      : SUBSCRIPTION_DATA.expired.label
    : undefined;
  const expirationDate = currentProfile
    ? parseDateToStringWithFormat(currentProfile?.subscriptionEndDate, format.weekDayMonthDayYear)
    : undefined;
  const isPaying = isPayingUser();
  return (
    <AccountTabPanel tab={tab} index={MY_ACCOUNT_TABS.billing.index}>
      <CommonComponents.Header>
        <CommonComponents.Title>Abonament</CommonComponents.Title>
        <CommonComponents.ButtonsContainer />
      </CommonComponents.Header>
      <Components.Container>
        <Components.FieldLabel>Abonament curent</Components.FieldLabel>
        <Components.SubBannerAndButtonWrapper>
          <Components.SubBannerContainer>
            <Components.SubBannerContent>
              <Components.SubTitle>{subLabel}</Components.SubTitle>
              {isSubscriptionActive() && <Components.SubBenefit>Expiră la: {expirationDate}</Components.SubBenefit>}
            </Components.SubBannerContent>
          </Components.SubBannerContainer>
          {!isPaying && (
            <Components.BuySubButton onClick={onBuySub}>
              Cumpără abonamentul Pro
              <ArrowRightIcon />
            </Components.BuySubButton>
          )}
        </Components.SubBannerAndButtonWrapper>
        <Components.FieldLabel>Date de facturare</Components.FieldLabel>
        <Components.CustomerPortalSection>
          <Components.CustomerPortalButton onClick={onCustomerPortalPress}>
            Mergi la Portalul Clientului
            <ArrowRightAltIcon />
          </Components.CustomerPortalButton>
          <Components.CustomerPortalDescription>
            Din Portalul Clientului, poți modifica datele de facturare, poți anula abonamentul curent, și poți vedea
            facturile asociate contului tău
          </Components.CustomerPortalDescription>
        </Components.CustomerPortalSection>
      </Components.Container>
    </AccountTabPanel>
  );
};

export default BillingTab;
