import {
  addMinutes,
  format,
  formatDistance,
  Locale,
  startOfMonth,
  startOfToday,
  addMonths,
  subMonths,
  isSameDay as isSameDayMethod,
  isYesterday as isYesterdayMethod,
  isSameWeek as isSameWeekMethod,
  isSameMonth as isSameMonthMethod,
  isSameYear as isSameYearMethod,
  differenceInYears,
  differenceInDays,
  isFuture,
} from 'date-fns';
import { DateFormatType } from './types';
import { ro } from 'date-fns/locale';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import { format as dateFormat } from './types';

export const parseDateToStringWithFormat = (
  date: Date | number | string,
  formatType: DateFormatType,
  locale?: Locale,
) => {
  if (typeof date === 'number' || typeof date === 'string') {
    const _date = new Date(date);
    return format(_date, formatType, locale ? { locale } : { locale: ro });
  }
  return format(date, formatType, locale ? { locale } : { locale: ro });
};

export const getPastMidnightUTC = (date: Date | string | number) => {
  const _date = new Date(date);
  return new Date(_date).setHours(0, 0, 0, 0);
};

export const mergeDateAndMinutes = (dateValue: Date | null, timeValue: number | undefined): Date | null => {
  return (
    (dateValue !== null &&
      timeValue !== undefined &&
      new Date(new Date(getPastMidnightUTC(dateValue))?.getTime() + (timeValue ? timeValue * 60 * 1000 : 0))) ||
    null
  );
};

export const parseTimeFromMinuteAndHour = (minute: number, hour: number) => {
  return hour + ':' + (minute / 10 === 0 ? '0' : '') + minute;
};

export const parseMinutesToHourAndMinutes = (value: number): string => {
  const date = new Date(startOfToday().getTime() + value * 60 * 1000);
  return parseDateToStringWithFormat(date, 'hh:mm aa');
};

export const getMonthFromDate = (date: Date): string => {
  return parseDateToStringWithFormat(date, dateFormat.month);
};

export const getStartOfMonth = (date: Date): Date => {
  return startOfMonth(date);
};

export const addDaysToDate = (date: Date, amount: number) => {
  return addDays(date, amount);
};

export const addMonthsToDate = (date: Date, amount: number) => {
  return addMonths(date, amount);
};

export const addMinutesToDate = (date: Date | string | number, amount: number): Date => {
  const _date = new Date(date);
  return addMinutes(_date, amount);
};

export const subtractDaysFromDate = (date: Date, amount: number) => {
  return subDays(date, amount);
};
export const subtractMonthsFromDate = (date: Date, amount: number) => {
  return subMonths(date, amount);
};

export const isSameDay = (firstDate: Date | number | string, secondDate: Date | number | string) => {
  const _firstDate = new Date(firstDate);
  const _secondDate = new Date(secondDate);
  return isSameDayMethod(_firstDate, _secondDate);
};
export const isYesterday = (firstDate: Date | number | string) => {
  const _firstDate = new Date(firstDate);
  return isYesterdayMethod(_firstDate);
};
export const isSameWeek = (firstDate: Date | number | string, secondDate: Date | number | string) => {
  const _firstDate = new Date(firstDate);
  const _secondDate = new Date(secondDate);
  return isSameWeekMethod(_firstDate, _secondDate);
};
export const isSameMonth = (firstDate: Date | number | string, secondDate: Date | number | string) => {
  const _firstDate = new Date(firstDate);
  const _secondDate = new Date(secondDate);
  return isSameMonthMethod(_firstDate, _secondDate);
};
export const isSameYear = (firstDate: Date | number | string, secondDate: Date | number | string) => {
  const _firstDate = new Date(firstDate);
  const _secondDate = new Date(secondDate);
  return isSameYearMethod(_firstDate, _secondDate);
};

export const getPartOfTheDay = () => {
  const currentHour = parseInt(format(new Date(), 'k'));
  if (currentHour >= 6 && currentHour < 12) {
    return 'morning';
  }
  if (currentHour >= 12 && currentHour < 16) {
    return 'day';
  }
  if (currentHour >= 16 && currentHour < 18) {
    return 'afternoon';
  }
  if (currentHour >= 18 && currentHour < 20) {
    return 'evening';
  }
  return 'night';
};

export const parseTime = (minutes: number) => {
  let result;
  if (minutes >= 60) {
    result = minutes / 60 + ' hr';
    if (minutes % 60) {
      result = ' ' + (minutes % 60) + ' min';
    }
    return result;
  }

  if (minutes % 60) {
    result = (minutes % 60) + ' min';
  }
  return result;
};

export const getTimeDifferenceBetweenDates = (firstDate: Date | number, secondDate: Date | number) => {
  const _firstDate = typeof firstDate === 'number' ? new Date(firstDate) : firstDate;
  const _secondDate = typeof secondDate === 'number' ? new Date(secondDate) : secondDate;

  if (!_firstDate || !_secondDate) {
    return undefined;
  }
  return _firstDate.getTime() - _secondDate.getTime();
};

export const parseLocaleTime = (date: string) => {
  const _date = new Date(date);
  return format(_date, 'h:mm a');
};

export const formatDistanceFromDate = (dateToBeCompared: Date) => {
  return formatDistance(subDays(dateToBeCompared, 0), new Date(), { addSuffix: true, includeSeconds: true });
};

export const getDifferanceBetweenNowAndADate = (date: Date) => {
  return new Date().getTime() - date.getTime();
};

export const getDifferenceInYears = (firstDate: string | Date, secondDate: string | Date) => {
  const firstProcessedDate = typeof firstDate === 'string' ? new Date(firstDate) : firstDate;
  const secondProcessedDate = typeof secondDate === 'string' ? new Date(secondDate) : secondDate;
  return differenceInYears(firstProcessedDate, secondProcessedDate);
};

export const getDifferenceInDays = (firstDate: string | Date, secondDate: string | Date) => {
  const firstProcessedDate = typeof firstDate === 'string' ? new Date(firstDate) : firstDate;
  const secondProcessedDate = typeof secondDate === 'string' ? new Date(secondDate) : secondDate;
  return differenceInDays(firstProcessedDate, secondProcessedDate);
};

export const getMinutesFromDate = (date: Date | string | number) => {
  const _date = new Date(date);
  return _date.getHours() * 60 + _date.getMinutes();
};

export const getHoursFromDate = (date: Date | string | number) => {
  const _date = new Date(date);
  return _date.getHours();
};

export const getDayOfMonthFromDate = (date: Date | string | number) => {
  const _date = new Date(date);
  return _date.getDate();
};

export const getMonthNumberFromDate = (date: Date | string | number) => {
  const _date = new Date(date);
  return _date.getMonth();
};

export const getYearFromDate = (date: Date | string | number) => {
  const _date = new Date(date);
  return _date.getFullYear();
};

export const isDateInFuture = (date: Date | string | number): boolean => {
  const _date = new Date(date);
  return isFuture(_date);
};
