import React from 'react';
import { SavedCaseParty } from '../../api/types/portal';

export interface CaseContextType {
  saveCaseParty: (savedParty: SavedCaseParty) => Promise<void>;
}

export const CaseContext = React.createContext<CaseContextType>({
  saveCaseParty: async () => {},
});
