import React from 'react';
import { ProjectErrorType } from '../../utils/errors/types';

export type ToastContextType = {
  showToast: (message: string) => void;
  showSuccessToast: (message: string) => void;
  showErrorToast: (error: ProjectErrorType) => void;
  showWarningToast: (error: ProjectErrorType | string) => void;
  dismissToast: () => void;
};

export const ToastContext = React.createContext<ToastContextType>({
  showToast: () => {},
  showSuccessToast: () => {},
  showErrorToast: () => {},
  showWarningToast: () => {},
  dismissToast: () => {},
});
