import React from 'react';
import { ProjectErrors, ProjectErrorType } from '../../utils/errors/types';
import { getProjectErrorForCode } from '../../utils/errors';

export interface SubscriptionContextType {
  createCheckoutSession: (productPriceId: string) => Promise<string | ProjectErrorType>;
  createCustomerPortalSession: () => Promise<string | ProjectErrorType>;
  isPayingUser: () => boolean;
  isActiveTrial: () => boolean;
  isSubscriptionActive: () => boolean;
  isThreeDaysBeforeTrialExpiration: () => boolean;
  isExpiredTrial: () => boolean;
}

export const SubscriptionContext = React.createContext<SubscriptionContextType>({
  createCheckoutSession: async (productPriceId: string) => {
    return getProjectErrorForCode(ProjectErrors.genericTryAgain);
  },
  createCustomerPortalSession: async () => {
    return getProjectErrorForCode(ProjectErrors.genericTryAgain);
  },
  isPayingUser: () => {
    return false;
  },
  isActiveTrial: () => {
    return false;
  },
  isSubscriptionActive: () => {
    return false;
  },
  isThreeDaysBeforeTrialExpiration: () => {
    return false;
  },
  isExpiredTrial: () => {
    return false;
  },
});
