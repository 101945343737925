export const MY_ACCOUNT_TABS = {
  profile: {
    index: 0,
    label: 'Profil',
  },
  notifications: {
    index: 1,
    label: 'Notificări',
  },
  billing: {
    index: 2,
    label: 'Abonament',
  },
};
