import styled from 'styled-components';
import AppCSS from '../../utils/styled';
import { NAVIGATION_BAR_HEIGHT } from '../../utils/styled/dimensions';
import { UIView } from '../../utils/styled/styled';
import { ReactComponent as ClockIcon } from '../../assets/icons/icon-clock.svg';
import Clock from 'react-live-clock';
import { ReactComponent as AddIcon } from '../../assets/icons/icon-add.svg';

export const Components = {
  Container: styled.div<{ isOnMobile: boolean }>`
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    height: ${NAVIGATION_BAR_HEIGHT}px;
    min-height: ${NAVIGATION_BAR_HEIGHT}px;
    background-color: ${AppCSS.colors.utility.headerBar.background};
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px ${(p) => (p.isOnMobile ? 16 : 24)}px;
    z-index: 99;
  `,
  LeftSideWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  `,
  MenuIconWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,
  LeftSideTitle: styled.span<{ isClickable?: boolean }>`
    ${AppCSS.fonts.h3};
    color: ${AppCSS.colors.greens.green90};
    ${({ isClickable }) => isClickable && 'cursor: pointer;'};
  `,
  LeftSideIconWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    min-width: max-content;
  `,
  BackButton: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: ${AppCSS.colors.primaryGreenGoth};
    ${AppCSS.fonts.h3};
    cursor: pointer;
  `,
  RightSectionWrapper: styled(UIView)`
    gap: 16px;
    height: 100%;
  `,
  Separator: styled.div`
    width: 1px;
    height: 100%;
    background-color: ${AppCSS.colors.greens.green50};
  `,
  ClockWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  `,
  ClockIcon: styled(ClockIcon)`
    height: 20px;
    width: 20px;
  `,
  Clock: styled(Clock)`
    ${AppCSS.fonts.label12Bold};
    color: ${AppCSS.colors.greens.green80};
  `,
  DateLabel: styled.span`
    ${AppCSS.fonts.label12Medium};
    color: ${AppCSS.colors.greens.green80};
  `,
  IconWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,
  AddIcon: styled(AddIcon)`
    width: 24px;
    height: 24px;
  `,
  ProfileWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
  `,
  ProfileLabel: styled.span`
    ${AppCSS.fonts.label16Medium};
    color: ${AppCSS.colors.greens.green90};
  `,
};
