export enum ProceduralStageEnum {
  'Fond' = 'Fond',
  'Apel' = 'Apel',
  'Recurs' = 'Recurs',
  'Recursinanulare' = 'Recursinanulare',
  'Recursininteresullegii' = 'Recursininteresullegii',
  'Sesizareprealabila' = 'Sesizareprealabila',
  'ContestaţieNCPP' = 'ContestaţieNCPP',
  'Recursincasatie' = 'Recursincasatie',
  'ContestatieICCJ' = 'ContestatieICCJ',
  'ContestatieInAnulareNCPP' = 'ContestatieInAnulareNCPP',
  'RevizuireContestatieNCPP' = 'RevizuireContestatieNCPP',
  'Contestatie' = 'Contestatie',
  'Revizuire' = 'Revizuire',
  'Contestatieinanulare' = 'Contestatieinanulare',
  'Stabilireacompetentei' = 'Stabilireacompetentei',
  'Recursimpotrivaincheierii' = 'Recursimpotrivaincheierii',
  'Recuzare' = 'Recuzare',
  'Stramutare' = 'Stramutare',
  'Indreptareeroaremateriala' = 'Indreptareeroaremateriala',
  'ContestatieinanulareFond' = 'ContestatieinanulareFond',
  'ContestatieinanulareApel' = 'ContestatieinanulareApel',
  'ContestatieinanulareRecurs' = 'ContestatieinanulareRecurs',
  'RevizuireFond' = 'RevizuireFond',
  'RevizuireApel' = 'RevizuireApel',
  'RevizuireRecurs' = 'RevizuireRecurs',
}
