import styled from 'styled-components';
import AppCSS from '../../../../utils/styled';

export const Components = {
  Container: styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  Title: styled.span`
    ${AppCSS.fonts.label14Regular};
    color: ${AppCSS.colors.greens.green90};
  `,
  Id: styled.div`
    ${AppCSS.fonts.label10Regular};
    color: ${AppCSS.colors.greens.green70};
  `,
};
