export const format = {
  dayOfMonthNumber: 'd',
  isoDayOfWeek: 'iii',
  shortDay: 'EEEEE',
  monthDayYear: 'd MMMM, yyyy',
  shortMonthDayYear: 'MMM d, yyyy',
  monthYear: 'MMMM, y',
  shortMonthAndYear: 'MMM yy',
  weekDayMonthDay: 'EEEE, d MMM',
  weekDayMonthDayYear: 'EEEE, MMM d, yyyy',
  shortWeekDayLongMonthShortYear: 'E, MMMM yy',
  yearMonthday: 'yyyy, MMM d',
  monthDay: 'MMM d',
  weekDay: 'EEE',
  month: 'MMMM',
  monthDayYearTime: 'PPpp',
  weekDayMonthDayYearTime: 'EEEE, LLL d yyyy H:mm',
  hourMinutesAmPm: 'hh:mm aaa',
  hourMinutesAmPmCapitalized: 'hh:mm aa',
  hourMinutes: 'HH:MM',
  hourMinutesSeconds: 'hh:mm:ss',
  hourAmPm: 'h aaa',
  hour: 'h',
  minutesSeconds: 'mm:ss',
  dayMonthYear: 'd MMM yyyy',
  readableDayMonthYear: 'd-MMM-yyyy',
  period: 'aaa',
  weekDayMonthDayOfMonth: 'E, MMMM do',
  dayMonthYearAlternative: 'dd-MM-yyyy',
} as const;

export type DateFormatType =
  | typeof format.dayOfMonthNumber
  | typeof format.isoDayOfWeek
  | typeof format.monthDay
  | typeof format.monthDayYear
  | typeof format.shortMonthDayYear
  | typeof format.monthYear
  | typeof format.shortMonthAndYear
  | typeof format.weekDayMonthDay
  | typeof format.weekDayMonthDayYear
  | typeof format.yearMonthday
  | typeof format.weekDay
  | typeof format.month
  | typeof format.monthDayYearTime
  | typeof format.weekDayMonthDayYearTime
  | typeof format.shortDay
  | typeof format.hourMinutesAmPm
  | typeof format.hourAmPm
  | typeof format.shortWeekDayLongMonthShortYear
  | typeof format.dayMonthYear
  | typeof format.hourMinutesAmPmCapitalized
  | typeof format.hour
  | typeof format.readableDayMonthYear
  | typeof format.hourMinutes
  | typeof format.period
  | typeof format.minutesSeconds
  | typeof format.weekDayMonthDayOfMonth
  | typeof format.hourMinutesSeconds
  | typeof format.dayMonthYearAlternative;
