import React, { ChangeEvent } from 'react';
import { Components } from './styled';
import { ReactComponent as RomanianFlag } from '../../../assets/icons/icon-romanian-flag.svg';

const ProjectPhoneNumberField = ({
  value,
  onChange,
  placeholder,
  isDisabled,
}: {
  value: string | undefined;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  isDisabled?: boolean;
}) => {
  return (
    <Components.Container isDisabled={isDisabled}>
      <Components.FlagWrapper isDisabled={isDisabled}>
        <RomanianFlag />
      </Components.FlagWrapper>
      <Components.CountryPhoneCode isDisabled={isDisabled}>+40</Components.CountryPhoneCode>
      <Components.TextField
        value={value || ''}
        onChange={onChange}
        placeholder={placeholder || '000000000'}
        type={'text'}
        disabled={isDisabled}
        isDisabled={isDisabled}
      />
    </Components.Container>
  );
};

export default ProjectPhoneNumberField;
