import React from 'react';
import { PortalCaseParty } from '../../../../api/types/portal';
import Parties from '../../../caseComponents/Parties';

const TableParties = ({ params }: { params?: any }) => {
  const { row }: { row: any } = params;
  const parties = row?.parties as PortalCaseParty[];

  return <Parties parties={parties} wrapContent={false} />;
};

export default TableParties;
