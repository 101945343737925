import React from 'react';
import { Components } from './styled';

import { MY_ACCOUNT_TABS } from '../../constants';

const AccountTabs = () => {
  return (
    <Components.TabsList>
      <Components.Tab>{MY_ACCOUNT_TABS.profile.label}</Components.Tab>
      <Components.Tab>{MY_ACCOUNT_TABS.notifications.label}</Components.Tab>
      <Components.Tab>{MY_ACCOUNT_TABS.billing.label}</Components.Tab>
    </Components.TabsList>
  );
};

export default AccountTabs;
