import styled from 'styled-components';
import AppCSS from '../../../../utils/styled';

export const Components = {
  Container: styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 24px;
    grid-row-gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
  `,
  FieldLabel: styled.span`
    ${AppCSS.fonts.label12Bold};
    color: ${AppCSS.colors.greens.green80};
    margin-top: 16px;
  `,
  SubBannerAndButtonWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
  `,
  SubBannerContainer: styled.div`
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    ${AppCSS.radiuses.default};
    border: 1px solid ${AppCSS.colors.greens.green50};
    background-color: ${AppCSS.colors.greens.green40};
  `,
  SubBannerContent: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    padding: 12px 32px;
  `,
  SubTitle: styled.span`
    ${AppCSS.fonts.h4};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  SubBenefitsWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
  `,
  SubBenefit: styled.span`
    ${AppCSS.fonts.label12Medium};
    color: ${AppCSS.colors.primaryMatteBlack};
  `,
  BuySubButton: styled(AppCSS.buttonStyles.PrimaryButtonWithIconStyle)`
    padding: 10px 8px 10px 12px;
  `,
  CustomerPortalSection: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 10px;
    gap: 10px;
  `,
  CustomerPortalDescription: styled.span`
    ${AppCSS.fonts.paragraph12Regular};
    color: ${AppCSS.colors.greens.green80};
  `,
  CustomerPortalButton: styled(AppCSS.buttonStyles.TertiaryButtonStyle)``,
};
