import React from 'react';
import SecondaryTag from '../../../tags/SecondaryTag';

const ProceduralStage = ({ params }: { params?: any }) => {
  const { row }: { row: any } = params;
  const proceduralStage = row?.proceduralStage || 'Necunoscut';

  return <SecondaryTag text={proceduralStage} />;
};

export default ProceduralStage;
