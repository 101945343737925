import { auth } from '../firebase';
import * as yup from 'yup';
import { AnySchema, ObjectSchema } from 'yup';
import { User } from '@firebase/auth';
import { Profile } from './types/profile';

export const createQueryStringFromHash = (queryParams?: { [_: string]: string }) => {
  const result =
    queryParams &&
    Object.entries(queryParams).reduce((add, [key, value]) => {
      let string = '';

      if (Array.isArray(value)) {
        string =
          value !== undefined && value !== null
            ? `${add}${value.reduce((sec, x) => {
                return x
                  ? `${sec}${add.length > 0 || sec.length > 0 ? '&' : '?'}${encodeURIComponent(
                      key,
                    )}[]=${encodeURIComponent(x)}`
                  : sec;
              }, '')}`
            : add;
        return string;
      }
      string =
        value !== undefined && value !== null
          ? `${add}${add.length > 0 ? '&' : '?'}${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          : add;
      return string;
    }, '');
  return result || '';
};

export const createAuthHeaders = async (user?: User): Promise<{ [_: string]: string }> => {
  let currentToken;
  try {
    if (user) {
      currentToken = await user?.getIdToken();
    } else if (auth.currentUser) {
      currentToken = await auth.currentUser?.getIdToken();
    }
  } catch (error) {
    return {};
  }
  return currentToken && currentToken?.length > 0 ? { Authorization: `Bearer ${currentToken}` } : {};
};

export const isValidYupSchema = async (schema: ObjectSchema<any>, value: any): Promise<boolean> => {
  return !!(await schema.validate(value, { strict: true }));
};

export const yupUtils = {
  stringRequired: yup.string().required(),
  stringRequiredOneOf: (arrayEnum: readonly string[]) =>
    yup
      .string()
      .oneOf([...arrayEnum])
      .required(),
  stringOptionalNullableOneOf: (arrayEnum: readonly string[]) =>
    yup
      .mixed()
      .oneOf([...arrayEnum, null])
      .optional(),
  stringOptionalNullable: yup.string().optional().nullable(),
  booleanRequired: yup.boolean().required(),
  booleanOptional: yup.boolean().optional(),
  arrayOptionalNullableOf: (schema: AnySchema) => yup.array().of(schema).optional().nullable(),
  arrayRequiredOf: (schema: AnySchema) => yup.array().of(schema).required(),
  arrayCompactRequiredOf: (schema: AnySchema) =>
    yup
      .array()
      .of(schema)
      .compact((v) => !v.checked)
      .required(),
  numberRequired: yup.number().required(),
  numberOptionalNullable: yup.number().optional().nullable(),
};

export const sortByCreatedAt = (data: Profile[]) => {
  return data?.sort((a: Profile, b: Profile) => b?.createdAt?.getTime() - a?.createdAt?.getTime());
};
