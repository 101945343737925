type EnvironmentOptions = {
  isProduction?: boolean;
  firebaseConfig: { [key: string]: string };
  baseUrl: string;
};

const development: EnvironmentOptions = {
  isProduction: false,
  baseUrl: 'http://localhost:8080',
  firebaseConfig: {
    apiKey: 'AIzaSyAU50c3sppT8V1qy1N1ZVCPFTra47U-3k8',
    authDomain: 'juridiq-dashboard-staging.firebaseapp.com',
    projectId: 'juridiq-dashboard-staging',
    storageBucket: 'juridiq-dashboard-staging.appspot.com',
    messagingSenderId: '920506811227',
    appId: '1:920506811227:web:2966bdad31902fca47d07e',
    measurementId: 'G-LVM3X0GC4Z',
  },
};

const staging: EnvironmentOptions = {
  isProduction: false,
  baseUrl: 'https://juridiq-backend-staging-300241016421.europe-central2.run.app/',
  firebaseConfig: {
    apiKey: 'AIzaSyAU50c3sppT8V1qy1N1ZVCPFTra47U-3k8',
    authDomain: 'juridiq-dashboard-staging.firebaseapp.com',
    projectId: 'juridiq-dashboard-staging',
    storageBucket: 'juridiq-dashboard-staging.appspot.com',
    messagingSenderId: '920506811227',
    appId: '1:920506811227:web:2966bdad31902fca47d07e',
    measurementId: 'G-LVM3X0GC4Z',
  },
};

const production: EnvironmentOptions = {
  isProduction: true,
  baseUrl: 'https://api.juridiq.ro',
  firebaseConfig: {
    apiKey: 'AIzaSyAaQRLu7N5wynpsAZuss8Og16sNOW6kndA',
    authDomain: 'juridiq-dashboard.firebaseapp.com',
    projectId: 'juridiq-dashboard',
    storageBucket: 'juridiq-dashboard.appspot.com',
    messagingSenderId: '387992059388',
    appId: '1:387992059388:web:ce45acdc7d9d104c5d8679',
    measurementId: 'G-SC5S4E9SM8',
  },
};

const productionWithLocalBackend: EnvironmentOptions = {
  ...production,
  baseUrl: 'http://localhost:8080',
};

export default production;
