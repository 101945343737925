import styled from 'styled-components';
import AppCSS from '../../../../../../utils/styled';

export const Components = {
  Container: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 12px;
    gap: 12px;
    ${AppCSS.radiuses.default};
    border: 1px solid ${AppCSS.colors.greens.green50};
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  `,
  HeaderWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
  `,
  Title: styled.span<{ isDone: boolean }>`
    ${({ isDone }) =>
      isDone
        ? `
        ${AppCSS.fonts.label16Medium};
        color: ${AppCSS.colors.greens.green70};
        text-decoration: line-through;
      `
        : `
        
        ${AppCSS.fonts.label16Bold};
        color: ${AppCSS.colors.primaryMatteBlack};
    `};
  `,
  Subtitle: styled.span`
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green80};
  `,
  Button: styled(AppCSS.buttonStyles.TertiaryButtonStyle)``,
};
