import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { ActivityIndicatorContext } from '../providers/ActivityIndicatorProvider/context';

const useModalState = <T>(): {
  isModalOpen: boolean;
  onModalClose: () => void;
  open: (t: T) => void;
  fetchAndOpen: (value: string | undefined, fetchAction: (value: string) => Promise<T | undefined>) => Promise<boolean>;
  modalState: T | undefined;
  setModalState: React.Dispatch<T | undefined>;
} => {
  const { setIsLoading } = useContext(ActivityIndicatorContext);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [extraData, setExtraData] = useState<T | undefined>();

  useEffect(() => {
    if (!isModalOpen) {
      setTimeout(() => {
        setExtraData(undefined);
      }, 350);
    }
  }, [isModalOpen]);

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const open = (t: T) => {
    setExtraData(t);
    setIsModalOpen(true);
  };

  const fetchAndOpen = async (
    value: string | undefined,
    callback: (value: string) => Promise<T | undefined>,
  ): Promise<boolean> => {
    if (value && value?.length > 0) {
      setIsLoading(true);
      const result = await callback?.(value);
      setIsLoading(false);
      if (result) {
        open(result);
        return true;
      }
    }
    return false;
  };

  return {
    isModalOpen,
    onModalClose,
    modalState: extraData,
    setModalState: setExtraData,
    open,
    fetchAndOpen,
  };
};

export default useModalState;
