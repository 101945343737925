/* eslint-disable @typescript-eslint/no-explicit-any */
import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

const Emitter = {
  addEventListener: (event: any, fn: any) => eventEmitter.on(event, fn),
  removeEventListener: (event: any, fn?: any) => eventEmitter.off(event, fn),
  emit: (event: any, payload: any) => eventEmitter.emit(event, payload),
};

Object.freeze(Emitter);

export default Emitter;
