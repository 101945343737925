import styled from 'styled-components';
import AppCSS from '../../../../../utils/styled';
import { DefaultModalComponents } from '../../../../../components/ProjectModal/utils';

export const Components = {
  ModalButtonsContainer: styled.div`
    width: 100%;
    grid-gap: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `,
  CancelButton: styled(AppCSS.buttonStyles.TertiaryButtonStyle)``,
  Button: styled(AppCSS.buttonStyles.SecondaryButtonStyle)``,
  Label: styled.span`
    ${AppCSS.fonts.label16Bold};
    color: ${AppCSS.colors.primaryMatteBlack};
    margin-bottom: 12px;
  `,
  ...DefaultModalComponents,
};
