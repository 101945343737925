import React from 'react';
import { Components } from './styled';
import { OnboardingStepStatus } from '../../../../../../providers/OnboardingProvider/types';
import { ReactComponent as ArrowRightIcon } from '../../../../../../assets/icons/icon-arrow-right.svg';
import { ReactComponent as CheckActiveIcon } from '../../../../../../assets/icons/icon-check-filled-active.svg';
import { ReactComponent as CheckInactiveIcon } from '../../../../../../assets/icons/icon-check-filled-inactive.svg';

const OnboardingStep = ({
  status,
  title,
  subtitle,
  onButtonPress,
  buttonLabel,
}: {
  status: OnboardingStepStatus;
  title: string;
  subtitle: string;
  onButtonPress?: () => void;
  buttonLabel?: string;
}) => {
  return (
    <Components.Container>
      {status === 'done' ? <CheckActiveIcon /> : <CheckInactiveIcon />}
      <Components.Content>
        <Components.HeaderWrapper>
          <Components.Title isDone={status === 'done'}>{title}</Components.Title>
          {status !== 'done' && <Components.Subtitle>{subtitle}</Components.Subtitle>}
        </Components.HeaderWrapper>
        {status === 'current' && buttonLabel && onButtonPress && (
          <Components.Button onClick={onButtonPress}>
            {buttonLabel}
            <ArrowRightIcon />
          </Components.Button>
        )}
      </Components.Content>
    </Components.Container>
  );
};

export default OnboardingStep;
