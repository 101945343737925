import styled from 'styled-components';
import AppCSS from '../../../utils/styled';

export const Components = {
  Container: styled.div<{ isDisabled?: boolean }>`
    position: relative;
    width: 100%;
    border: 1px solid ${AppCSS.colors.greens.green60};
    ${AppCSS.radiuses.small};
    background-color: ${AppCSS.colors.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${({ isDisabled }) =>
      isDisabled &&
      `
      background-color: ${AppCSS.colors.greens.green40};
    `}
  `,
  TextField: styled.input<{ isDisabled?: boolean }>`
    flex: 1;
    width: 100%;
    padding: 12px 12px 12px 72px;
    height: 38px;
    ${AppCSS.fonts.label12Medium};
    color: ${AppCSS.colors.greens.green90};
    background-color: transparent;
    ${({ isDisabled }) =>
      isDisabled &&
      `
      color: ${AppCSS.colors.greens.green70};
    `}
    border: none;
    -webkit-appearance: none;
    z-index: 1;
    &:focus-visible {
      -webkit-appearance: none;
      border: none;
      outline: none;
    }
    &:focus {
      -webkit-appearance: none;
      border: none;
      outline: none;
    }
    &::placeholder {
      color: ${AppCSS.colors.greens.green60};
    }
  `,
  FlagWrapper: styled.div<{ isDisabled?: boolean }>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    ${({ isDisabled }) =>
      isDisabled &&
      `
      opacity: 0.6;
    `}
  `,
  CountryPhoneCode: styled.span<{ isDisabled?: boolean }>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${AppCSS.fonts.label12Medium};
    color: ${AppCSS.colors.greens.green80};
    z-index: 0;
    ${({ isDisabled }) =>
      isDisabled &&
      `
      color: ${AppCSS.colors.greens.green70};
    `}
  `,
};
