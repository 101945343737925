import styled from 'styled-components';
import Select from '@mui/material/Select';
import AppCSS from '../../../utils/styled';
import { MAX_SELECT_HEIGHT } from './constants';

export const Components = {
  Select: styled(Select)`
    width: 100%;
    color: ${AppCSS.colors.primaryMatteBlack};
    ${AppCSS.radiuses.small};
    border: 1px solid ${AppCSS.colors.greens.green60};
    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    & .MuiOutlinedInput-input {
      ${AppCSS.fonts.label12Medium};
      padding-left: 12px;
      padding-right: 8px !important;
      padding-top: 0;
      padding-bottom: 0;
      min-height: auto;
      white-space: break-spaces;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    & .MuiSelect-icon {
      right: 12px;
    }
    ${AppCSS.fonts.label12Medium};
    background-color: ${AppCSS.colors.white};
    height: 40px;
  `,
  SelectIcon: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin-right: 12px;
  `,
};

export const styles = {
  SelectMenu: `
    & .MuiPaper-root {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: ${MAX_SELECT_HEIGHT}px !important;
      ${AppCSS.boxShadows.menu};
      & .MuiList-root {
        padding: 0;
      }
      & .MuiMenuItem-root {
        ${AppCSS.fonts.label12Regular};
        color: ${AppCSS.colors.primaryMatteBlack};
        padding: 12px;
        min-width: 200px;
        border-bottom: 1px solid ${AppCSS.colors.greens.green40};
        &:hover {
          background-color: ${AppCSS.colors.utility.screenBackground};
        }
      }
      & .MuiMenuItem-root:last-child {
        border-bottom: 0;
      }
    }
  `,
};
