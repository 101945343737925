import { CalendarEvents } from '../../../api/types/calendar';
import { parseDateToStringWithFormat } from '../../../utils/dateFormat';
import { EVENT_METADATA_DATE_FORMAT } from '../../../defines';

export const hasHearings = (date: Date, events: CalendarEvents): boolean => {
  const key = parseDateToStringWithFormat(date, EVENT_METADATA_DATE_FORMAT);
  return events?.eventsMetadata
    ? key in events?.eventsMetadata
      ? events?.eventsMetadata?.[key]?.hasHearings === true
      : false
    : false;
};
