import React from 'react';
import Providers from './providers';
import ProjectRoutes from './router';

function App() {
  return (
    <Providers>
      <ProjectRoutes />
    </Providers>
  );
}

export default App;
