import React, { useContext, useEffect } from 'react';
import { Components as LocalComponents } from './styled';
import { Components } from '../styled';
import { ToastContext } from '../../../providers/ToastProvider/context';
import { ActivityIndicatorContext } from '../../../providers/ActivityIndicatorProvider/context';
import { SessionContext } from '../../../providers/SessionProvider/context';
import useFormTextState from '../../../hooks/useFormTextState';
import ProjectSimpleContainer from '../../../components/containers/ProjectSimpleContainer';
import { ReactComponent as AuthContainerBackground } from '../../../assets/illustrations/illustration-auth-bg.svg';
import ProjectTextField from '../../../components/inputs/ProjectTextField';
import { UIView } from '../../../utils/styled/styled';
import { isProjectError } from '../../../utils/errors';
import { ReactComponent as WhatsAppIcon } from '../../../assets/icons/icon-whatsapp.svg';

const VerifyPhoneNumber = () => {
  const { showWarningToast, showErrorToast, showToast } = useContext(ToastContext);
  const { setIsLoading } = useContext(ActivityIndicatorContext);
  const { sendPhoneVerificationCode, verifyPhoneNumber, cachedPhoneNumber } = useContext(SessionContext);

  const smsCodeProps = useFormTextState();

  const onConfirmCode = async () => {
    if (!(smsCodeProps.value && smsCodeProps.value?.length > 0)) {
      showWarningToast('Codul de verificare introdus este invalid');
      return;
    }
    setIsLoading(true);
    const result = await verifyPhoneNumber(smsCodeProps.value);
    const resultError = isProjectError(result);
    if (resultError) {
      showErrorToast(resultError);
    }
    setIsLoading(false);
  };

  const onResendCode = async () => {
    setIsLoading(true);
    const result = await sendPhoneVerificationCode();
    const resultError = isProjectError(result);
    if (resultError) {
      showErrorToast(resultError);
    } else {
      showToast('Un cod de 7 caractere a fost trimis la numărul de telefon introdus');
    }
    setIsLoading(false);
  };

  return (
    <ProjectSimpleContainer>
      <Components.Container>
        <Components.LeftSideWrapper>
          <Components.LeftSideBackgroundImageWrapper>
            <AuthContainerBackground height='100%' width='100%' />
          </Components.LeftSideBackgroundImageWrapper>
          <Components.LogoSquare />
          <Components.LeftSideFooterWrapper>
            <Components.FooterTitle>Secretarul tău digital.</Components.FooterTitle>
            <Components.FooterSubtitle>Ai ajuns pe platforma juriștilor inteligenți</Components.FooterSubtitle>
          </Components.LeftSideFooterWrapper>
        </Components.LeftSideWrapper>
        <Components.RightSideWrapper>
          <Components.RightSideHeaderWrapper>
            <Components.HeaderTitle>Verifică numărul de telefon!</Components.HeaderTitle>
            <LocalComponents.HeaderSubtitle>
              Vei primi un{' '}
              <LocalComponents.HeaderSubtitleAccent>cod de 7 caractere</LocalComponents.HeaderSubtitleAccent> pe{' '}
              <LocalComponents.WhatsAppLabelWrapper>
                <LocalComponents.WhatsAppIconWrapper>
                  <WhatsAppIcon />
                </LocalComponents.WhatsAppIconWrapper>{' '}
                <LocalComponents.HeaderSubtitleAccent>WhatsApp</LocalComponents.HeaderSubtitleAccent>
              </LocalComponents.WhatsAppLabelWrapper>{' '}
              la numărul de telefon introdus la pasul anterior. Codul va fi folosit pentru securitatea contului tău,
              notificări pe aceiași platformă, și multe altele.
            </LocalComponents.HeaderSubtitle>
          </Components.RightSideHeaderWrapper>
          <Components.RightSideContentWrapper>
            <Components.MultipleFieldsWrapper>
              <Components.FieldWrapper>
                <Components.FieldLabel>
                  Cod primit pe{' '}
                  <LocalComponents.WhatsAppIconWrapper>
                    <WhatsAppIcon />
                  </LocalComponents.WhatsAppIconWrapper>{' '}
                  <LocalComponents.HeaderSubtitleAccent>WhatsApp</LocalComponents.HeaderSubtitleAccent>
                </Components.FieldLabel>
                <ProjectTextField
                  value={smsCodeProps.value}
                  onChangeText={smsCodeProps.onChangeText}
                  placeholder={'Introduceți codul primit'}
                />
              </Components.FieldWrapper>
            </Components.MultipleFieldsWrapper>
            <Components.CallToActionWrapper>
              <Components.CallToActionButton onClick={onConfirmCode}>Confirmă</Components.CallToActionButton>
              <UIView>
                <Components.CallToActionSubtitle>Nu ai primit niciun cod?</Components.CallToActionSubtitle>
                <Components.CallToActionSubtitleAccent onClick={onResendCode}>
                  Retrimite codul
                </Components.CallToActionSubtitleAccent>
              </UIView>
            </Components.CallToActionWrapper>
          </Components.RightSideContentWrapper>
        </Components.RightSideWrapper>
      </Components.Container>
    </ProjectSimpleContainer>
  );
};

export default VerifyPhoneNumber;
