import styled from 'styled-components';
import AppCSS from '../../../../utils/styled';

export const Components = {
  Container: styled.div`
    white-space: break-spaces;
  `,
  Text: styled.span`
    ${AppCSS.fonts.label14Regular};
    color: ${AppCSS.colors.greens.green80};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
};
