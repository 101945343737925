export const SUBSCRIPTION_DATA = {
  pro: {
    label: 'Abonament Pro',
  },
  trial: {
    label: 'Perioadă de test',
  },
  expired: {
    label: 'Abonament epxirat',
  },
};
