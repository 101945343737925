export const COUNTRIES = {
  'United States +1': {
    countryCode: 'us',
    dialCode: '+1',
    label: 'United States +1',
    name: 'United States',
    value: '+1',
  },
  'Afghanistan +93': {
    countryCode: 'af',
    dialCode: '+93',
    label: 'Afghanistan +93',
    name: 'Afghanistan',
    value: '+93',
  },
  'Albania +355': {
    countryCode: 'al',
    dialCode: '+355',
    label: 'Albania +355',
    name: 'Albania',
    value: '+355',
  },
  'Algeria +213': {
    countryCode: 'dz',
    dialCode: '+213',
    label: 'Algeria +213',
    name: 'Algeria',
    value: '+213',
  },
  'American Samoa +1684': {
    countryCode: 'as',
    dialCode: '+1684',
    label: 'American Samoa +1684',
    name: 'American Samoa',
    value: '+1684',
  },
  'Andorra +376': {
    countryCode: 'ad',
    dialCode: '+376',
    label: 'Andorra +376',
    name: 'Andorra',
    value: '+376',
  },
  'Angola +244': {
    countryCode: 'ao',
    dialCode: '+244',
    label: 'Angola +244',
    name: 'Angola',
    value: '+244',
  },
  'Anguilla +1264': {
    countryCode: 'ai',
    dialCode: '+1264',
    label: 'Anguilla +1264',
    name: 'Anguilla',
    value: '+1264',
  },
  'Antigua and Barbuda +1268': {
    countryCode: 'ag',
    dialCode: '+1268',
    label: 'Antigua and Barbuda +1268',
    name: 'Antigua and Barbuda',
    value: '+1268',
  },
  'Argentina +54': {
    countryCode: 'ar',
    dialCode: '+54',
    label: 'Argentina +54',
    name: 'Argentina',
    value: '+54',
  },
  'Armenia +374': {
    countryCode: 'am',
    dialCode: '+374',
    label: 'Armenia +374',
    name: 'Armenia',
    value: '+374',
  },
  'Aruba +297': {
    countryCode: 'aw',
    dialCode: '+297',
    label: 'Aruba +297',
    name: 'Aruba',
    value: '+297',
  },
  'Australia +61': {
    countryCode: 'au',
    dialCode: '+61',
    label: 'Australia +61',
    name: 'Australia',
    value: '+61',
  },
  'Austria +43': {
    countryCode: 'at',
    dialCode: '+43',
    label: 'Austria +43',
    name: 'Austria',
    value: '+43',
  },
  'Azerbaijan +994': {
    countryCode: 'az',
    dialCode: '+994',
    label: 'Azerbaijan +994',
    name: 'Azerbaijan',
    value: '+994',
  },
  'Bahamas +1242': {
    countryCode: 'bs',
    dialCode: '+1242',
    label: 'Bahamas +1242',
    name: 'Bahamas',
    value: '+1242',
  },
  'Bahrain +973': {
    countryCode: 'bh',
    dialCode: '+973',
    label: 'Bahrain +973',
    name: 'Bahrain',
    value: '+973',
  },
  'Bangladesh +880': {
    countryCode: 'bd',
    dialCode: '+880',
    label: 'Bangladesh +880',
    name: 'Bangladesh',
    value: '+880',
  },
  'Barbados +1246': {
    countryCode: 'bb',
    dialCode: '+1246',
    label: 'Barbados +1246',
    name: 'Barbados',
    value: '+1246',
  },
  'Belarus +375': {
    countryCode: 'by',
    dialCode: '+375',
    label: 'Belarus +375',
    name: 'Belarus',
    value: '+375',
  },
  'Belgium +32': {
    countryCode: 'be',
    dialCode: '+32',
    label: 'Belgium +32',
    name: 'Belgium',
    value: '+32',
  },
  'Belize +501': {
    countryCode: 'bz',
    dialCode: '+501',
    label: 'Belize +501',
    name: 'Belize',
    value: '+501',
  },
  'Benin +229': {
    countryCode: 'bj',
    dialCode: '+229',
    label: 'Benin +229',
    name: 'Benin',
    value: '+229',
  },
  'Bermuda +1441': {
    countryCode: 'bm',
    dialCode: '+1441',
    label: 'Bermuda +1441',
    name: 'Bermuda',
    value: '+1441',
  },
  'Bhutan +975': {
    countryCode: 'bt',
    dialCode: '+975',
    label: 'Bhutan +975',
    name: 'Bhutan',
    value: '+975',
  },
  'Bolivia +591': {
    countryCode: 'bo',
    dialCode: '+591',
    label: 'Bolivia +591',
    name: 'Bolivia',
    value: '+591',
  },
  'Bosnia and Herzegovina +387': {
    countryCode: 'ba',
    dialCode: '+387',
    label: 'Bosnia and Herzegovina +387',
    name: 'Bosnia and Herzegovina',
    value: '+387',
  },
  'Botswana +267': {
    countryCode: 'bw',
    dialCode: '+267',
    label: 'Botswana +267',
    name: 'Botswana',
    value: '+267',
  },
  'Brazil +55': {
    countryCode: 'br',
    dialCode: '+55',
    label: 'Brazil +55',
    name: 'Brazil',
    value: '+55',
  },
  'British Indian Ocean Territory +246': {
    countryCode: 'io',
    dialCode: '+246',
    label: 'British Indian Ocean Territory +246',
    name: 'British Indian Ocean Territory',
    value: '+246',
  },
  'British Virgin Islands +1284': {
    countryCode: 'vg',
    dialCode: '+1284',
    label: 'British Virgin Islands +1284',
    name: 'British Virgin Islands',
    value: '+1284',
  },
  'Brunei +673': {
    countryCode: 'bn',
    dialCode: '+673',
    label: 'Brunei +673',
    name: 'Brunei',
    value: '+673',
  },
  'Bulgaria +359': {
    countryCode: 'bg',
    dialCode: '+359',
    label: 'Bulgaria +359',
    name: 'Bulgaria',
    value: '+359',
  },
  'Burkina Faso +226': {
    countryCode: 'bf',
    dialCode: '+226',
    label: 'Burkina Faso +226',
    name: 'Burkina Faso',
    value: '+226',
  },
  'Burundi +257': {
    countryCode: 'bi',
    dialCode: '+257',
    label: 'Burundi +257',
    name: 'Burundi',
    value: '+257',
  },
  'Cambodia +855': {
    countryCode: 'kh',
    dialCode: '+855',
    label: 'Cambodia +855',
    name: 'Cambodia',
    value: '+855',
  },
  'Cameroon +237': {
    countryCode: 'cm',
    dialCode: '+237',
    label: 'Cameroon +237',
    name: 'Cameroon',
    value: '+237',
  },
  'Canada +1': {
    countryCode: 'ca',
    dialCode: '+1',
    label: 'Canada +1',
    name: 'Canada',
    value: '+1',
  },
  'Cape Verde +238': {
    countryCode: 'cv',
    dialCode: '+238',
    label: 'Cape Verde +238',
    name: 'Cape Verde',
    value: '+238',
  },
  'Caribbean Netherlands +599': {
    countryCode: 'bq',
    dialCode: '+599',
    label: 'Caribbean Netherlands +599',
    name: 'Caribbean Netherlands',
    value: '+599',
  },
  'Cayman Islands +1345': {
    countryCode: 'ky',
    dialCode: '+1345',
    label: 'Cayman Islands +1345',
    name: 'Cayman Islands',
    value: '+1345',
  },
  'Central African Republic +236': {
    countryCode: 'cf',
    dialCode: '+236',
    label: 'Central African Republic +236',
    name: 'Central African Republic',
    value: '+236',
  },
  'Chad +235': {
    countryCode: 'td',
    dialCode: '+235',
    label: 'Chad +235',
    name: 'Chad',
    value: '+235',
  },
  'Chile +56': {
    countryCode: 'cl',
    dialCode: '+56',
    label: 'Chile +56',
    name: 'Chile',
    value: '+56',
  },
  'China +86': {
    countryCode: 'cn',
    dialCode: '+86',
    label: 'China +86',
    name: 'China',
    value: '+86',
  },
  'Christmas Island +61': {
    countryCode: 'cx',
    dialCode: '+61',
    label: 'Christmas Island +61',
    name: 'Christmas Island',
    value: '+61',
  },
  'Cocos Islands +61': {
    countryCode: 'cc',
    dialCode: '+61',
    label: 'Cocos Islands +61',
    name: 'Cocos Islands',
    value: '+61',
  },
  'Colombia +57': {
    countryCode: 'co',
    dialCode: '+57',
    label: 'Colombia +57',
    name: 'Colombia',
    value: '+57',
  },
  'Comoros +269': {
    countryCode: 'km',
    dialCode: '+269',
    label: 'Comoros +269',
    name: 'Comoros',
    value: '+269',
  },
  'Congo +242': {
    countryCode: 'cg',
    dialCode: '+242',
    label: 'Congo +242',
    name: 'Congo',
    value: '+242',
  },
  'Congo +243': {
    countryCode: 'cd',
    dialCode: '+243',
    label: 'Congo +243',
    name: 'Congo',
    value: '+243',
  },
  'Cook Islands +682': {
    countryCode: 'ck',
    dialCode: '+682',
    label: 'Cook Islands +682',
    name: 'Cook Islands',
    value: '+682',
  },
  'Costa Rica +506': {
    countryCode: 'cr',
    dialCode: '+506',
    label: 'Costa Rica +506',
    name: 'Costa Rica',
    value: '+506',
  },
  'Croatia +385': {
    countryCode: 'hr',
    dialCode: '+385',
    label: 'Croatia +385',
    name: 'Croatia',
    value: '+385',
  },
  'Cuba +53': {
    countryCode: 'cu',
    dialCode: '+53',
    label: 'Cuba +53',
    name: 'Cuba',
    value: '+53',
  },
  'Curaçao +599': {
    countryCode: 'cw',
    dialCode: '+599',
    label: 'Curaçao +599',
    name: 'Curaçao',
    value: '+599',
  },
  'Cyprus +357': {
    countryCode: 'cy',
    dialCode: '+357',
    label: 'Cyprus +357',
    name: 'Cyprus',
    value: '+357',
  },
  'Czech Republic +420': {
    countryCode: 'cz',
    dialCode: '+420',
    label: 'Czech Republic +420',
    name: 'Czech Republic',
    value: '+420',
  },
  'Côte d’Ivoire +225': {
    countryCode: 'ci',
    dialCode: '+225',
    label: 'Côte d’Ivoire +225',
    name: 'Côte d’Ivoire',
    value: '+225',
  },
  'Denmark +45': {
    countryCode: 'dk',
    dialCode: '+45',
    label: 'Denmark +45',
    name: 'Denmark',
    value: '+45',
  },
  'Djibouti +253': {
    countryCode: 'dj',
    dialCode: '+253',
    label: 'Djibouti +253',
    name: 'Djibouti',
    value: '+253',
  },
  'Dominica +1767': {
    countryCode: 'dm',
    dialCode: '+1767',
    label: 'Dominica +1767',
    name: 'Dominica',
    value: '+1767',
  },
  'Dominican Republic +1': {
    countryCode: 'do',
    dialCode: '+1',
    label: 'Dominican Republic +1',
    name: 'Dominican Republic',
    value: '+1',
  },
  'Ecuador +593': {
    countryCode: 'ec',
    dialCode: '+593',
    label: 'Ecuador +593',
    name: 'Ecuador',
    value: '+593',
  },
  'Egypt +20': {
    countryCode: 'eg',
    dialCode: '+20',
    label: 'Egypt +20',
    name: 'Egypt',
    value: '+20',
  },
  'El Salvador +503': {
    countryCode: 'sv',
    dialCode: '+503',
    label: 'El Salvador +503',
    name: 'El Salvador',
    value: '+503',
  },
  'Equatorial Guinea +240': {
    countryCode: 'gq',
    dialCode: '+240',
    label: 'Equatorial Guinea +240',
    name: 'Equatorial Guinea',
    value: '+240',
  },
  'Eritrea +291': {
    countryCode: 'er',
    dialCode: '+291',
    label: 'Eritrea +291',
    name: 'Eritrea',
    value: '+291',
  },
  'Estonia +372': {
    countryCode: 'ee',
    dialCode: '+372',
    label: 'Estonia +372',
    name: 'Estonia',
    value: '+372',
  },
  'Ethiopia +251': {
    countryCode: 'et',
    dialCode: '+251',
    label: 'Ethiopia +251',
    name: 'Ethiopia',
    value: '+251',
  },
  'Falkland Islands +500': {
    countryCode: 'fk',
    dialCode: '+500',
    label: 'Falkland Islands +500',
    name: 'Falkland Islands',
    value: '+500',
  },
  'Faroe Islands +298': {
    countryCode: 'fo',
    dialCode: '+298',
    label: 'Faroe Islands +298',
    name: 'Faroe Islands',
    value: '+298',
  },
  'Fiji +679': {
    countryCode: 'fj',
    dialCode: '+679',
    label: 'Fiji +679',
    name: 'Fiji',
    value: '+679',
  },
  'Finland +358': {
    countryCode: 'fi',
    dialCode: '+358',
    label: 'Finland +358',
    name: 'Finland',
    value: '+358',
  },
  'France +33': {
    countryCode: 'fr',
    dialCode: '+33',
    label: 'France +33',
    name: 'France',
    value: '+33',
  },
  'French Guiana +594': {
    countryCode: 'gf',
    dialCode: '+594',
    label: 'French Guiana +594',
    name: 'French Guiana',
    value: '+594',
  },
  'French Polynesia +689': {
    countryCode: 'pf',
    dialCode: '+689',
    label: 'French Polynesia +689',
    name: 'French Polynesia',
    value: '+689',
  },
  'Gabon +241': {
    countryCode: 'ga',
    dialCode: '+241',
    label: 'Gabon +241',
    name: 'Gabon',
    value: '+241',
  },
  'Gambia +220': {
    countryCode: 'gm',
    dialCode: '+220',
    label: 'Gambia +220',
    name: 'Gambia',
    value: '+220',
  },
  'Georgia +995': {
    countryCode: 'ge',
    dialCode: '+995',
    label: 'Georgia +995',
    name: 'Georgia',
    value: '+995',
  },
  'Germany +49': {
    countryCode: 'de',
    dialCode: '+49',
    label: 'Germany +49',
    name: 'Germany',
    value: '+49',
  },
  'Ghana +233': {
    countryCode: 'gh',
    dialCode: '+233',
    label: 'Ghana +233',
    name: 'Ghana',
    value: '+233',
  },
  'Gibraltar +350': {
    countryCode: 'gi',
    dialCode: '+350',
    label: 'Gibraltar +350',
    name: 'Gibraltar',
    value: '+350',
  },
  'Greece +30': {
    countryCode: 'gr',
    dialCode: '+30',
    label: 'Greece +30',
    name: 'Greece',
    value: '+30',
  },
  'Greenland +299': {
    countryCode: 'gl',
    dialCode: '+299',
    label: 'Greenland +299',
    name: 'Greenland',
    value: '+299',
  },
  'Grenada +1473': {
    countryCode: 'gd',
    dialCode: '+1473',
    label: 'Grenada +1473',
    name: 'Grenada',
    value: '+1473',
  },
  'Guadeloupe +590': {
    countryCode: 'gp',
    dialCode: '+590',
    label: 'Guadeloupe +590',
    name: 'Guadeloupe',
    value: '+590',
  },
  'Guam +1671': {
    countryCode: 'gu',
    dialCode: '+1671',
    label: 'Guam +1671',
    name: 'Guam',
    value: '+1671',
  },
  'Guatemala +502': {
    countryCode: 'gt',
    dialCode: '+502',
    label: 'Guatemala +502',
    name: 'Guatemala',
    value: '+502',
  },
  'Guernsey +44': {
    countryCode: 'gg',
    dialCode: '+44',
    label: 'Guernsey +44',
    name: 'Guernsey',
    value: '+44',
  },
  'Guinea +224': {
    countryCode: 'gn',
    dialCode: '+224',
    label: 'Guinea +224',
    name: 'Guinea',
    value: '+224',
  },
  'Guinea-Bissau +245': {
    countryCode: 'gw',
    dialCode: '+245',
    label: 'Guinea-Bissau +245',
    name: 'Guinea-Bissau',
    value: '+245',
  },
  'Guyana +592': {
    countryCode: 'gy',
    dialCode: '+592',
    label: 'Guyana +592',
    name: 'Guyana',
    value: '+592',
  },
  'Haiti +509': {
    countryCode: 'ht',
    dialCode: '+509',
    label: 'Haiti +509',
    name: 'Haiti',
    value: '+509',
  },
  'Honduras +504': {
    countryCode: 'hn',
    dialCode: '+504',
    label: 'Honduras +504',
    name: 'Honduras',
    value: '+504',
  },
  'Hong Kong +852': {
    countryCode: 'hk',
    dialCode: '+852',
    label: 'Hong Kong +852',
    name: 'Hong Kong',
    value: '+852',
  },
  'Hungary +36': {
    countryCode: 'hu',
    dialCode: '+36',
    label: 'Hungary +36',
    name: 'Hungary',
    value: '+36',
  },
  'Iceland +354': {
    countryCode: 'is',
    dialCode: '+354',
    label: 'Iceland +354',
    name: 'Iceland',
    value: '+354',
  },
  'India +91': {
    countryCode: 'in',
    dialCode: '+91',
    label: 'India +91',
    name: 'India',
    value: '+91',
  },
  'Indonesia +62': {
    countryCode: 'id',
    dialCode: '+62',
    label: 'Indonesia +62',
    name: 'Indonesia',
    value: '+62',
  },
  'Iran +98': {
    countryCode: 'ir',
    dialCode: '+98',
    label: 'Iran +98',
    name: 'Iran',
    value: '+98',
  },
  'Iraq +964': {
    countryCode: 'iq',
    dialCode: '+964',
    label: 'Iraq +964',
    name: 'Iraq',
    value: '+964',
  },
  'Ireland +353': {
    countryCode: 'ie',
    dialCode: '+353',
    label: 'Ireland +353',
    name: 'Ireland',
    value: '+353',
  },
  'Isle of Man +44': {
    countryCode: 'im',
    dialCode: '+44',
    label: 'Isle of Man +44',
    name: 'Isle of Man',
    value: '+44',
  },
  'Israel +972': {
    countryCode: 'il',
    dialCode: '+972',
    label: 'Israel +972',
    name: 'Israel',
    value: '+972',
  },
  'Italy +39': {
    countryCode: 'it',
    dialCode: '+39',
    label: 'Italy +39',
    name: 'Italy',
    value: '+39',
  },
  'Jamaica +1876': {
    countryCode: 'jm',
    dialCode: '+1876',
    label: 'Jamaica +1876',
    name: 'Jamaica',
    value: '+1876',
  },
  'Japan +81': {
    countryCode: 'jp',
    dialCode: '+81',
    label: 'Japan +81',
    name: 'Japan',
    value: '+81',
  },
  'Jersey +44': {
    countryCode: 'je',
    dialCode: '+44',
    label: 'Jersey +44',
    name: 'Jersey',
    value: '+44',
  },
  'Jordan +962': {
    countryCode: 'jo',
    dialCode: '+962',
    label: 'Jordan +962',
    name: 'Jordan',
    value: '+962',
  },
  'Kazakhstan +7': {
    countryCode: 'kz',
    dialCode: '+7',
    label: 'Kazakhstan +7',
    name: 'Kazakhstan',
    value: '+7',
  },
  'Kenya +254': {
    countryCode: 'ke',
    dialCode: '+254',
    label: 'Kenya +254',
    name: 'Kenya',
    value: '+254',
  },
  'Kiribati +686': {
    countryCode: 'ki',
    dialCode: '+686',
    label: 'Kiribati +686',
    name: 'Kiribati',
    value: '+686',
  },
  'Kuwait +965': {
    countryCode: 'kw',
    dialCode: '+965',
    label: 'Kuwait +965',
    name: 'Kuwait',
    value: '+965',
  },
  'Kyrgyzstan +996': {
    countryCode: 'kg',
    dialCode: '+996',
    label: 'Kyrgyzstan +996',
    name: 'Kyrgyzstan',
    value: '+996',
  },
  'Laos +856': {
    countryCode: 'la',
    dialCode: '+856',
    label: 'Laos +856',
    name: 'Laos',
    value: '+856',
  },
  'Latvia +371': {
    countryCode: 'lv',
    dialCode: '+371',
    label: 'Latvia +371',
    name: 'Latvia',
    value: '+371',
  },
  'Lebanon +961': {
    countryCode: 'lb',
    dialCode: '+961',
    label: 'Lebanon +961',
    name: 'Lebanon',
    value: '+961',
  },
  'Lesotho +266': {
    countryCode: 'ls',
    dialCode: '+266',
    label: 'Lesotho +266',
    name: 'Lesotho',
    value: '+266',
  },
  'Liberia +231': {
    countryCode: 'lr',
    dialCode: '+231',
    label: 'Liberia +231',
    name: 'Liberia',
    value: '+231',
  },
  'Libya +218': {
    countryCode: 'ly',
    dialCode: '+218',
    label: 'Libya +218',
    name: 'Libya',
    value: '+218',
  },
  'Liechtenstein +423': {
    countryCode: 'li',
    dialCode: '+423',
    label: 'Liechtenstein +423',
    name: 'Liechtenstein',
    value: '+423',
  },
  'Lithuania +370': {
    countryCode: 'lt',
    dialCode: '+370',
    label: 'Lithuania +370',
    name: 'Lithuania',
    value: '+370',
  },
  'Luxembourg +352': {
    countryCode: 'lu',
    dialCode: '+352',
    label: 'Luxembourg +352',
    name: 'Luxembourg',
    value: '+352',
  },
  'Macau +853': {
    countryCode: 'mo',
    dialCode: '+853',
    label: 'Macau +853',
    name: 'Macau',
    value: '+853',
  },
  'Macedonia +389': {
    countryCode: 'mk',
    dialCode: '+389',
    label: 'Macedonia +389',
    name: 'Macedonia',
    value: '+389',
  },
  'Madagascar +261': {
    countryCode: 'mg',
    dialCode: '+261',
    label: 'Madagascar +261',
    name: 'Madagascar',
    value: '+261',
  },
  'Malawi +265': {
    countryCode: 'mw',
    dialCode: '+265',
    label: 'Malawi +265',
    name: 'Malawi',
    value: '+265',
  },
  'Malaysia +60': {
    countryCode: 'my',
    dialCode: '+60',
    label: 'Malaysia +60',
    name: 'Malaysia',
    value: '+60',
  },
  'Maldives +960': {
    countryCode: 'mv',
    dialCode: '+960',
    label: 'Maldives +960',
    name: 'Maldives',
    value: '+960',
  },
  'Mali +223': {
    countryCode: 'ml',
    dialCode: '+223',
    label: 'Mali +223',
    name: 'Mali',
    value: '+223',
  },
  'Malta +356': {
    countryCode: 'mt',
    dialCode: '+356',
    label: 'Malta +356',
    name: 'Malta',
    value: '+356',
  },
  'Marshall Islands +692': {
    countryCode: 'mh',
    dialCode: '+692',
    label: 'Marshall Islands +692',
    name: 'Marshall Islands',
    value: '+692',
  },
  'Martinique +596': {
    countryCode: 'mq',
    dialCode: '+596',
    label: 'Martinique +596',
    name: 'Martinique',
    value: '+596',
  },
  'Mauritania +222': {
    countryCode: 'mr',
    dialCode: '+222',
    label: 'Mauritania +222',
    name: 'Mauritania',
    value: '+222',
  },
  'Mauritius +230': {
    countryCode: 'mu',
    dialCode: '+230',
    label: 'Mauritius +230',
    name: 'Mauritius',
    value: '+230',
  },
  'Mayotte +262': {
    countryCode: 'yt',
    dialCode: '+262',
    label: 'Mayotte +262',
    name: 'Mayotte',
    value: '+262',
  },
  'Mexico +52': {
    countryCode: 'mx',
    dialCode: '+52',
    label: 'Mexico +52',
    name: 'Mexico',
    value: '+52',
  },
  'Micronesia +691': {
    countryCode: 'fm',
    dialCode: '+691',
    label: 'Micronesia +691',
    name: 'Micronesia',
    value: '+691',
  },
  'Moldova +373': {
    countryCode: 'md',
    dialCode: '+373',
    label: 'Moldova +373',
    name: 'Moldova',
    value: '+373',
  },
  'Monaco +377': {
    countryCode: 'mc',
    dialCode: '+377',
    label: 'Monaco +377',
    name: 'Monaco',
    value: '+377',
  },
  'Mongolia +976': {
    countryCode: 'mn',
    dialCode: '+976',
    label: 'Mongolia +976',
    name: 'Mongolia',
    value: '+976',
  },
  'Montenegro +382': {
    countryCode: 'me',
    dialCode: '+382',
    label: 'Montenegro +382',
    name: 'Montenegro',
    value: '+382',
  },
  'Montserrat +1664': {
    countryCode: 'ms',
    dialCode: '+1664',
    label: 'Montserrat +1664',
    name: 'Montserrat',
    value: '+1664',
  },
  'Morocco +212': {
    countryCode: 'ma',
    dialCode: '+212',
    label: 'Morocco +212',
    name: 'Morocco',
    value: '+212',
  },
  'Mozambique +258': {
    countryCode: 'mz',
    dialCode: '+258',
    label: 'Mozambique +258',
    name: 'Mozambique',
    value: '+258',
  },
  'Myanmar +95': {
    countryCode: 'mm',
    dialCode: '+95',
    label: 'Myanmar +95',
    name: 'Myanmar',
    value: '+95',
  },
  'Namibia +264': {
    countryCode: 'na',
    dialCode: '+264',
    label: 'Namibia +264',
    name: 'Namibia',
    value: '+264',
  },
  'Nauru +674': {
    countryCode: 'nr',
    dialCode: '+674',
    label: 'Nauru +674',
    name: 'Nauru',
    value: '+674',
  },
  'Nepal +977': {
    countryCode: 'np',
    dialCode: '+977',
    label: 'Nepal +977',
    name: 'Nepal',
    value: '+977',
  },
  'Netherlands +31': {
    countryCode: 'nl',
    dialCode: '+31',
    label: 'Netherlands +31',
    name: 'Netherlands',
    value: '+31',
  },
  'New Caledonia +687': {
    countryCode: 'nc',
    dialCode: '+687',
    label: 'New Caledonia +687',
    name: 'New Caledonia',
    value: '+687',
  },
  'New Zealand +64': {
    countryCode: 'nz',
    dialCode: '+64',
    label: 'New Zealand +64',
    name: 'New Zealand',
    value: '+64',
  },
  'Nicaragua +505': {
    countryCode: 'ni',
    dialCode: '+505',
    label: 'Nicaragua +505',
    name: 'Nicaragua',
    value: '+505',
  },
  'Niger +227': {
    countryCode: 'ne',
    dialCode: '+227',
    label: 'Niger +227',
    name: 'Niger',
    value: '+227',
  },
  'Nigeria +234': {
    countryCode: 'ng',
    dialCode: '+234',
    label: 'Nigeria +234',
    name: 'Nigeria',
    value: '+234',
  },
  'Niue +683': {
    countryCode: 'nu',
    dialCode: '+683',
    label: 'Niue +683',
    name: 'Niue',
    value: '+683',
  },
  'Norfolk Island +672': {
    countryCode: 'nf',
    dialCode: '+672',
    label: 'Norfolk Island +672',
    name: 'Norfolk Island',
    value: '+672',
  },
  'North Korea +850': {
    countryCode: 'kp',
    dialCode: '+850',
    label: 'North Korea +850',
    name: 'North Korea',
    value: '+850',
  },
  'Northern Mariana Islands +1670': {
    countryCode: 'mp',
    dialCode: '+1670',
    label: 'Northern Mariana Islands +1670',
    name: 'Northern Mariana Islands',
    value: '+1670',
  },
  'Norway +47': {
    countryCode: 'no',
    dialCode: '+47',
    label: 'Norway +47',
    name: 'Norway',
    value: '+47',
  },
  'Oman +968': {
    countryCode: 'om',
    dialCode: '+968',
    label: 'Oman +968',
    name: 'Oman',
    value: '+968',
  },
  'Pakistan +92': {
    countryCode: 'pk',
    dialCode: '+92',
    label: 'Pakistan +92',
    name: 'Pakistan',
    value: '+92',
  },
  'Palau +680': {
    countryCode: 'pw',
    dialCode: '+680',
    label: 'Palau +680',
    name: 'Palau',
    value: '+680',
  },
  'Palestine +970': {
    countryCode: 'ps',
    dialCode: '+970',
    label: 'Palestine +970',
    name: 'Palestine',
    value: '+970',
  },
  'Panama +507': {
    countryCode: 'pa',
    dialCode: '+507',
    label: 'Panama +507',
    name: 'Panama',
    value: '+507',
  },
  'Papua New Guinea +675': {
    countryCode: 'pg',
    dialCode: '+675',
    label: 'Papua New Guinea +675',
    name: 'Papua New Guinea',
    value: '+675',
  },
  'Paraguay +595': {
    countryCode: 'py',
    dialCode: '+595',
    label: 'Paraguay +595',
    name: 'Paraguay',
    value: '+595',
  },
  'Peru +51': {
    countryCode: 'pe',
    dialCode: '+51',
    label: 'Peru +51',
    name: 'Peru',
    value: '+51',
  },
  'Philippines +63': {
    countryCode: 'ph',
    dialCode: '+63',
    label: 'Philippines +63',
    name: 'Philippines',
    value: '+63',
  },
  'Poland +48': {
    countryCode: 'pl',
    dialCode: '+48',
    label: 'Poland +48',
    name: 'Poland',
    value: '+48',
  },
  'Portugal +351': {
    countryCode: 'pt',
    dialCode: '+351',
    label: 'Portugal +351',
    name: 'Portugal',
    value: '+351',
  },
  'Puerto Rico +1': {
    countryCode: 'pr',
    dialCode: '+1',
    label: 'Puerto Rico +1',
    name: 'Puerto Rico',
    value: '+1',
  },
  'Qatar +974': {
    countryCode: 'qa',
    dialCode: '+974',
    label: 'Qatar +974',
    name: 'Qatar',
    value: '+974',
  },
  'Romania +40': {
    countryCode: 'ro',
    dialCode: '+40',
    label: 'Romania +40',
    name: 'Romania',
    value: '+40',
  },
  'Russia +7': {
    countryCode: 'ru',
    dialCode: '+7',
    label: 'Russia +7',
    name: 'Russia',
    value: '+7',
  },
  'Rwanda +250': {
    countryCode: 'rw',
    dialCode: '+250',
    label: 'Rwanda +250',
    name: 'Rwanda',
    value: '+250',
  },
  'Réunion +262': {
    countryCode: 're',
    dialCode: '+262',
    label: 'Réunion +262',
    name: 'Réunion',
    value: '+262',
  },
  'Saint Barthélemy +590': {
    countryCode: 'bl',
    dialCode: '+590',
    label: 'Saint Barthélemy +590',
    name: 'Saint Barthélemy',
    value: '+590',
  },
  'Saint Helena +290': {
    countryCode: 'sh',
    dialCode: '+290',
    label: 'Saint Helena +290',
    name: 'Saint Helena',
    value: '+290',
  },
  'Saint Kitts and Nevis +1869': {
    countryCode: 'kn',
    dialCode: '+1869',
    label: 'Saint Kitts and Nevis +1869',
    name: 'Saint Kitts and Nevis',
    value: '+1869',
  },
  'Saint Lucia +1758': {
    countryCode: 'lc',
    dialCode: '+1758',
    label: 'Saint Lucia +1758',
    name: 'Saint Lucia',
    value: '+1758',
  },
  'Saint Martin +590': {
    countryCode: 'mf',
    dialCode: '+590',
    label: 'Saint Martin +590',
    name: 'Saint Martin',
    value: '+590',
  },
  'Saint Pierre and Miquelon +508': {
    countryCode: 'pm',
    dialCode: '+508',
    label: 'Saint Pierre and Miquelon +508',
    name: 'Saint Pierre and Miquelon',
    value: '+508',
  },
  'Saint Vincent and the Grenadines +1784': {
    countryCode: 'vc',
    dialCode: '+1784',
    label: 'Saint Vincent and the Grenadines +1784',
    name: 'Saint Vincent and the Grenadines',
    value: '+1784',
  },
  'Samoa +685': {
    countryCode: 'ws',
    dialCode: '+685',
    label: 'Samoa +685',
    name: 'Samoa',
    value: '+685',
  },
  'San Marino +378': {
    countryCode: 'sm',
    dialCode: '+378',
    label: 'San Marino +378',
    name: 'San Marino',
    value: '+378',
  },
  'Saudi Arabia +966': {
    countryCode: 'sa',
    dialCode: '+966',
    label: 'Saudi Arabia +966',
    name: 'Saudi Arabia',
    value: '+966',
  },
  'Senegal +221': {
    countryCode: 'sn',
    dialCode: '+221',
    label: 'Senegal +221',
    name: 'Senegal',
    value: '+221',
  },
  'Serbia +381': {
    countryCode: 'rs',
    dialCode: '+381',
    label: 'Serbia +381',
    name: 'Serbia',
    value: '+381',
  },
  'Seychelles +248': {
    countryCode: 'sc',
    dialCode: '+248',
    label: 'Seychelles +248',
    name: 'Seychelles',
    value: '+248',
  },
  'Sierra Leone +232': {
    countryCode: 'sl',
    dialCode: '+232',
    label: 'Sierra Leone +232',
    name: 'Sierra Leone',
    value: '+232',
  },
  'Singapore +65': {
    countryCode: 'sg',
    dialCode: '+65',
    label: 'Singapore +65',
    name: 'Singapore',
    value: '+65',
  },
  'Sint Maarten +1721': {
    countryCode: 'sx',
    dialCode: '+1721',
    label: 'Sint Maarten +1721',
    name: 'Sint Maarten',
    value: '+1721',
  },
  'Slovakia +421': {
    countryCode: 'sk',
    dialCode: '+421',
    label: 'Slovakia +421',
    name: 'Slovakia',
    value: '+421',
  },
  'Slovenia +386': {
    countryCode: 'si',
    dialCode: '+386',
    label: 'Slovenia +386',
    name: 'Slovenia',
    value: '+386',
  },
  'Solomon Islands +677': {
    countryCode: 'sb',
    dialCode: '+677',
    label: 'Solomon Islands +677',
    name: 'Solomon Islands',
    value: '+677',
  },
  'Somalia +252': {
    countryCode: 'so',
    dialCode: '+252',
    label: 'Somalia +252',
    name: 'Somalia',
    value: '+252',
  },
  'South Africa +27': {
    countryCode: 'za',
    dialCode: '+27',
    label: 'South Africa +27',
    name: 'South Africa',
    value: '+27',
  },
  'South Korea +82': {
    countryCode: 'kr',
    dialCode: '+82',
    label: 'South Korea +82',
    name: 'South Korea',
    value: '+82',
  },
  'South Sudan +211': {
    countryCode: 'ss',
    dialCode: '+211',
    label: 'South Sudan +211',
    name: 'South Sudan',
    value: '+211',
  },
  'Spain +34': {
    countryCode: 'es',
    dialCode: '+34',
    label: 'Spain +34',
    name: 'Spain',
    value: '+34',
  },
  'Sri Lanka +94': {
    countryCode: 'lk',
    dialCode: '+94',
    label: 'Sri Lanka +94',
    name: 'Sri Lanka',
    value: '+94',
  },
  'Sudan +249': {
    countryCode: 'sd',
    dialCode: '+249',
    label: 'Sudan +249',
    name: 'Sudan',
    value: '+249',
  },
  'Suriname +597': {
    countryCode: 'sr',
    dialCode: '+597',
    label: 'Suriname +597',
    name: 'Suriname',
    value: '+597',
  },
  'Svalbard and Jan Mayen +47': {
    countryCode: 'sj',
    dialCode: '+47',
    label: 'Svalbard and Jan Mayen +47',
    name: 'Svalbard and Jan Mayen',
    value: '+47',
  },
  'Swaziland +268': {
    countryCode: 'sz',
    dialCode: '+268',
    label: 'Swaziland +268',
    name: 'Swaziland',
    value: '+268',
  },
  'Sweden +46': {
    countryCode: 'se',
    dialCode: '+46',
    label: 'Sweden +46',
    name: 'Sweden',
    value: '+46',
  },
  'Switzerland +41': {
    countryCode: 'ch',
    dialCode: '+41',
    label: 'Switzerland +41',
    name: 'Switzerland',
    value: '+41',
  },
  'Syria +963': {
    countryCode: 'sy',
    dialCode: '+963',
    label: 'Syria +963',
    name: 'Syria',
    value: '+963',
  },
  'São Tomé and Príncipe +239': {
    countryCode: 'st',
    dialCode: '+239',
    label: 'São Tomé and Príncipe +239',
    name: 'São Tomé and Príncipe',
    value: '+239',
  },
  'Taiwan +886': {
    countryCode: 'tw',
    dialCode: '+886',
    label: 'Taiwan +886',
    name: 'Taiwan',
    value: '+886',
  },
  'Tajikistan +992': {
    countryCode: 'tj',
    dialCode: '+992',
    label: 'Tajikistan +992',
    name: 'Tajikistan',
    value: '+992',
  },
  'Tanzania +255': {
    countryCode: 'tz',
    dialCode: '+255',
    label: 'Tanzania +255',
    name: 'Tanzania',
    value: '+255',
  },
  'Thailand +66': {
    countryCode: 'th',
    dialCode: '+66',
    label: 'Thailand +66',
    name: 'Thailand',
    value: '+66',
  },
  'Timor-Leste +670': {
    countryCode: 'tl',
    dialCode: '+670',
    label: 'Timor-Leste +670',
    name: 'Timor-Leste',
    value: '+670',
  },
  'Togo +228': {
    countryCode: 'tg',
    dialCode: '+228',
    label: 'Togo +228',
    name: 'Togo',
    value: '+228',
  },
  'Tokelau +690': {
    countryCode: 'tk',
    dialCode: '+690',
    label: 'Tokelau +690',
    name: 'Tokelau',
    value: '+690',
  },
  'Tonga +676': {
    countryCode: 'to',
    dialCode: '+676',
    label: 'Tonga +676',
    name: 'Tonga',
    value: '+676',
  },
  'Trinidad and Tobago +1868': {
    countryCode: 'tt',
    dialCode: '+1868',
    label: 'Trinidad and Tobago +1868',
    name: 'Trinidad and Tobago',
    value: '+1868',
  },
  'Tunisia +216': {
    countryCode: 'tn',
    dialCode: '+216',
    label: 'Tunisia +216',
    name: 'Tunisia',
    value: '+216',
  },
  'Turkey +90': {
    countryCode: 'tr',
    dialCode: '+90',
    label: 'Turkey +90',
    name: 'Turkey',
    value: '+90',
  },
  'Turkmenistan +993': {
    countryCode: 'tm',
    dialCode: '+993',
    label: 'Turkmenistan +993',
    name: 'Turkmenistan',
    value: '+993',
  },
  'Turks and Caicos Islands +1649': {
    countryCode: 'tc',
    dialCode: '+1649',
    label: 'Turks and Caicos Islands +1649',
    name: 'Turks and Caicos Islands',
    value: '+1649',
  },
  'Tuvalu +688': {
    countryCode: 'tv',
    dialCode: '+688',
    label: 'Tuvalu +688',
    name: 'Tuvalu',
    value: '+688',
  },
  'U.S. Virgin Islands +1340': {
    countryCode: 'vi',
    dialCode: '+1340',
    label: 'U.S. Virgin Islands +1340',
    name: 'U.S. Virgin Islands',
    value: '+1340',
  },
  'Uganda +256': {
    countryCode: 'ug',
    dialCode: '+256',
    label: 'Uganda +256',
    name: 'Uganda',
    value: '+256',
  },
  'Ukraine +380': {
    countryCode: 'ua',
    dialCode: '+380',
    label: 'Ukraine +380',
    name: 'Ukraine',
    value: '+380',
  },
  'United Arab Emirates +971': {
    countryCode: 'ae',
    dialCode: '+971',
    label: 'United Arab Emirates +971',
    name: 'United Arab Emirates',
    value: '+971',
  },
  'United Kingdom +44': {
    countryCode: 'gb',
    dialCode: '+44',
    label: 'United Kingdom +44',
    name: 'United Kingdom',
    value: '+44',
  },
  'Uruguay +598': {
    countryCode: 'uy',
    dialCode: '+598',
    label: 'Uruguay +598',
    name: 'Uruguay',
    value: '+598',
  },
  'Uzbekistan +998': {
    countryCode: 'uz',
    dialCode: '+998',
    label: 'Uzbekistan +998',
    name: 'Uzbekistan',
    value: '+998',
  },
  'Vanuatu +678': {
    countryCode: 'vu',
    dialCode: '+678',
    label: 'Vanuatu +678',
    name: 'Vanuatu',
    value: '+678',
  },
  'Vatican City +39': {
    countryCode: 'va',
    dialCode: '+39',
    label: 'Vatican City +39',
    name: 'Vatican City',
    value: '+39',
  },
  'Venezuela +58': {
    countryCode: 've',
    dialCode: '+58',
    label: 'Venezuela +58',
    name: 'Venezuela',
    value: '+58',
  },
  'Vietnam +84': {
    countryCode: 'vn',
    dialCode: '+84',
    label: 'Vietnam +84',
    name: 'Vietnam',
    value: '+84',
  },
  'Wallis and Futuna +681': {
    countryCode: 'wf',
    dialCode: '+681',
    label: 'Wallis and Futuna +681',
    name: 'Wallis and Futuna',
    value: '+681',
  },
  'Western Sahara +212': {
    countryCode: 'eh',
    dialCode: '+212',
    label: 'Western Sahara +212',
    name: 'Western Sahara',
    value: '+212',
  },
  'Yemen +967': {
    countryCode: 'ye',
    dialCode: '+967',
    label: 'Yemen +967',
    name: 'Yemen',
    value: '+967',
  },
  'Zambia +260': {
    countryCode: 'zm',
    dialCode: '+260',
    label: 'Zambia +260',
    name: 'Zambia',
    value: '+260',
  },
  'Zimbabwe +263': {
    countryCode: 'zw',
    dialCode: '+263',
    label: 'Zimbabwe +263',
    name: 'Zimbabwe',
    value: '+263',
  },
  'Åland Islands +358': {
    countryCode: 'ax',
    dialCode: '+358',
    label: 'Åland Islands +358',
    name: 'Åland Islands',
    value: '+358',
  },
};

export const COUNTRIES_ARRAY = Object.values(COUNTRIES);
export const COUNTRY_LABELS = Object.keys(COUNTRIES);
