import React, { useContext, useMemo } from 'react';
import { SessionContext } from '../SessionProvider/context';
import { NotesContext } from './context';
import { addNote } from '../../api/note';

const NotesProvider = ({ children }: { children: React.ReactNode }) => {
  const { currentProfile, refreshProfile } = useContext(SessionContext);

  const saveNoteOnServer = async (caseId: string, noteText: string) => {
    if (currentProfile) {
      await addNote(currentProfile?.id, caseId, noteText);
      refreshProfile().then();
    }
  };

  const value = useMemo(() => ({ saveNoteOnServer }), [currentProfile]);

  return <NotesContext.Provider value={value}>{children}</NotesContext.Provider>;
};

export default NotesProvider;
