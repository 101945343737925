import React, { ChangeEvent } from 'react';
import { Components } from './styled';
import { useMediaQuery } from 'react-responsive';
import { createMediaQuery } from '../../utils/responsive';
import { MEDIA_QUERY_BREAKPOINTS } from '../../utils/responsive/constants';

const ProjectTableHeader = ({
  onSearchChange,
  searchLabel,
  searchPlaceholder,
  children,
}: {
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
  searchLabel?: string;
  searchPlaceholder?: string;
  children?: React.ReactNode;
}) => {
  const isMobile = useMediaQuery({
    query: createMediaQuery(MEDIA_QUERY_BREAKPOINTS.largeMobile, true),
  });

  return (
    <Components.Container isMobile={isMobile}>
      <Components.FieldWrapper>
        {searchLabel && <Components.FieldLabel>{searchLabel}</Components.FieldLabel>}
        <Components.TextFieldContainer>
          <Components.TextField type={'search'} onChange={onSearchChange} placeholder={searchPlaceholder} />
        </Components.TextFieldContainer>
      </Components.FieldWrapper>
      {children}
    </Components.Container>
  );
};

export default ProjectTableHeader;
