import { OnboardingStepStatus, OnboardingStepType } from './types';
import React from 'react';

interface OnboardingContextType {
  completedStepsCount: number;
  shouldShowOnboarding: boolean;
  getStepStatus: (step: OnboardingStepType) => OnboardingStepStatus;
  didCompleteOnboarding: () => boolean;
  onCompletingOnboarding: () => void;
}

export const OnboardingContext = React.createContext<OnboardingContextType>({
  completedStepsCount: 1,
  shouldShowOnboarding: false,
  getStepStatus: () => {
    return 'current';
  },
  didCompleteOnboarding: () => {
    return false;
  },
  onCompletingOnboarding: () => {},
});
