import styled from 'styled-components';
import AppCSS from '../../../../../utils/styled';

export const Components = {
  Container: styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: flex-start;
    justify-content: flex-start;
  `,
  Date: styled.span`
    ${AppCSS.fonts.label12Regular};
    color: ${AppCSS.colors.greens.green80};
    margin-top: 3px;
  `,
  ContentContainer: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 4px;
    align-items: flex-start;
  `,
  LogText: styled.span`
    ${AppCSS.fonts.label16Regular};
    color: ${AppCSS.colors.greens.green90};
  `,
  HearingsButton: styled(AppCSS.buttonStyles.TertiaryButtonStyle)`
    flex: 0;
  `,
};
